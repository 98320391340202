import axios from "axios";
import { store } from "./redux/store";
import { handleSessionExpired } from "./utils/sessionManager";
import { BASE_API_ADMIN, BASE_API_ADMIN_DYNAMIC } from "./config";
import { UNAUTHENTICATED } from "./constants";

// Check if the project is running on localhost
const isLocalhost = window.location.hostname === "localhost";

const baseURL = isLocalhost ? BASE_API_ADMIN : BASE_API_ADMIN_DYNAMIC;

// Utility to fetch the access token
const getAccessToken = () => localStorage.getItem("token");

const APIPvt = axios.create({
  baseURL,
  withCredentials: process.env.NODE_ENV === "production", // Automatically include cookies with requests
});

APIPvt.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
APIPvt.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      const expireTokenModel = store?.getState()?.auth?.expireTokenModel;
      const isEmailVerify = store?.getState()?.auth?.isEmailVerify;
      const accessToken = store?.getState()?.auth?.accessToken;
      if (status === 401 && data?.message?.toLowerCase() === UNAUTHENTICATED) {
        if (isEmailVerify && accessToken !== "" && !expireTokenModel) {
          // Handle session expiration
          handleSessionExpired();
        }
      }
    } else {
      console.error("Network or server error:");
    }

    return Promise.reject(error); // Forward the error
  }
);

export default APIPvt;
