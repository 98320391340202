import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const trackingId = process.env.REACT_APP_TRACKING_ID;
    if (trackingId) {
      ReactGA.initialize(trackingId);
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    } else {
      console.error("GA_MEASUREMENT_ID is missing");
    }
  }, [location]);

  return null;
};

export default TrackPageView;
