import axios from "axios";
import { store } from "./redux/store";
import { handleSessionExpired } from "./utils/sessionManager";
import { BASE_API, BASE_API_DYNAMIC } from "./config";
import { UNAUTHENTICATED } from "./constants";

const isLocalhost = window.location.hostname === "localhost";
const baseURL = isLocalhost ? BASE_API : BASE_API_DYNAMIC;

// Utility to fetch the access token
const getAccessToken = () => localStorage.getItem("token");

// Create Axios instance
const APIPvtDB = axios.create({
  baseURL,
  withCredentials: process.env.NODE_ENV === "production", // Automatically include cookies with requests
});

// Request interceptor
APIPvtDB.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error) // Forward the error
);

// Response interceptor
APIPvtDB.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      const expireTokenModel = store?.getState()?.auth?.expireTokenModel;
      const isEmailVerify = store?.getState()?.auth?.isEmailVerify;
      const accessToken = store?.getState()?.auth?.accessToken;
      if (status === 401 && data?.message?.toLowerCase() === UNAUTHENTICATED) {
        if (isEmailVerify && accessToken !== "" && !expireTokenModel) {
          // Handle session expiration
          handleSessionExpired();
        }
      }
    } else {
      console.error("Network or server error:");
    }
    return Promise.reject(error); // Forward the error
  }
);

export default APIPvtDB;
