import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import "./CookiesSection.css";
import { useState } from "react";

const CookiesSection = () => {
  const cookies = new Cookies();
  const [isCookies, setIsCookies] = useState(
    false || cookies.get("acceptCookie")
  );

  const cookiesHandler = () => {
    cookies.set("acceptCookie", true, {
      httpOnly: true,
      sameSite: "Strict",
      secure: true,
      path: "/",
    });
    setIsCookies(true);
  };

  const declineCookiesHandler = () => {
    setIsCookies(true);
  };

  return (
    <>
      {!isCookies && (
        <section className="cookies-sec">
          <div className="cookies-row">
            <div className="cookies-heading">
              <img src="/image/cookies.png" alt="cookies" />
              <p>This website uses cookies</p>
            </div>
            <div className="cookies-text-btn">
              <p className="cookies-paragrapgh">
                We use cookies to maintain your session and ensure your
                information stays secure while using our site. Rest assured, we
                do not track your activity for marketing or promotional
                purposes. For more information see or{" "}
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy.
                </a>{" "}
                By continuing, you agree to our use of these essential cookies.
              </p>
              <div className="cookies-btns">
                <Link onClick={() => declineCookiesHandler()}>
                  <button className="decline-btn">Cancel</button>
                </Link>
                <Link onClick={() => cookiesHandler()}>
                  <button className="allow-btn">OK</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CookiesSection;
