import { useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobAppliedRight from "../../Common/JobRecord/JobAppliedRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";

const JobApplied = ({
  showMsg,
  appliedJobsCount,
  appliedJobs,
  searchJobApplied,
  onSearchChange,
}) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);

  const countHandler = () => {
    if (count < appliedJobsCount) setCount((prevCount) => prevCount + 10);
  };

  if (appliedJobs?.length === 0 && searchJobApplied === "") return null;

  return (
    <section className="job-applied" id="job_applied">
      <div className="job-heading">
        <div className="container container-site">
          <div className="job-heading-home job-heading-local w-100 position-relative">
            <h2>Jobs applied</h2>
            <div
              className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                }`}
              onClick={() => {
                setOpenSearch(true);
              }}
            >
              <img
                className="job-searchIcon job-searchIcon-mbl"
                src="/image/job-searchIcon.png"
                alt="job-searchBar"
              />
            </div>
            <div
              className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                }`}
            >
              <img
                className="job-searchIcon"
                src="/image/job-searchIcon.png"
                alt="job-searchBar"
                onClick={() => {
                  setOpenSearch(false);
                }}
              />
              <label for='search-job-4' className="visibility-hidden opacity-0 position-absolute">search-job</label>
              <input
                placeholder="Search jobs"
                className="job-search-input"
                id='search-job-4'
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container container-site mt-md-2 mt-4">
        <div className="space-inner-row">
          {appliedJobs?.length > 0 ? (
            appliedJobs?.slice(0, count)?.map((prod, index) => (
              <>
                {index != 0 && (<div className="divider d-md-block d-none"></div>)}
                <div className="Job-Opportunities-row mt-md-0 mt-3">
                  <JobRecordLeft JobData={prod} index={index} />
                  <JobAppliedRight 
                    JobData={prod}        
                    hashId={prod?.hashed_id}
                    slug={prod?.slug}  
                    date={prod?.date_applied} 
                    selectedJobId={prod?.id} 
                    conversation={prod?.conversation}
                  />
                </div>
              </>
            ))
          ) : (
            <JobNoRecordFound />
          )}
          <div className="divider"></div>
        </div>
      </div>
      {
        appliedJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>More jobs</button>
            </Link>
          </div>
        )
      }
    </section >
  );
};

export default JobApplied;
