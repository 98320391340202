import React, { useEffect, useState } from 'react'
import "./index.css"
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../../api';

const PreScreeningQuestions = () => {
    const [isStepCreated, setIsStepCreated] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const jID = searchParams.get("job_id");
    const handleNavigate = () => {      
      
      navigate(`/pre-question?job_id=${jID}`);
    }

    const editInterviewQuestion = async () => {
      navigate(`/pre-question?job_id=${jID}&edit=true`);
    }

    const getPreScreeningQus = async () => {
      try {
        const response = await API.get(`/job-board/get-job-questions?currentJobId=${jID}`);
        setQuestions(response.data.questions)
        setJobTitle(response.data?.job?.full_title)
        if(response.data?.questions?.length<1) {
          setIsStepCreated(true)
        }
        else{
          setIsStepCreated(false)
        }
      } catch (error) {
        console.error(error);
      }

    }

    useEffect(() => {
      getPreScreeningQus();
    }, [])
  return (
    <>
     <div className="page-space bg-clr-admin">
      <div className="page-title-heading align-items-center">
        <div className='flex-start-center'>
          <img src='/image/form-back-arrow.png' alt='back' className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
          <h2 className='m-0'>Pre-screening questions</h2>
        </div>
        {
          isStepCreated && (<button className='btn btn-black px-3 py-2' onClick={handleNavigate}>Pre-screening questions +</button>)
        }
      </div>
     
      <div className='job-position mt-4'>
        <img src="/image/bag-icon.png" alt='img' />
        <h3 className='m-0'>{jobTitle}</h3>
        {
          !isStepCreated && ( <button className='btn btn-transparent' onClick={editInterviewQuestion}>
            <img style={{width:"20px", height:"20px", cursor:"pointer"}} src="/image/edit-1.png" alt='img'  />
            </button>)
        }
       
      </div>
      <div className='cand-card-list'>
        {
          isStepCreated ? (<><div class="divider pt-3 mt-3"></div><div className='no-interview text-center'><p>Currently there are no pre-screening questions</p></div></>) :
          (<div className='drag-sec'> 
            <div className='que-container'>
                <div className='que-content'>
                  {
                    questions.map((question, id) => {
                      return(
                        <div className='single-ques' key={id}>
                          <p className='fs-16 mb-1'><strong className='text-theme fw-700'>Question {id+1}</strong>/{questions.length}{question.is_required === 1 && (<span className='text-danger'>*</span>)}</p>
                          <div className='row'>
                            <div className='col-md-9'>
                              <p className='fw-700 mb-2'>{question.question_text}</p>
                              {
                                // question.response_type === 'radio' ? <>
                                //   {question.answer_options.map((ans, idx) => {
                                //     return(
                                //       <p className='py-1'>{ans.option_text}</p>
                                //     )
                                //   })} 
                                // </> :
                                 <div className='w-40'>
                                  <label className='fw-700' htmlFor='answerIs'>{`${question?.fillable_part_value ? `☄ ${question?.fillable_part_value}` : "" }`}</label>
                                  {/* <select class="form-select" id='answerIs' aria-label="Default select example">
                                    {question?.answer_options.map((ans, idx) => (
                                      <option value={ans.option_text} key={idx}>{ans.option_text}</option>
                                    ))}
                                    
                                  </select> */}
                                </div>
                              }
                            </div>
                            <div className='col-md-3'>
                              <p className='fw-700 mb-2'>
                                Ideal answer
                              </p>
                              <p className='fw-700 text-theme py-1'>{question.ideal_answer}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }                  
                </div>
            </div>  
          </div>)
        }
      </div>
    </div>
    </>
  )
}

export default PreScreeningQuestions