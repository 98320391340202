import React, { useEffect, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

const CompanyCulture = ({title,points, imag, editable, updateValue }) => {
  const [hasSelected, setHasSelected] = useState(false);
  const handleRadioChange = (e, title) => {
    if(updateValue) {
      updateValue(e, title);
    }
  };

  useEffect(() => {
    const isSelected = points.some(item => item.is_selected === '1')
    setHasSelected(isSelected);
  }, [points])


  return (
    <div className='culture-box'>
      {imag ? <img src={imag} alt='static-img'/> : ''}
      <h3 className='pb-2 d-flex align-items-center gap-1'>
        {title}            
        {
          hasSelected ? '' : <img src='/image/info.png' alt='info' className='m-0' />
        }
      </h3>
      <ul>
        {
        points?.map((level, index) =>{
          return (
            <li key={index}>
              {
                editable ? <>
                <div class="form-check">
                  <input
                    type="radio"
                    className="form-check-input bg-white"
                    name={`${title.replace(/\s+/g, '').toLowerCase()}`}
                    value={level.name}
                    defaultChecked={level.is_selected === '1'}
                    id={`${title.replace(/\s+/g, '').toLowerCase()}-${level.id}`}
                    onChange={(e) => handleRadioChange(e, title)}
                  />
                  <label className="form-check-label" key={level.id} for={`${title.replace(/\s+/g, '').toLowerCase()}-${level.id}`}>
                  {level.name}
                  </label>
                </div>

              </> : <div className='d-flex align-items-center gap-2'>
              {level.is_selected === '1' ? 
                  <img src='image/check-demo.png' alt='check-demo' className='mb-1' /> : ''
                }
               <span className={`${level.is_selected === '0' ? "ps-4 ms-1" : '' }`}> {level.name}</span>
              </div>
              }
            </li>
          )
        })
        }
      </ul>
    </div>
  )
}

export default CompanyCulture