import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";
import {
  candtatusBulkResumeChangeAsync,
  firstImpBulkResumeChangeAsync,
  fvtBulkResumeCandAsync,
  getBulkAnalysisDataAsync,
  setBulkResumeProcessId,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import {
  getProgressBarColorClass,
  getProgressBarWidth,
} from "../../../../utils/progressbarFn";
import BulkApplicationTable from "../../../../components/Admin/BulkResume/BulkApplicationTable";
import { BULK_ANALYSIS_SORT, SORT_DIRECTION } from "../../../../constants";

const BulkAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { processId } = useSelector((state) => state.bulkResumeCanDetail);

  const [msg, setMsg] = useState("");
  const [bulkResumeData, setBulkResumeData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(BULK_ANALYSIS_SORT.ID);
  const [sortDirection, setSortDirection] = useState("desc");

  const [overallMatch, setOverallMatch] = useState([]);
  const [experience, setExperience] = useState([]);
  const [impression, SetImpression] = useState([]);

  const [statusDropDown, setStatusDropDown] = useState([]);

  const [selectedOverallMatch, setSelectedOverallMatch] = useState({
    label: "",
    value: null,
  });

  const [selectedExperience, setSelectedExperience] = useState({
    label: "",
    value: null,
  });

  const [selectedImpression, setSelectedImpression] = useState({
    id: null,
    impression: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navHandler = () => {
    dispatch(setBulkResumeProcessId(null));
    navigate("/bulk-resume-analysis");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onOverallMatchClick = (label, value) => {
    setSelectedOverallMatch({
      label: label,
      value: value,
    });
  };

  const onOverallMatchClean = () => {
    setSelectedOverallMatch({
      label: "",
      value: null,
    });
  };

  const onExperienceClick = (label, value) => {
    setSelectedExperience({
      label: label,
      value: value,
    });
  };

  const onExperienceClean = () => {
    setSelectedExperience({
      label: "",
      value: null,
    });
  };

  const onImpressionClick = (id, impression) => {
    setSelectedImpression({
      id: id,
      impression: impression,
    });
  };

  const onImpressionClean = () => {
    setSelectedImpression({
      id: null,
      impression: "",
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSortByHandler = (value) => {
    if (sortBy === value) {
      setSortDirection((prevDirection) =>
        prevDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
      );
    } else {
      setSortDirection(SORT_DIRECTION.DESC);
    }
    setSortBy(value);
  };

  /*****************************/

  const onFvtClick = async (candidate_id, is_favorite) => {
    const selectedCandidate = bulkResumeData?.find(
      (candidate) => candidate?.candidate_id === candidate_id
    );
    if (selectedCandidate?.impression === 1) {
      toast.error(
        "Not a Good FitCandidate cannot be added to Favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }

    dispatch(
      fvtBulkResumeCandAsync({
        candidate_id: candidate_id,
        is_favorite: is_favorite === 0 ? 1 : 0,
      })
    )
      .then((response) => {
        const res = response?.payload;
        const updatedCandidate = bulkResumeData?.map((candidate) => {
          if (candidate?.candidate_id === candidate_id) {
            return {
              ...candidate,
              is_favorite: candidate?.is_favorite === 0 ? 1 : 0,
            };
          }
          return candidate;
        });
        setBulkResumeData(updatedCandidate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* applicants on first impression click */
  const onFirstImpressionClick = async (candidate_id, impression_id) => {
    if (candidate_id === null || impression_id === null) return;

    const selectedCandidate = bulkResumeData?.find(
      (candidate) => candidate?.candidate_id === candidate_id
    );
    if (parseInt(selectedCandidate?.is_favorite) === 1 && impression_id === 1) {
      toast.error("Favourite applicant cannot have not a good fit status.", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }

    dispatch(
      firstImpBulkResumeChangeAsync({
        candidate_id: candidate_id,
        impression_id: impression_id,
      })
    )
      .then((response) => {
        const res = response?.payload;

        const updatedCandidate = bulkResumeData?.map((candidate) => {
          if (candidate?.candidate_id === candidate_id) {
            return {
              ...candidate,
              impression: impression_id,
            };
          }
          return candidate;
        });
        setBulkResumeData(updatedCandidate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Application status Change Handler */
  const onCandidateStatusChangeHandler = async (
    candidate_id,
    candidate_status_id,
    current_Status_Id
  ) => {
    if (candidate_id === null || candidate_status_id === null) return;
    if (current_Status_Id === candidate_status_id) return;

    dispatch(
      candtatusBulkResumeChangeAsync({
        candidate_id: candidate_id,
        candidate_status_id: candidate_status_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        const updatedCandidate = bulkResumeData?.map((candidate) => {
          if (candidate?.candidate_id === candidate_id) {
            const { id, status } = statusDropDown?.find(
              (item) => item?.id === candidate_status_id
            );
            return {
              ...candidate,
              candidate_status_id: id,
              candidate_status_label: status,
            };
          }
          return candidate;
        });
        setBulkResumeData(updatedCandidate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBulkAnalysisData = async () => {
    if (processId !== null) {
      try {
        setMsg("");
        dispatch(
          getBulkAnalysisDataAsync({
            process_id: processId,
            search: search,
            currentPage: currentPage,
            perPage: perPage,
            overallMatch: selectedOverallMatch?.value,
            experience: selectedExperience?.value,
            impressionId: selectedImpression?.id,
            sortBy: sortBy,
            sortDirection: sortDirection
          })
        )
          .then(async (response) => {
            const res = response?.payload;

            if (res?.status === true) {
              setBulkResumeData(res?.data);
              setOverallMatch(res?.overAllMatch);
              setExperience(res?.experience);
              SetImpression(res?.impressions);
              setStatusDropDown(res?.statuses);

              setTotalPages(res?.last_page);
              if (
                res?.data?.length === 0 &&
                search === "" &&
                selectedOverallMatch?.value === null &&
                selectedExperience?.value === null &&
                selectedImpression?.id === null
              )
                setMsg("Polycareers AI is processing your request.");
              else if (
                res?.data?.length === 0 &&
                (search !== "" ||
                  selectedOverallMatch?.value !== null ||
                  selectedExperience?.value !== null ||
                  selectedImpression?.id !== null)
              )
                setMsg("Please make another selection. No record found.");
              else {
                if (res?.data?.length !== 0) setMsg("");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getBulkAnalysisData();
  }, [
    selectedOverallMatch,
    selectedExperience,
    selectedImpression,
    search,
    currentPage,
    perPage,
    sortBy,
    sortDirection
  ]);

  return (
    <div className="page-space bg-clr-admin">
      <div className="form-heading-prom">
        <img
          src="/image/form-back-arrow.png"
          alt="back-arrow"
          onClick={() => navHandler()}
        />
        <h2>Bulk analysis</h2>
      </div>
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Overall match</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedOverallMatch?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedOverallMatch?.label === ""
                      ? "Overall match"
                      : selectedOverallMatch?.label}
                  </p>
                  {selectedOverallMatch?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onOverallMatchClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {overallMatch?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {overallMatch?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onOverallMatchClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Experience</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedExperience?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedExperience?.label === ""
                      ? "Experience"
                      : selectedExperience?.label}
                  </p>
                  {selectedExperience?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onExperienceClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {experience?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {experience?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onExperienceClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">First impression</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedImpression?.impression !== "" &&
                      "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedImpression?.impression === ""
                      ? "First impression"
                      : selectedImpression?.impression}
                  </p>
                  {selectedImpression?.impression === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onImpressionClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {impression?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {impression?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onImpressionClick(item?.id, item?.impression);
                        }}
                      >
                        {item?.impression}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <ShowRows
        perPage={perPage}
        onChangePerPageHandler={onChangePerPageHandler}
      />
      <div className="department-table-imge">
        <div className="col-user-table">
          <table className="depart-table-left">
            <thead className="tb-heading-user">
              <tr>
                <th scope="col">
                  <div className="th-admin-heading">
                    Full name{" "}
                    <img
                      className="arrow-icon icon-cursor"
                      src={
                          sortBy === BULK_ANALYSIS_SORT.FIRST_NAME &&
                          sortDirection === SORT_DIRECTION.DESC
                          ?  "/image/bars-arrow-down.png"
                          : "/image/bars-arrow-up.png"
                      }
                      alt=""
                      onClick={() =>
                        onSortByHandler(BULK_ANALYSIS_SORT.FIRST_NAME)
                      }
                    />
                  </div>
                </th>
                <th scope="col">
                  <div className="th-admin-heading">
                    Experience{" "}
                    <img
                      className="arrow-icon icon-cursor"
                      src={
                        sortBy === BULK_ANALYSIS_SORT.OVERALL_EXPERIENCE &&
                        sortDirection === SORT_DIRECTION.DESC
                        ?  "/image/bars-arrow-down.png"
                        : "/image/bars-arrow-up.png"
                      }
                      alt=""
                      onClick={() =>
                        onSortByHandler(BULK_ANALYSIS_SORT.OVERALL_EXPERIENCE)
                      }
                    />
                  </div>
                </th>
                <th scope="col">
                  <div className="th-admin-heading">Candidate Status</div>
                </th>
                <th scope="col">
                  <div className="th-admin-heading">
                    Overall match{" "}
                    <img
                      className="arrow-icon icon-cursor"
                      src={
                        sortBy === BULK_ANALYSIS_SORT.OVERALL_MATCH &&
                        sortDirection === SORT_DIRECTION.DESC
                        ?  "/image/bars-arrow-down.png"
                        : "/image/bars-arrow-up.png"
                      }
                      alt=""
                      onClick={() =>
                        onSortByHandler(BULK_ANALYSIS_SORT.OVERALL_MATCH)
                      }
                    />{" "}
                  </div>
                </th>
                <th scope="col" className="th-status-res">
                  <div className="th-admin-heading">Impression</div>
                </th>
                <th scope="col" className="th-size-six">
                  <div className="th-admin-heading">
                    Date applied{" "}
                    <img
                      className="arrow-icon icon-cursor"
                      src={
                        sortBy === BULK_ANALYSIS_SORT.DATE_APPLIED &&
                        sortDirection === SORT_DIRECTION.DESC
                        ?  "/image/bars-arrow-down.png"
                        : "/image/bars-arrow-up.png"
                      }
                      alt=""
                      onClick={() =>
                        onSortByHandler(BULK_ANALYSIS_SORT.DATE_APPLIED)
                      }
                    />
                  </div>
                </th>
                <th scope="col" className="th-size-seven">
                  <div className="th-admin-heading">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody className="tb-user">
              {bulkResumeData?.map((item, index) => (
                <BulkApplicationTable
                  key={index}
                  index={index}
                  item={item}
                  candidateStatusesList={statusDropDown}
                  onFvtClick={onFvtClick}
                  onFirstImpressionClick={onFirstImpressionClick}
                  onCandidateStatusChangeHandler={
                    onCandidateStatusChangeHandler
                  }
                />
              ))}
            </tbody>
          </table>

          {bulkResumeData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
    </div>
  );
};

export default BulkAnalysis;
