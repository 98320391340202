import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  searchWord: "",
  jobTypeId: null,
  selectJobType: "",
  jobLocationId: null,
  selectJobLocation: "",
  dateId:null,
  selectDate:"",
  mostSearchJob:false,
  cleared: false,
};
const JobSearchSlice = createSlice({
  name: "jobSearch",
  initialState,
  reducers: {
    setSearchWord: (state, action) => {
      state.searchWord = action.payload;
    },
    setSelectJobType: (state, action) => {
      state.jobTypeId = action.payload.id;
      state.selectJobType = action.payload.name;
    },
    setSelectJobLocationId: (state, action) => {
      state.jobLocationId = action.payload;
    },
    setSelectJobLocation: (state, action) => {
      state.selectJobLocation = action.payload;
    },
    setSelectDate: (state, action) => {
      state.dateId = action.payload.id;
      state.selectDate = action.payload.name;
    },
    setMostSearchJob: (state, action) => {
      state.mostSearchJob = action.payload;
    },
    setClearFilter: (state) => {
      state.searchWord = "";
      state.jobTypeId = null;
      state.selectJobType = "";
      state.jobLocationId = null;
      state.selectJobLocation = "";
      state.dateId = null;
      state.selectDate ="";
      state.cleared = true;
    },
    resetClearedFlag: (state) => {
      state.cleared = false; 
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchWord,
  setSelectJobType,
  setSelectJobLocationId,
  setSelectJobLocation,
  setSelectDate,
  setMostSearchJob,
  setClearFilter,
  resetClearedFlag 
} = JobSearchSlice.actions;

export default JobSearchSlice.reducer;



export const getJobTypesDataAsync = createAsyncThunk(
  "public/getJObTypesData",
  async () => {
    try {
      const response = await API.get(`/job-board/job-types`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);