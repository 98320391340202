import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setFeedbackModel } from "../../../redux/slices/homeSlice";
import {
  setActiveTab,
  setLoginModel,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import { ADMIN, CANDIDATE, COUNTRY_ISO_CODE } from "../../../constants";
import "./Banner.css";
import { getAvailableJobPostsDataAsync, getProductPricesDataAsync, setBuyJobsModelModel } from "../../../redux/slices/JobDecisionSlice";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryISOCode, openFeedbackModel } = useSelector(
    (state) => state.home
  );

  const scroll = "search";
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  const [productPrice, setProductPrice] = useState("")

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(getAvailableJobPostsDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          if (
            res?.availableJobPosts === 0 ||
            res?.availableJobPosts === null
          ) {
            dispatch(setBuyJobsModelModel(true));
            getProductPrice();
          } else {
            dispatch(setActiveTab("employer"));
            dispatch(setPostJobMode("add"));
            dispatch(setPostJobId(null));
            navigate("/post-job");
          }
        }
      })
      .catch((error) => {
        dispatch(setBuyJobsModelModel(false));
        console.log(error);
      });
    }
  };

  const getProductPrice = async () => {
    dispatch(getProductPricesDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setProductPrice(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const onFeedBackClickHandler = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(setFeedbackModel(true));
    }
  };

  return (
    <>
    <section class="home-benner-sec">
      <div className="banner-top container-site">
        <div className="banner-row-poly">
          <div className="banner-heading-poly">
            <h1>
              Your search starts <span> here.</span>
            </h1>
            <div className="two-btn-poly">
              {!ADMIN.includes(user?.user_type) && (
                <Link to={`/job-search/${scroll}`}>
                  <button className="find-job-poly">Find a job</button>
                </Link>
              )}
              {!CANDIDATE.includes(user?.user_type) && (
                <button
                  className="post-job-poly"
                  onClick={() => postJobClickHanlder()}
                >
                  Post a job
                </button>
              )}
            </div>
            <div class="banner-img">
              <img
                src={
                  countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "/image/banner-poly-CA.png"
                    : "/image/banner-poly-MK.png"
                }
                alt={
                  countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "banner-poly-CA"
                    : "banner-poly-MK"
                }
              />         
            </div>
          </div>
        </div>
        <button class="feed-back-btn" onClick={() => onFeedBackClickHandler()}>
          Feedback
        </button>
      </div>
      </section>
    </>
  );
};

export default Banner;
