import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getCurrentFormattedDate from "../../../../utils/getCurrentFormattedDate";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";
import {
  setActiveSideBar,
  setFormMode,
} from "../../../../redux/slices/AdminDashboardSlice";
import {
  getBulkResumeListingDataAsync,
  setBulkResumeProcessId,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import { setTotalBulkResumeCredit } from "../../../../redux/slices/buyBulkAnalysisSlice";
import {
  getProgressBarColorClass,
  getProgressBarWidth,
} from "../../../../utils/progressbarFn";
import {
  BULK_RESUME_ANALYSIS_SORT,
  PARSING_STATUS,
  SORT_DIRECTION,
} from "../../../../constants";
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
import ConfettiModal from "../../../../components/Common/ConfettiModal/ConfettiModal";
import "./index.css";

const BulkResumeAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);
  const [bulkCreditsPayment, setBulkCreditsPayment] = useState(false);

  const [msg, setMsg] = useState("");
  const [bulkResumeData, setBulkResumeData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [status, setStatus] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [availableBulkCredits, setAvailableBulkCredits] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navHandler = () => {
    dispatch(setFormMode("add"));

    dispatch(
      setActiveSideBar({
        id: "41",
        name: "Resume batch tool",
        value: "Resume batch tool",
      })
    );

    navigate("/bulk-resume-upload");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSortByHandler = (value) => {
    if (sortBy === value) {
      setSortDirection((prevDirection) =>
        prevDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
      );
    } else {
      setSortDirection(SORT_DIRECTION.DESC);
    }
    setSortBy(value);
  };

  /*****************************/

  const getBulkResumeListingData = async () => {
    try {
      setMsg("");
      dispatch(
        getBulkResumeListingDataAsync({
          userId: user?.id,
          search: search,
          status: selectedStatus?.value,
          currentPage: currentPage,
          perPage: perPage,
          sortBy: sortBy,
          sortDirection: sortDirection,
        })
      )
        .then(async (response) => {
          const res = response?.payload;

          if (res?.status === true) {
            const AvailableCredits = res?.available_bulk_credits
              ? res?.available_bulk_credits
              : 0;
            setAvailableBulkCredits(AvailableCredits);
            setBulkResumeData(res?.data);
            setStatus(res?.dropdowns?.status_data);
            setTotalPages(res?.last_page);
            dispatch(setTotalBulkResumeCredit(AvailableCredits));
            if (
              res?.data?.length === 0 &&
              search === "" &&
              selectedStatus?.value === null
            )
              setMsg("Please upload a bulk resume to view data.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" || selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBulkResumeListingData();
  }, [selectedStatus, search, currentPage, perPage, sortBy, sortDirection]);

  const handleBulkCreditsModel = () => {
    setBulkCreditsPayment(!bulkCreditsPayment);
    navigate("/bulk-resume-analysis");
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("bulkCreditsPayment");
    if (paramValue) {
      setBulkCreditsPayment(true);
    }
  }, []);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>Resume batch tool</h2>{" "}
        {/* <p className="page-sub-heading">Available credits <b>({availableBulkCredits ? availableBulkCredits : 0})</b></p>
         */}
        <button
          className="create-department"
          type="text"
          onClick={() => navHandler()}
        >
          Bulk resume upload +
        </button>
      </div>{" "}
      <div className="resume-batch-sub-heading">
        <div className="resume-batch-sub-heading-1">
          {" "}
          <p>
            You have{" "}
            <span>{availableBulkCredits ? availableBulkCredits : 0}</span>{" "}
            PolyCareers credits available
          </p>
        </div>
        <div className="resume-batch-sub-heading-2">
          <p>
            Got a lot of resumes? Save time with our Resume Batch Tool. Buy
            credits in the System Hub, zip your resumes, upload them, and add
            your job description. Our AI ranks and analyzes top candidates, with
            prices starting at $7.99 for 50 resumes! We’ll notify you when it's
            done!
          </p>
        </div>
      </div>{" "}
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">jon-search-box</label>
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <ShowRows
        perPage={perPage}
        onChangePerPageHandler={onChangePerPageHandler}
      />
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Job title{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortBy === BULK_RESUME_ANALYSIS_SORT.JOB_TITLE &&
                          sortDirection === SORT_DIRECTION.DESC
                            ? "/image/bars-arrow-down.png"
                            : "/image/bars-arrow-up.png"
                        }
                        alt=""
                        onClick={() =>
                          onSortByHandler(BULK_RESUME_ANALYSIS_SORT.JOB_TITLE)
                        }
                      />{" "}
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">Zip file name </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Total files{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortBy === BULK_RESUME_ANALYSIS_SORT.TOTAL_RESUMES &&
                          sortDirection === SORT_DIRECTION.DESC
                            ? "/image/bars-arrow-down.png"
                            : "/image/bars-arrow-up.png"
                        }
                        alt=""
                        onClick={() =>
                          onSortByHandler(
                            BULK_RESUME_ANALYSIS_SORT.TOTAL_RESUMES
                          )
                        }
                      />
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Files parsed{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortBy === BULK_RESUME_ANALYSIS_SORT.RESUME_PARSED &&
                          sortDirection === SORT_DIRECTION.DESC
                            ? "/image/bars-arrow-down.png"
                            : "/image/bars-arrow-up.png"
                        }
                        alt=""
                        onClick={() =>
                          onSortByHandler(
                            BULK_RESUME_ANALYSIS_SORT.RESUME_PARSED
                          )
                        }
                      />
                    </div>
                  </th>
                  <th scope="col">
                    <div className="th-admin-heading">
                      Files in error{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortBy ===
                            BULK_RESUME_ANALYSIS_SORT.RESUME_IN_ERROR &&
                          sortDirection === SORT_DIRECTION.DESC
                            ? "/image/bars-arrow-down.png"
                            : "/image/bars-arrow-up.png"
                        }
                        alt=""
                        onClick={() =>
                          onSortByHandler(
                            BULK_RESUME_ANALYSIS_SORT.RESUME_IN_ERROR
                          )
                        }
                      />
                    </div>
                  </th>
                  <th scope="col" className="th-status-res">
                    <div className="th-admin-heading">Status</div>
                  </th>
                  <th scope="col" className="th-size-six">
                    <div className="th-admin-heading">Total process time </div>
                  </th>
                  <th scope="col" className="th-date-submitted">
                    <div className="th-admin-heading">
                      Date submitted{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortBy === BULK_RESUME_ANALYSIS_SORT.DATE_SUBMITTED &&
                          sortDirection === SORT_DIRECTION.DESC
                            ? "/image/bars-arrow-down.png"
                            : "/image/bars-arrow-up.png"
                        }
                        alt=""
                        onClick={() =>
                          onSortByHandler(
                            BULK_RESUME_ANALYSIS_SORT.DATE_SUBMITTED
                          )
                        }
                      />
                    </div>
                  </th>
                  <th scope="col" className="th-size-seven">
                    <div className="th-admin-heading">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-user">
                {bulkResumeData?.length > 0 &&
                  bulkResumeData?.map((item, index) => (
                    <tr>
                      <td className="">{item?.job_title}</td>
                      <td className="">{item?.zip_name}</td>
                      <td>{item?.total_resumes}</td>
                      <td>{item?.resume_parsed}</td>
                      <td>{item?.resume_in_error}</td>
                      <td>
                        {item?.status}
                        <div className="progress">
                          <div
                            className={`progress-bar progress-bar-color ${getProgressBarColorClass(
                              item?.status?.toLowerCase()
                            )}`}
                            role="progressbar"
                            style={{
                              width: getProgressBarWidth(
                                item?.status?.toLowerCase()
                              ),
                            }}
                            aria-valuenow={parseInt(
                              getProgressBarWidth(item?.status?.toLowerCase())
                            )}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td className="resume-batch-t-p-time">
                        {item?.status?.toLowerCase() ===
                          PARSING_STATUS.COMPLETED &&
                        item?.total_process_time ? (
                          item?.total_process_time
                        ) : (
                          <div className="dash-td-por" />
                        )}
                      </td>
                      <td>{getCurrentFormattedDate(item?.date_submitted)}</td>
                      <td>
                        {" "}
                        <div className="four-resume-img">
                          <div className="img-text-tooltip">
                            <img
                              src="/image/jd-des-res.png"
                              alt=""
                              className="show-tooltip icon-cursor"
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-resume-view-job");
                              }}
                            />
                            <div className="tooltip-jd-resume">
                              View job descritpion
                            </div>
                          </div>
                          <div className="img-text-tooltip">
                            <img
                              src="/image/request-ana-res.png"
                              alt=""
                              className="show-tooltip3 icon-cursor"
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-resume-req-detail");
                              }}
                            />
                            <div className="tooltip-request-detail-res">
                              Request detail
                            </div>
                          </div>
                          <div className="img-text-tooltip">
                            <img
                              src="/image/bulk-ana-res.png"
                              alt=""
                              className="show-tooltip2 icon-cursor"
                              onClick={() => {
                                dispatch(
                                  setBulkResumeProcessId(item?.process_id)
                                );
                                navigate("/bulk-analysis");
                              }}
                            />
                            <div className="tooltip-request-analysis-res">
                              Bulk analysis
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {bulkResumeData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
      {bulkCreditsPayment && (
        <ContentModal closeModal={handleBulkCreditsModel}>
          <ConfettiModal
            closeModal={handleBulkCreditsModel}
            title={<p>Credits successfully added to your total.</p>}
          />
        </ContentModal>
      )}
    </div>
  );
};

export default BulkResumeAnalysis;
