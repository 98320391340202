import { useSelector } from "react-redux";
import CompanySlotCard from "../../../../../components/Admin/Promotion/CompanySlotCard";
import { COUNTRY_ISO_CODE } from "../../../../../constants";

const HomePageHeader = () => {
  const { userCountry  } = useSelector((state) => state.auth);
  return (
    <div className="text-center ">
      <h1 className="demo-page-home-title">Homepage</h1>
      <p className="demo-page-home-subtitle mb-5">Your search starts here.</p>
      <img
        src={
          userCountry?.name === COUNTRY_ISO_CODE.CA
            ? "/image/banner-poly-CA.png"
            : "/image/banner-poly-MK.png"
        }
        width={360}
        alt={
          userCountry === COUNTRY_ISO_CODE.CA
            ? "banner-poly-CA"
            : "banner-poly-MK"
        }
      />
    </div>
  );
};

const SearchPageHeader = () => {
  return (
    <div className="text-center">
      <h1 className="demo-page-home-title">Search page</h1>
      <div className="mt-3 justify-content-between demo-page-search-container">
        <div className="demo-page-search bg-white p-3 text-secondary rounded-8">
          <div>Keywords, company, skills...</div>
          <div className="border-end border-start px-3 d-flex justify-content-between">
            <div>Job type</div>
            <div>
              <img src="/image/admin-d.png" />
            </div>
          </div>
          <div className="ps-3 d-flex justify-content-between">
            <div>Job location</div>
            <div>
              <img src="/image/admin-d.png" />
            </div>
          </div>
        </div>

        <div className="rounded-8 bg-white p-3 d-flex justify-content-between text-secondary date-posted-field">
          <div>Date posted</div>
          <div>
            <img src="/image/admin-d.png" />
          </div>
        </div>
        <div className="rounded-8 bg-secondary text-white p-3">Find jobs</div>
      </div>
      <div className="mt-3 text-start  search-jobs-container">
        <div className="job-ad-position-title fw-bolder">
          Most searched jobs:
        </div>
        <div className="d-flex flex-wrap ms-3 pills-container">
          <div className="rounded-pill p-2 text-center bg-muted">
            Software Engineer
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            Work From Home
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            Artificial Intelligence
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">Hybrid</div>
          <div className="rounded-pill p-2 text-center bg-muted">AI jobs</div>
          <div className="rounded-pill p-2 text-center bg-muted">
            UI/UX Designer
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            Manager Jobs
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            React Developer
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            Health jobs
          </div>
          <div className="rounded-pill p-2 text-center bg-muted">
            Data Entry
          </div>
        </div>
        <div className="job-ad-position-title text-center me-3 fs-6">
          <u>Clear Filter</u>
        </div>
      </div>
    </div>
  );
};

export const DemoPositionPage = ({
  allPosition,
  isJobAd,
  selectedAllPosition,
  occupiedPosition,
  onCompanyCardAddClick,
  isSearchPage,
  enableSearchSidebar,
  sections,
  dates,
  selectedJob,
}) => {
  const Header = isSearchPage ? SearchPageHeader : HomePageHeader;
  return (
    <div>
      <div className="mb-5 p-3 pt-5">
        <Header />
      </div>
      <hr />

      {!isSearchPage && isJobAd && (
        <>
          <div className="px-3 pt-3 pb-3">
            <p className="demo-page-home-section-title mb-4">Promoted companies</p>
            <span className="demo-page-home-section-subtitle">Companies promotion type showcases your company on the homepage for more exposure and visibility.</span>
            <CompanySlotCard
              allPosition={[0, 0, 0, 0, 0, 0]}
              selectedAllPosition={selectedAllPosition}
              occupiedPosition={occupiedPosition}
              onCompanyCardAddClick={onCompanyCardAddClick}
              isJobAd={false}
              disableAll={true}
            />
          </div>
          <hr />
        </>
      )}

      {!isSearchPage && allPosition?.length > 0 && (
        <div className="px-3 pt-3 pb-5">
          <p className="demo-page-home-section-title mb-4">
            {isJobAd ? "Choose your Job ad placement" : "Promoted companies"}
          </p>
          <CompanySlotCard
            allPosition={allPosition}
            selectedAllPosition={selectedAllPosition}
            occupiedPosition={occupiedPosition}
            onCompanyCardAddClick={onCompanyCardAddClick}
            isJobAd={isJobAd}
            disableAll={false}
          />
        </div>
      )}

      {isSearchPage && (
        <>
        <div className="my-5">
          <div class="left-right-card-slots">
          <div class="left-side-card-slot">
            <p className="left-side-slot-heading">Side promotion</p>
              <CompanySlotCard
                allPosition={allPosition?.filter(item => item?.section_id === 2)}
                selectedAllPosition={
                  selectedAllPosition
                }
                occupiedPosition={           
                  occupiedPosition
                }
                onCompanyCardAddClick={onCompanyCardAddClick}
                isJobAd={false}
                disableAll={false}
                showVertical
                selectionKey="position"
              />          
          </div>
          <div className="right-side-card-slot">            
              <CompanySlotCard
                allPosition={allPosition?.filter(item => item?.section_id === 1)}
                selectedAllPosition={
                  selectedAllPosition
                }
                occupiedPosition={
                  occupiedPosition
                }
                onCompanyCardAddClick={onCompanyCardAddClick}
                isJobAd
                disableAll={false}
              />       
          </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};
