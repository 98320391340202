const setDateTime = (data) => {
  if (!data) return;

  const utcDate = new Date(data); // Parse input date
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Detect user's timezone

  const today = new Date(); // Current date
  const yesterday = new Date(today); // Yesterday's date
  yesterday.setDate(today.getDate() - 1); // Set yesterday's date

  let formattedDate = "";

  // Compare dates in user's timezone
  const localDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimezone }) // Convert UTC to local date
  );

  if (localDate.toDateString() === today.toDateString()) {
    formattedDate = "Today";
  } else if (localDate.toDateString() === yesterday.toDateString()) {
    formattedDate = "Yesterday";
  } else {
    // Format the date for other cases
    formattedDate = localDate.toLocaleDateString('en-US', {
      timeZone: userTimezone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  }

  return formattedDate;
};

export default setDateTime;
