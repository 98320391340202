import React, { useEffect, useState } from 'react'
import './CandidateCard.css'
import { useNavigate } from 'react-router-dom';
import { changeJobStatusAsync, setPostJobEditForm } from '../../../redux/slices/employerDashboardSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setActiveTab, setPostJobId, setPostJobMode } from '../../../redux/slices/AuthSlice';

const CandidateCard = ({props, onChangeJobStatus,JobData, getAllJobs}) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if(props.is_active){
      setIsActive(true)
    }
  }, [props]);

  const JobDetailHandle = () => {
    navigate('/job-detail', {
      state: { jobId: props.id }
    });
  }
  const handleCandidateJourney = () => {
    navigate(`/interview-journey?job_id=${props.id}`);
  }

  const handlePreScreeing = () => {
    navigate(`/pre-screening-question?job_id=${props.id}`);
  }

  const onSwitchChange = (data) => {
    console.log(data)
    onJobStatusListingClick(data?.id);
  };

  const onJobStatusListingClick = async (job_id) => {
    dispatch(
      changeJobStatusAsync({
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          toast.success(res.message.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          if(getAllJobs) {
            getAllJobs()
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editJobClickHanlder = (id) => {
    dispatch(setActiveTab("employer"));
    dispatch(setPostJobMode("edit"));
    dispatch(setPostJobEditForm("listing"));
    dispatch(setPostJobId(id));
    navigate("/post-job?edit=true");
  };


  return (
    <>
      <div  className={`adJob-card  ${props.is_active === 1 ? 'btColorActive' : 'btColorInactive'}`}>
        <div className="adJob-card-header">
          <div>
            {props?.full_title ? <h3 className='cursor-pointer flex-start-center gap-1' onClick={JobDetailHandle}>
              {/* <span className={`user-status ${props.is_active === 1 ? 'active' : 'inactive'}`}></span> */}
              {props?.full_title}
            </h3> : ''}
            <p className="with-icon">
              <img src="/image/candList.png" alt="candList" /> <strong>{props?.applicant_count}</strong>
            </p>
          </div>
          <div className="ellipses-dd">
            <button className="btn btn-transparent p-0" onClick={() => setShowMenu(!showMenu)}>
              <img src="/image/adJob-dd.png" alt="adJob-dd" />
            </button>
            <div className={showMenu ? 'cv-cions active' : 'cv-cions'}>
              <div className="dd-val" onClick={() => editJobClickHanlder(props.id)}>
                <img src="/image/edit-black.png" alt="" className="show-tooltip" />
                <p className="dd-content">Edit</p>
              </div>
              <div className="dd-val">
                
              <div className="loction-switch-col p-0">
                {/* {console.log(props)} */}
                  <label className="switch" for={`switch-${props.id}`}>
                    <input
                      type="checkbox"
                      data-type={props.is_active === 0 ? false : true}
                      // checked={isActive}
                      defaultChecked={props.is_active}
                      name='isActive'
                      onChange={() => onSwitchChange(props)}
                      id={`switch-${props.id}`}
                    />
                    <span className="visibility-hidden opacity-0 position-absolute">switch</span>
                    <span className="slider ml-auto"></span>
                  </label>
                </div>
                <p className="dd-content">Status</p>
              </div>
            </div>
          </div>
        </div>
        <div className="adJob-card-body ">
          <p className="with-icon cursor-pointer" data-id={props.id} onClick={handleCandidateJourney} >
            <img src="/image/shortList.png" alt="candList" /> Hiring process 
            {
              props.interview_step_count > 0 ? '' :  
              <img src="/image/infoIcon.png" alt="canList"/>
            }
          </p>
          <p className="with-icon cursor-pointer" onClick={handlePreScreeing}>
            <img src="/image/screening.png" alt="candList"  /> Pre-screening questions
            {
              props.pre_screening_questions_count > 0 ? '' :  
              <img src="/image/infoIcon.png" alt='info'/>
            }
          </p>
        </div>
        <div className="adJob-card-footer">
          <p>Posted date: {props?.date_posted}</p>
        </div>
      </div>
    </>
  )
}

export default CandidateCard