import React, { useState, Fragment, useEffect, useRef } from 'react'
import '../CanDetailMessages/CanDetailMessages.css'
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
import OutsideClickHandler from "react-outside-click-handler";
import API from "../../../../api";
import APIPvtDB from "../../../../apiPvtD";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import './index.css'

import NotesSec from './NotesSec'
import setDateTime from '../../../../utils/setDateTime';
import InvitePeopleModal from '../../../Common/InvitePeopleModal/InvitePeopleModal';
import AddNewNotesModal from './AddNewNotesModal';

const CandidatesNotesSec = ({ candidateData, jobDetailData, candidateDetailData }) => {
  const { user } = useSelector((state) => state.auth);
  const prevCountRef = useRef();
  const [toggleShareListModel, settoggleShareListModel] = useState(false);
  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [selectCompanyUser, setSelectCompanyUser] = useState([]);
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);
  const [userName, setUserName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [commentMsg, setCommentMsg] = useState([]);
  const [commentsDetail, setCommentsDetail] = useState(null);
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [newNotes, setNewNotes] = useState(false);
  const [editNote, setEditNote] = useState('');

  const getCommentsDetail = async () => {
    setLoading(true)
    try {

      await APIPvtDB.get(`/get-notes?job_id=${jobDetailData.id}&candidate_id=${candidateDetailData?.account_id}`)
        .then((response) => {
          setCommentsDetail(response.data?.data)
          setCommentMsg(response.data?.data.comments)
          setCompanyInfo(response.data?.data.company)
          setSelectCompanyUser(response.data?.data.members)
          prevCountRef.current = response.data?.data.members.length;
          setLoading(false)
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true)
    getCommentsDetail()
  }, [])

  const toggleEmojiPickerHandler = () => {
    setToggleEmojiPicker((current) => !current);
    scrollToBottom();
  };
  const scrollToBottom = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [toggleEmojiPicker]);

  const addEmojiToInput = (e) => {
    setInputValue((current) => current + e.native);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const enterMsg = async (data) => {
    try {
      const formData = {
        "object_type": 'job',
        "candidate_id": candidateDetailData?.account_id,
        "object_id": jobDetailData.id,
        "comments": data
      }
      await APIPvtDB.post(`/add-notes`, formData)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            setNewNotes(!newNotes)
            getCommentsDetail()
            // setCommentMsg(response.data.data)
          }
          else {
            toast.error(response?.data.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
            setLoading(false)
            setNewNotes(false)
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }


  const isCompanyUserFieldValid = () =>
    !!(
      formik.touched["campanyUser"] &&
      formik.errors["campanyUser"] &&
      selectCompanyUser?.length < 1
    );

  const getCompanyUserErrorMessage = () => {
    return (
      isCompanyUserFieldValid() && (
        <p className="error-msg">{formik.errors["campanyUser"]}</p>
      )
    );
  };

  const onCompanyUserCancel = (curentCompanyUser) => {
    setSelectCompanyUser(prevData => prevData.filter(item => item.id !== curentCompanyUser.id))
  };

  const onCompanyUserClick = (curentCompanyUser) => {
    const idExists = selectCompanyUser.some(item => item.id === curentCompanyUser.id);
    if (!idExists) {
      setSelectCompanyUser([...selectCompanyUser, curentCompanyUser]);
    }
  };

  const formik = useFormik({
    initialValues: {
      campanyUser: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      campanyUser: Yup.mixed().test(
        "campanyUser",
        "Please select the people to add members list",
        (value) =>
          !!(Array.isArray(selectCompanyUser))
      ),
    }),
    onSubmit: async (data, { resetForm }) => {
      let selectCompanyUsersId = selectCompanyUser.map((item) => item.id);
      if (selectCompanyUsersId.length < 1) {
        selectCompanyUsersId = ['']
      }
      AddMemberToMsg(selectCompanyUsersId);
    },
  });

  const AddMemberToMsg = async (users) => {
    setLoading(true)
    try {
      // const formData = new FormData();
      const formData = {
        "job_id": jobDetailData.id,
        "user_ids": users
      }
      await APIPvtDB.post(`/add-members-to-job-notes`, formData)
        .then((response) => {
          if (response.data.success) {
            addRemoveCand()
            getCommentsDetail()
            if (selectCompanyUser.length < prevCountRef.current) {
              toast.success('Members removed successfully', {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            if (selectCompanyUser.length > prevCountRef.current) {
              toast.success(response?.data.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          } else {
            addRemoveCand()
            getCommentsDetail()
            toast.error(response?.data.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      try {
        if (userName) {
          const formData = new FormData();
          user !== null && formData.append("my_id", user?.id);
          user !== null && formData.append("company_id", user?.company_id);
          // user !== null && formData.append("my_id", 5);
          // user !== null && formData.append("company_id", 1);
          formData.append("search_word", userName);

          API.post(`/job-board/auto-search-company-user`, formData)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                let res = response?.data;

                setAllCompanyUser(res?.company_users);
           
                if (res?.company_users?.length > 0) {               
                  setCompanyUserHide(true);
                }
                else {
                  setCompanyUserHide(false);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)

    return () => clearTimeout(getData)
  }, [userName])

  const addRemoveCand = () => {
    settoggleShareListModel(!toggleShareListModel);
  };
  const addNotes = () => {
    setEditNote('')
    setNewNotes(!newNotes);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      enterMsg()
    }
  };
  const editNoteSec = (data) => {
    if (data) {
      setEditNote(data)
      setNewNotes(!newNotes);
    }
  };

  return (
    <div className="tab-content ex1-content" id="ex1-content">
      <div
        className="tab-pane fade show active"
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
      >
        <div className="tabs-content-jour">
          <div className='d-flex align-items-center justify-content-between'>
            <p className="content-heading-jour pb-0 border-0 d-block">Notes</p>
            <div className={`gap-3 ${commentMsg.length > 0 ? 'd-flex mx-4': 'd-flex mx-4'}`}>
              <button className='btn btn-outline-black d-flex gap-2 align-items-center' onClick={addRemoveCand}>
                <img src='/image/mem-vec.png' alt="icon"/>
                <span className='d-md-inline d-none'> Add team members</span>
                <span className='d-md-none d-inline'>+</span>
              </button>
              <button className='btn btn-outline-black d-flex gap-2 align-items-center' onClick={addNotes}>
                <img src='/image/mem-vecc.png' alt="icon"/>
                <span className='d-md-inline d-none'> Create new notes</span> +</button>
            </div>
          </div>
          <div className='comments-sec'>
            {
              commentMsg.length > 0 ? <div className='notes-box notes-scr'>
              <div className="header-bar-comment d-none">
                <div className="clinet-profile size-w-d">
                  <img src={commentsDetail?.user?.avatar_link ? commentsDetail?.user?.avatar_link : '/image/client-img.png'} alt="" />
                  <div className="clint-info">
                    <p className="name-clinet">{commentsDetail?.user?.first_name} {commentsDetail?.user?.last_name}</p>
                    <p className="clinet-rank">{commentsDetail?.user?.role?.title}</p>
                  </div>
                </div>
              </div>
              {
                loading ?
                  <div className='chat-model-comment-box bg-white'>
                    <div className="loader-div">
                      <span
                        className="spinner-border spinner-border"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  </div> : <div className="chat-model-comment-box bg-white">
                    <div className='chat-extras-info'>
                      <div className='cand-inque'>
                        <h3>{jobDetailData.name} <small>({`${commentsDetail?.members.length} ${commentsDetail?.members.length > 1 ? "members":"member"}`})</small> </h3>
                        <ul>
                          {
                            commentsDetail?.members.map((item, index) => {
                              return (
                                <li key={index}>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      // delay={{ show: 250, hide: 4000000 }}
                                      <Tooltip id='tooltip-top'>
                                        {item.first_name} {item.last_name}
                                      </Tooltip>
                                    }
                                  >
                                    <img src={item?.avatar_link ? item?.avatar_link : '/image/client-img.png'} />
                                  </OverlayTrigger>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="position-relative chat-summary" ref={modalRef}>
                      <div className="all-chat-comment-m notesbox">
                        {commentMsg.map((item, index) => {
                          return (
                            <Fragment>
                              <div className='chat-slot'>
                                <NotesSec
                                  commentDet={item}
                                  companyInfo={companyInfo}
                                  loggedInID={user.id}
                                  getCommentsDetail={getCommentsDetail}
                                  editNoteSec={editNoteSec}
                                />
                              </div>
                            </Fragment>
                          )
                        })}
                      </div>
                    </div>
                  </div>
              }
            </div> : <div className='no-notes'>
              <p>You currently have no new notes.</p>
              <img src='/image/no-notes.png' alt='no-notes' onClick={addNotes} className='cursor-pointer' />
            </div>
            }
          </div>
        </div>
      </div>

     
      {
        toggleShareListModel && (
          <InvitePeopleModal
          toggleShareListModel={toggleShareListModel}
          closeModal={addRemoveCand}
          formik={formik}
          allCompanyUser={allCompanyUser}
          allCompanyUserHide={allCompanyUserHide}
          onCompanyUserClick={onCompanyUserClick}
          selectCompanyUser={selectCompanyUser}
          getCompanyUserErrorMessage={getCompanyUserErrorMessage}
          onCompanyUserCancel={onCompanyUserCancel}
          userName={userName}
          setUserName={setUserName}
          message= {"Invite people to access the note"}
        />
        )
      }
      {
        newNotes && (
          <AddNewNotesModal 
            closeModal={addNotes}
            enterMsg={enterMsg}
            editNote={editNote}
            getCommentsDetail={getCommentsDetail}
          />
        )
      }
    </div>
  )
}

export default CandidatesNotesSec