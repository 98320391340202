import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setActiveSideBar,
  setDeleteAction,
  setFormMode,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import APIPvt from "../../../apiPvt";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import "../Dashboard/index.css";

const AdminRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { defaultPermissions } = useSelector((state) => state.adminDashboard);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [roleListingData, setRoleListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [dep, setDep] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [selectedDepDropDown, setSelectedDepDropDown] = useState({
    id: null,
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  /* Model State*/
  const [toggleDeleteModel, setToggleDeleteModel] = useState(false);

  /*Department Delete */

  const [selectedDeleteRoleId, setSelectedDeleteRoleId] = useState(null);

  const navHandler = () => {
    dispatch(setFormMode("add"));
    dispatch(
      setActiveSideBar({ id: "2", name: "Roles", value: "Create Role" })
    );
    dispatch(setSelectedDep({ id: null, name: "" }));

    dispatch(setSelectedRoleR({ id: null, name: "" }));
    navigate("/admin-system-user");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onDepClick = (id, name) => {
    setSelectedDepDropDown({ id: id, name: name });
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onDepClean = () => {
    setSelectedDepDropDown({
      id: null,
      name: "",
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  }

  const onViewUserClick = (id, name) => {
    dispatch(
      setSelectedRoleR({
        id: id,
        name: name,
      })
    );
    navigate("/admin-view-user");
  };

  const onCreateUserClick = (roleId, roleName, depId, depName) => {
    dispatch(setFormMode("add"));
    dispatch(
      setSelectedDep({
        id: depId,
        name: depName,
      })
    );
    dispatch(
      setSelectedRoleR({
        id: roleId,
        name: roleName,
      })
    );
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Users",
        value: "Create User",
      })
    );
    navigate("/admin-system-user");
  };

  const onEditRoleClick = (id, name) => {
    dispatch(setFormMode("edit"));
    dispatch(
      setSelectedRoleR({
        id: id,
        name: name,
      })
    );
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Roles",
        value: "Create Role",
      })
    );
    navigate("/admin-system-user");
  };

  /*Event Fot Delete Dep*/

  const deleteModelHandler = (toggleModel, id) => {
    setSelectedDeleteRoleId(id);
    setToggleDeleteModel(toggleModel);
  };

  const onDeletRoleClick = async () => {

    
    try {
      setLoader(true);
      await APIPvt.delete(`/role/${selectedDeleteRoleId}`)
        .then((response) => {
          let res = response?.data;
          if (response?.status === 200 && res?.success === true) {
            getRoleListingData();
            dispatch(setDeleteAction({ open: false, msg: "" }));          
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else if (response?.status === 200 && res?.success === false) {
            dispatch(setDeleteAction({ open: true, msg: res?.message }));
          }
          setLoader(false);
          deleteModelHandler(false, null);
        })
        .catch((error) => {
          if (error) {
            setLoader(false);
            deleteModelHandler(false, null);
            console.log(error);
          }
        });
    } catch (error) {
      setLoader(false);
      deleteModelHandler(false, null);
      console.log(error);
    }
  };

  /*****************************/

  const getRoleListingData = async () => {
    try {
      let params = `?`;
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (selectedDepDropDown?.id !== null) {
        params += `department_id=${selectedDepDropDown?.id}&`;
      }
      if (selectedStatus?.value !== null) {
        params += `status=${selectedStatus?.value}&`;
      }
      if (currentPage !== null) {
        params += `page=${currentPage}&`;
      }
      if (perPage !== "") {
        params += `per_page=${perPage}&`;
      }
      setMsg("");
      await APIPvt.get(`/roles${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            setRoleListingData(res?.data);
            setDep(res?.departments);
            setStatus(res?.status);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              search === "" &&
              selectedDepDropDown?.id === null
            )
              setMsg("Please Create a Role to view data.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" || selectedDepDropDown?.id !== null)
            )
              setMsg("Please make another selection. No record found.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleListingData();
  }, [selectedDepDropDown, selectedStatus, search, currentPage, perPage]);

  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>Roles</h2>
          {defaultPermissions?.roles_create === 1 && (
            <button
              className="create-department"
              type="text"
              onClick={() => navHandler()}
            >
              Create roles +
            </button>
          )}
        </div>{" "}
        <div className="filter-search-bar">
          <div className="two-drop-role">
            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Department</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        selectedDepDropDown?.name !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {selectedDepDropDown?.name === ""
                        ? "Department"
                        : selectedDepDropDown?.name}
                    </p>
                    {selectedDepDropDown?.name === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onDepClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {dep?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {dep?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Status</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        selectedStatus?.label !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {selectedStatus?.label === ""
                        ? "Status"
                        : selectedStatus?.label}
                    </p>
                    {selectedStatus?.label === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onStatusClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {status?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {status?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-right-searcgadmin">
            <div className="search-admin">
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">Search</label>
              <img src="/image/job-search.png" alt="search" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
         </div>
         <ShowRows  perPage={perPage} onChangePerPageHandler={onChangePerPageHandler}/>     
        <div className="department-table-imge">
          <div className="col-left-table-depart">
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-deprt">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Role name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Status</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-admin">
                  {roleListingData?.length > 0 &&
                    roleListingData?.map((item, index) => (
                      <tr>
                        <td className="deprt-left-td">
                          <div className="name-sr-circle">
                            <span className="later-circle">
                              {item?.title?.charAt(0)}
                            </span>
                            {item?.title}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`active-tb-data ${
                              item?.status === 0 && "inactive-tb-data"
                            }`}
                          >
                            {" "}
                            {item?.status === 0 ? "Inactive" : "Active"}
                          </div>
                        </td>
                        <td>
                          <div className="four-role-img">
                            {defaultPermissions?.users_view === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onViewUserClick(item?.id, item?.title)
                                }
                              >
                                <img
                                  src="/image/role-user-d.png"
                                  alt=""
                                  className="show-tooltip icon-cursor"
                                />
                                <div className="tooltip-view-role">
                                  View users
                                </div>
                              </div>
                            )}
                            {defaultPermissions?.users_create === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onCreateUserClick(
                                    item?.id,
                                    item?.title,
                                    item?.department?.id,
                                    item?.department?.name
                                  )
                                }
                              >
                                <img
                                  src="/image/role-c-d.png"
                                  alt=""
                                  className="show-tooltip1 icon-cursor"
                                />
                                <div className="tooltip-crete-role">
                                  Create user
                                </div>
                              </div>
                            )}

                            {defaultPermissions?.roles_edit === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onEditRoleClick(item?.id, item?.name)
                                }
                              >
                                <img
                                  src="/image/role-edit.png"
                                  alt=""
                                  className="show-tooltip2 icon-cursor"
                                />
                                <div className="tooltip-edit-role">Edit</div>
                              </div>
                            )}
                            {defaultPermissions?.roles_delete === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  deleteModelHandler(true, item?.id)
                                }
                              >
                                <img
                                  src="/image/role-delet.png"
                                  alt=""
                                  className="show-tooltip3 icon-cursor"
                                />
                                <div className="tooltip-delete-role">
                                  Delete
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {roleListingData?.length > 0 && (
              <TablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
            <TableError msg={msg} />
          </div>
          <div className="col-right-image-depart">
            <img src="/image/right-depart.png" alt="" />
          </div>
        </div>
      </div>

      {toggleDeleteModel && (
        <ActionModal
          closeModal={deleteModelHandler}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to delete this role?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteModelHandler(false, null)}
          >
            No
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => onDeletRoleClick()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes"
            )}
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default AdminRole;
