import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getEmployerDropdownAsync,
  resendVerifyEmailAsync,
} from "../../../../redux/slices/AuthSlice";
import API from "../../../../api";
import { emailRegex, nameRegex, PublicDomains } from "../../../../constants";


const EmployerForm = ({ employerSignUpHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [companySizeOption, setCompanySizeOption] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",      
      companyName:"",
      industry: "",
      industryId: null,
      companySize: "",
      companySizeId: null,
      password: "",
      confirmPassword: "",      
      termServices: false,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(nameRegex, "First name should be an alphabet")
        .max(30, "First name cannot exceed 30 characters")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(nameRegex, "Last name should be an alphabet")
        .max(30, "Last name cannot exceed 30 characters")
        .required("Please enter last name"),
      email: Yup.string()
        .matches(
          emailRegex,
          "Please enter valid email address"
        )
        .required("Please enter email")
        .test(
          "is-not-public-email",
          "Public email account are not allowed",
          (value) => {
            const domain = value.split("@")[1];
            console.log("domain");
            return !PublicDomains.includes(domain);
          }
        ),
      companyName: Yup.string().required("Please enter company name"),
      industry: Yup.string().required("Please select industry"),
      companySize: Yup.string().required("Please select company size"),      
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 upper case letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 lower case letter (abcd)");
        }
        if (!/[!#%$*]/.test(value)) {
          errors.push("Use at least one special character (!# % $ *)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please re-enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),     
      termServices: Yup.boolean()
        .oneOf([true], "Please select the terms and conditions")
        .required("Please select the terms and conditions"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { email } = data;
      if (passwordErrors?.length > 0) return;

      try {
        setLoader(true);
        await API.post("/auth/check-email-unique", {
          email: email,
        })
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data;
              if (response?.data?.result === true) {
                await employerSignUpHandler(data);
              } else {
                let errorMsg = res?.message?.error;
                if(errorMsg){
                  setErrors({ email: errorMsg });
                }        
              }           
              setLoader(false);
            }
          })
          .catch((error) => {    
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const industryHandler = async (item) => {
    const { id, name } = item;
    await formik.setFieldValue("industry", name);
    await formik.setFieldValue("industryId", id);
  };

  const companySizeHandler = async (item) => {
    const { id, name } = item;
    await formik.setFieldValue("companySize", name);
    await formik.setFieldValue("companySizeId", id);
  };

  const resendVerifyEmailHandler = async () => {
    if (formik?.values?.email === "" || isFormFieldValid("email")) {
      formik.touched["email"] = true;
      isFormFieldValid("email");
      formik?.setFieldError("email", formik?.errors?.email);
      return;
    } else {
      dispatch(
        resendVerifyEmailAsync({
          email: formik?.values?.email,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    }
  };

  const getEmployerDropdownData = async (id) => {
    dispatch(
      getEmployerDropdownAsync()
    )
      .then((response) => {
        const res = response?.payload;
        if(res?.success === true){
          setIndustryOption(res?.data?.industries);
          setCompanySizeOption(res?.data?.company_sizes);
        }
 
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getEmployerDropdownData();
  }, []);

  return (
    <>
      <div className="candidate-signup-form-feild ">
        <form onSubmit={formik.handleSubmit}>
          <div className="candidate-username-signup-form">
            <div
              className={`first-name f-feild ${
                isFormFieldValid("firstName") && "error-input"
              }`}
            >
              <label for="fname1">First name*</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                id='fname1'
                placeholder="First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("firstName")}
            </div>

            <div
              className={`last-name f-feild ${
                isFormFieldValid("lastName") && "error-input"
              }`}
            >
              <label for="lname1">Last name*</label>
              <br />
              <input
                className="form-font-f"
                type="text"
                id='lname1'
                placeholder="Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {getFormErrorMessage("lastName")}
            </div>
          </div>

          <div
            className={`signup-email f-feild ${
              isFormFieldValid("email") && "error-input"
            }`}
          >
            <label for="femail1">E-mail*</label>
            <br />
            <input
              className="form-font-f"
              type="email"
              name="email"
              id="femail1"
              placeholder="Enter valid email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("email")}
          </div>
          <div
            className={`signup-company-name f-feild ${isFormFieldValid("companyName") && "error-input"
              }`}
          >
            <label for="flink">Company name*</label>
            <input
              className="form-font-f"
              type="text"
              id="flink"
              placeholder="Company name"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {getFormErrorMessage("companyName")}
          </div>
          <div
            className={`signup-country f-feild ${
              isFormFieldValid("industry") && "error-input"
            }`}
          >
            <label for="fcountry">Industry*</label>
            <br />
            <div
              className={`industry-feild-frm-country bg-pass-f ${
                isFormFieldValid("industry") && "error-input"
              }`}
            >
              <div
                name="industry"
                id="dropdown-boxId-2"
                className="company-form-dropdownBox-f"
                data-toggle="dropdown"
                aria-haspopup="true"
              >
                <span
                  className={`${
                    formik?.values?.industry === "" && "dropdown-opty"
                  }`}
                >
                  {formik?.values?.industry === ""
                    ? "Industry"
                    : formik?.values?.industry}
                </span>
                <div className="icon-hide">
                  {isFormFieldValid("industry") ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <img src="/image/form-d-f.png" alt="" />
                  )}
                </div>
              </div>
              {industryOption?.length > 0 && (
                <div className="list-search-dropdown-company">
                  <ul className="list-job-title-country">
                    {industryOption?.map((j, i) => (
                      <li
                        className="icon-cursor-f"
                        onClick={() => industryHandler(j)}
                      >
                        {j?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {getFormErrorMessage("industry")}
          </div>
          <div
            className={`signup-country f-feild ${
              isFormFieldValid("companySize") && "error-input"
            }`}
          >
            <label for="fcountry">Company size*</label>
            <br />
            <div
              className={`industry-feild-frm-country bg-pass-f ${
                isFormFieldValid("companySize") && "error-input"
              }`}
            >
              <div
                name="companySize"
                id="dropdown-boxId-2"
                className="company-form-dropdownBox-f"
                data-toggle="dropdown"
                aria-haspopup="true"
              >
                <span
                  className={`${
                    formik?.values?.companySize === "" && "dropdown-opty"
                  }`}
                >
                  {formik?.values?.companySize === ""
                    ? "Company size"
                    : formik?.values?.companySize}
                </span>
                <div className="icon-hide">
                  {isFormFieldValid("companySize") ? (
                    <img src="/image/warning.png" alt="" />
                  ) : (
                    <img src="/image/form-d-f.png" alt="" />
                  )}
                </div>
              </div>
              {companySizeOption?.length > 0 && (
                <div className="list-search-dropdown-company">
                  <ul className="list-job-title-country">
                    {companySizeOption?.map((j, i) => (
                      <li
                        className="icon-cursor-f"
                        onClick={() => companySizeHandler(j)}
                      >
                        {j?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {getFormErrorMessage("companySize")}
          </div>
          <div
            className={`signup-passowrd f-feild ${
              isPasswordFieldValid(passwordErrors) && "error-input"
            }`}
          >
            <label for="password-field">Password*</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={passwordShown ? "text" : "password"}
                name="password"
                placeholder="Enter valid password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">             
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!passwordShown && "password-icon-togle"}
                      onClick={() => setPasswordShown(false)}
                    />
                  </>              
              </div>
            </div>
            {getPasswordErrorMessage(passwordErrors)}
          </div>
          <div
            className={`signup-passowrd f-feild ${
              isFormFieldValid("confirmPassword") && "error-input"
            }`}
          >
            <label for="password-field">Confirm password*</label>
            <br />
            <div class="password-icon bg-pass-f">
              <input
                className="form-font-f"
                id="password-field"
                type={confirmPasswordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder="Re-type password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="icon-hide">              
                  <>
                    <img
                      src="/image/hide-password.png"
                      alt=""
                      className={confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(true)}
                    />
                    <img
                      src="/image/password.png"
                      alt=""
                      className={!confirmPasswordShown && "password-icon-togle"}
                      onClick={() => setConfirmPasswordShown(false)}
                    />
                  </>          
              </div>
            </div>
            {getFormErrorMessage("confirmPassword")}
          </div>
          <div className="signup-check f-feild">
            {isFormFieldValid("termServices") && (
              <div className="attention-box">
                <div className="attendtion-heading">
                  <div className="icon-atten">
                    <img src="/image/attention.png" alt="" />
                  </div>
                  <div className="attention-text">
                    <p className="text-atten-box">
                      {formik?.errors?.termServices}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <label for='termServices1' className="visibility-hidden opacity-0 position-absolute">termServices</label>
            <input
              type="checkbox"
              name="termServices"
              id="termServices1"
              checked={formik?.values?.termServices}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />{" "}
            &nbsp;Yes, I understand and agree to the terms of service
          </div>
          <div className="candidate-from-signup-create">
            <button type="submit" className="form-submit">
              {loader ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              ) : (
                "Get started"
              )}
            </button>
            <div className="signup-resendmail">
              <p>
                Haven't received the confirmation email yet?{" "}
                <Link href="#" onClick={() => resendVerifyEmailHandler()}>
                  <span>Resend Email</span>
                </Link>
              </p>
            </div>
            <br />
          </div>
        </form>
      </div>
    </>
  );
};

export default EmployerForm;
