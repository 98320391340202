import React, { useEffect, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import CompanyCulture from './CompanyCulture';

const ProfileSection = ({ sectionType, editMethod, description, heading, tooltip, defaultText, companyCulture }) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(description);
  const [selectedRadio, setSelectedRadio] = useState({
    work_environment_id: null,
    work_environment_text: '',
    collaboration_level_id: null,
    collaboration_level_text: '',
    work_pace_id: null,
    work_pace_text: "",
    communication_style_id: null,
    communication_style_text: "",
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
  const updateObjectValue = (e, title) => {
    const { id, value } = e.target;  // Extract id and value from the event
    const [category, numericId] = id.split("-");  // Split id into category and numeric id
    
    const newState = {};

    // Update based on the ID prefix
    if (category === "paceofwork") {
      newState.work_pace_id = parseInt(numericId);
      newState.work_pace_text = value;
    } else if (category === "communicationstyle") {
      newState.communication_style_id = parseInt(numericId);
      newState.communication_style_text = value;
    } else if (category === "levelofcollaboration") {
      newState.collaboration_level_id = parseInt(numericId);
      newState.collaboration_level_text = value;
    } else if (category === "workenvironment") {
      newState.work_environment_id = parseInt(numericId);
      newState.work_environment_text = value;
    } 

    // Update the state with the new values
    setSelectedRadio((prevState) => ({
      ...prevState,
      ...newState,
    }));

  };

  const sectionEdit = (type, description) => {
    setEditable(!editable)
    if (type === "culture") {
      editMethod(type, selectedRadio)
    } else {
      editMethod(type, value)
    }
  }

  useEffect(() => {
    if (description)
      setValue(description);
  }, [description])

  return (
    <div className='profile-edit-body'>
      {(editMethod && !editable) && (
        <button className="btn btn-transparent edit-btn-profile" onClick={() => setEditable(!editable)}>
          <span className="visibility-hidden opacity-0 position-absolute">Edit</span>
          <img src="/image/pen.png" alt="" />
        </button>
      )}
      <h3>{heading}
        {sectionType !== 'culture' ?  <>
          {tooltip && <OverlayTrigger
          placement='top'
          overlay={
            // delay={{ show: 250, hide: 4000000 }}
            <Tooltip id='tooltip-top' className="large-tooltip">
              {tooltip}
            </Tooltip>
          }
        >
          <img src='/image/info.png' alt='info' className='ms-1' />
        </OverlayTrigger>}

        </> : ''}
        
      </h3>
      {
        editable ? (
          <>
            {sectionType !== 'culture' ? <>
              <Form.Group>
                <Form.Control as="textarea" rows={3} value={value} onChange={handleChange} maxLength={500} />
                <p className='text-end'>{value ? value.length : '0'}/500</p>
              </Form.Group>
            <hr />
            </> : ''}
            {
              sectionType === 'culture' && companyCulture ? <div className='row mt-4'>
                {companyCulture?.workEnvironments ?  <div className='col-md-6'>
                  <CompanyCulture
                    imag='image/WEnv.png'
                    editable={editable}
                    points={companyCulture?.workEnvironments}
                    title='Work environment'
                    updateValue={updateObjectValue}
                  />
                  
                </div> : ''}
                {companyCulture?.collaborationLevels ?  <div className='col-md-6'>
                  <CompanyCulture
                    imag='image/LOColl.png'
                    editable={editable}
                    points={companyCulture?.collaborationLevels}
                    title='Level of collaboration'
                    updateValue={updateObjectValue}
                  />
                  
                </div> : ''}
                {companyCulture?.communicationStyles ?  <div className='col-md-6'>
                  <CompanyCulture
                    imag='image/CommSty.png'
                    editable={editable}
                    points={companyCulture?.communicationStyles}
                    title='Communication style'
                    updateValue={updateObjectValue}
                  />
                  
                </div> : ''}
                {companyCulture?.workPaces ?  <div className='col-md-6'>
                  <CompanyCulture
                    imag='image/WPac.png'
                    editable={editable}
                    points={companyCulture?.workPaces}
                    title='Pace of work'
                    updateValue={updateObjectValue}
                  />
                  
                </div> : ''}
                <hr />
              </div>   :''

            }
            <div className='edit-footer'>
              <button className="btn btn-outline-black btn-ai d-none">
                ✨ Rewrite with AI</button>
              <div className='action-edit'>
                <button className="btn btn-black" onClick={() => setEditable(!editable)}>Cancel</button>
                <button className="btn btn-outline-black" onClick={() => sectionEdit(sectionType)}>Save</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className={!value ? 'opacity-70' : ''}>{value ? value : defaultText}</p>
            {
              sectionType === 'culture' && companyCulture ? <div className='row mt-4'>
                {companyCulture?.workEnvironments ?  <div className='col-md-6'>
                  <CompanyCulture
                    imag='image/WEnv.png'
                    points={companyCulture?.workEnvironments}
                    title='Work environment'
                  />
                  
                </div> : ''}
                {companyCulture?.collaborationLevels ?  <div className='col-md-6'>
                  <CompanyCulture
                  imag='image/LOColl.png'
                    points={companyCulture?.collaborationLevels}
                    title='Level of collaboration'
                  />
                  
                </div> : ''}
                {companyCulture?.communicationStyles ?  <div className='col-md-6'>
                  <CompanyCulture
                  imag='image/CommSty.png'
                    points={companyCulture?.communicationStyles}
                    title='Communication style'
                  />
                  
                </div> : ''}
                {companyCulture?.workPaces ?  <div className='col-md-6'>
                  <CompanyCulture
                  imag='image/WPac.png'
                    points={companyCulture?.workPaces}
                    title='Pace of work'
                  />
                  
                </div> : ''}
                
              </div>  :''
            }
          </>
        )
      }
    </div >
  )
}

export default ProfileSection