import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDataAsync } from "../../../redux/slices/homeSlice";
import { COUNTRY_ISO_CODE } from "../../../constants";
import "./Footer.css";
import ContactUsModal from "../../Common/COntactusModal/ContactusModal";
import ActionModal from "../../Common/ActionModal/ActionModal";

const Footer = () => {
  const { user } =useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myRef = useRef()
  const { countryISOCode } = useSelector((state) => state.home);
  const [openMenu, setOpenMenu] = useState('');
  const [openServices, setOpenServices] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [countries, setCountries] = useState([]);
  const [contactModal, setContactModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const showSubmitModal = () => {
    setContactModal(false)
    setSubmitSuccess(true)
  }

  const getFooterData = async () => {
    dispatch(getFooterDataAsync())
      .then(async (response) => {
        const res = response?.payload;
        const { countries } = res?.data;

        if (Array?.isArray(countries) && countries?.length > 0) {
          setCountries(countries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFooterData();
  }, []);

  const openmobileMenu = (menu) => {
    if(document.body.offsetWidth < 767) {
      if(openMenu === menu) {
        setOpenMenu('')  
      } else {
        setOpenMenu(menu)
      }
    }
    
  }

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    setDimensions(getDimensions())
    if(getDimensions().width < 767) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return (
    <section className="footer-sec" ref={myRef}>
      <div className="container container-site">
        <div className="space-inner-row">
          <div className="footer-row ">
            <div className="footer-countries footer-list">
              <ul>
                <li><p className={`${openMenu === 'countries' ? 'active' : ''} Countries-heading`} onClick={()=>openmobileMenu('countries')}>Countries 
                <img class="dropdown-arrow d-md-none d-inline ms-2" src="image/footer-arrow.png" alt="footer-arrow" />
                  </p></li>
                {!isMobile ? <>
                  {countries?.length > 0 &&
                  countries?.map((prod, index) => (
                    <li key={index}>{prod?.name}</li>
                  ))}
                </> : <>
                  {openMenu === 'countries' ? <>
                    {countries?.length > 0 &&
                  countries?.map((prod, index) => (
                    <li key={index}>{prod?.name}</li>
                  ))}
                  </> : ''}
                </>}
                
              </ul>
            </div>
            <div className="footer-countries footer-list">
              <ul>
                <li><p className={`${openMenu === 'frequently' ? 'active' : ''} Countries-heading`} onClick={()=>openmobileMenu('frequently')}>Frequently asked questions
                <img class="dropdown-arrow d-md-none d-inline ms-2" src="image/footer-arrow.png" alt="footer-arrow" /></p></li>

                {!isMobile ? <>
                  <li className="icon-cursor">
                    <a href="/faq-questions?topic=general%20faqs" target="_blank">
                      General FAQ
                    </a>
                  </li>
                  <li className="icon-cursor">
                  <a href="/faq-questions?topic=employer%20faq" target="_blank">
                      Employer FAQ
                  </a>
                  </li>
                  <li className="icon-cursor">
                    <a href="/faq-questions?topic=candidates%20faq" target="_blank">
                      Candidate FAQ
                    </a>
                  </li>
                </> : <>
                  {openMenu === 'frequently' ? <>
                    <li className="icon-cursor">
                  <a href="/faq-questions?topic=general%20faqs" target="_blank">
                    General FAQ
                  </a>
                </li>
                <li className="icon-cursor">
                <a href="/faq-questions?topic=employer%20faq" target="_blank">
                    Employer FAQ
                 </a>
                </li>
                <li className="icon-cursor">
                  <a href="/faq-questions?topic=candidates%20faq" target="_blank">
                    Candidate FAQ
                  </a>
                </li>
                  </> : ''}
                </>}
              </ul>
            </div>
            <div className="footer-countries footer-list ">
              <ul>
                <li>
                <p className={`${openMenu === 'countries' ? 'active' : ''} Countries-heading`}  onClick={()=>openmobileMenu('privacy')}>Policies and privacy center <img class="ms-2  dropdown-arrow d-md-none d-inline" src="image/footer-arrow.png" alt="footer-arrow" /></p>
                </li>

                {!isMobile ? <>
                  <li
                  className="icon-cursor"             
                >
                 <a href="/privacy-policy" target="_blank">
                 Privacy policy
                  </a> 
                </li>
                <li
                  className="icon-cursor"             
                >
                 <a href="/faq-questions?topic=terms%20of%20service" target="_blank">
                 Terms of service
                  </a> 
                </li>
                <li>
                <a href="/faq-questions?topic=cookie%20policy" target="_blank">
                Cookies policy
                </a> </li>
                {user ? <>
                  <li><a href="/faq-questions?topic=ai%20use%20statement" target="_blank">AI use statement</a></li>
                  <li><a href="/faq-questions?topic=disclaimer%20for%20polycareers" target="_blank">Disclaimer for PolyCareers</a></li>
                </> : ''}
                </> : <>
                {openMenu === 'privacy' ? <>
                  <li className="icon-cursor">
                 <a href="/privacy-policy" target="_blank">
                 Privacy policy
                  </a> 
                </li>
                <li
                  className="icon-cursor"             
                >
                 <a href="/faq-questions?topic=terms%20of%20service" target="_blank">
                 Terms of service
                  </a> 
                </li>
                <li>
                <a href="/faq-questions?topic=cookie%20policy" target="_blank">
                Cookies policy
                </a> </li>
                <li><a href="/faq-questions?topic=ai%20use%20statement" target="_blank">AI use statement</a></li>
                <li><a href="/faq-questions?topic=disclaimer%20for%20polycareers" target="_blank">Disclaimer for PolyCareers</a></li>
                  </> : ''}
                </>}

              </ul>
            </div>
            <div className="footer-address ">
              <img src="/image/footer-logo.png " alt="footer-logo " />
              <p className="Countries-heading my-3">Head Office</p>
              <p className="office-details">
                {countryISOCode === COUNTRY_ISO_CODE.CA ? (
                  // <>1920 Yonge Street, Toronto, ON M5E 1S7</>
                  <>1920 Yonge Street <br/>
                  Toronto, ON M4S 3E2 <br/>
                  Canada </>
                ) : (
                  <>202/1 Orce Nikolov, First Floor Skopje, Macedonia</>
                )}
              </p>
              <p className="office-details">
                <a
                  href={
                    countryISOCode === COUNTRY_ISO_CODE.CA
                      ? "mailto:info@polycareers.com"
                      : "mailto:info@polycareers.com.mk"
                  }
                >
                  {countryISOCode === COUNTRY_ISO_CODE.CA
                    ? "info@polycareers.com"
                    : "info@polycareers.com.mk"}
                </a>
              </p>
            </div>
          </div>         
        </div>
        
      </div>
      {contactModal && <ContactUsModal setContactModal={setContactModal} showSubmitModal={showSubmitModal} />}
      {submitSuccess &&
        <ActionModal
          heading="Thank you!"
          content="Your message has been received we will get back to you shortly"
          closeModal={() => setSubmitSuccess(!submitSuccess)}
          modalArt="/image/success-modal.png"
        ></ActionModal>
      }
    </section>
  );
};

export default Footer;
