import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setActiveSideBar,
  setDeleteAction,
  setFormMode,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import APIPvt from "../../../apiPvt";
import "./index.css";

const AdminDepartment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { defaultPermissions } = useSelector((state) => state.adminDashboard);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  /* Model State*/
  const [toggleDeleteModel, setToggleDeleteModel] = useState(false);

  /*Department Delete */

  const [selectedDeleteDepId, setSelectedDeleteDepId] = useState(null);

  const navHandler = () => {
    dispatch(setFormMode("add"));
    dispatch(setSelectedDep({ id: null, name: "" }));

    dispatch(setSelectedRoleR({ id: null, name: "" }));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Create Department",
      })
    );
    navigate("/admin-system-user");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
      setPerPage(value);
  }

  const onViewRoleClick = (id, name) => {
    dispatch(
      setSelectedDep({
        id: id,
        name: name,
      })
    );
    navigate("/admin-view-role");
  };

  const onCreateRoleClick = (id, name) => {
    dispatch(setFormMode("add"));
    dispatch(
      setSelectedDep({
        id: id,
        name: name,
      })
    );
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Roles",
        value: "Create Role",
      })
    );
    navigate("/admin-system-user");
  };

  const onEditDepClick = (id, name) => {
    dispatch(setFormMode("edit"));
    dispatch(
      setSelectedDep({
        id: id,
        name: name,
      })
    );
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Create Department",
      })
    );
    navigate("/admin-system-user");
  };

  /*Event Fot Delete Dep*/

  const deleteModelHandler = (toggleModel, id) => {
    setSelectedDeleteDepId(id);
    setToggleDeleteModel(toggleModel);
  };

  const onDeletDepClick = async () => {
    try {
      setLoader(true);
      await APIPvt.delete(`/department/${selectedDeleteDepId}`)
        .then((response) => {
          let res = response?.data;
          if (response?.status === 200 && res?.success === true) {
            getDepartmentListingData();
            dispatch(setDeleteAction({ open: false, msg: "" }));
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else if (response?.status === 200 && res?.success === false) {
            dispatch(setDeleteAction({ open: true, msg: res?.message }));
          }
          setLoader(false);
          deleteModelHandler(false, null);
        })
        .catch((error) => {
          if (error) {
            setLoader(false);
            deleteModelHandler(false, null);
            console.log(error);
          }
        });
    } catch (error) {
      setLoader(false);
      deleteModelHandler(false, null);
      console.log(error);
    }
  };

  /*****************************/

  const getDepartmentListingData = async () => {
    try {
      let params = `?`;
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (selectedStatus?.value !== null) {
        params += `status=${selectedStatus?.value}&`;
      }
      if (currentPage !== null) {
        params += `page=${currentPage}&`;
      }
      if (perPage !== "") {
        params += `per_page=${perPage}&`;
      }
      setMsg("");
      await APIPvt.get(`/departments${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            setListingData(res?.data);
            setStatus(res?.status);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              search === "" &&
              selectedStatus?.value === null
            )
              setMsg("Please Create a Department to view data.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" || selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartmentListingData();
  }, [selectedStatus, search, currentPage, perPage]);

  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>Department</h2>
          {defaultPermissions?.department_create === 1 && (
            <button
              className="create-department"
              type="text"
              onClick={() => navHandler()}
            >
              Create department +
            </button>
          )}
        </div>{" "}
        <div className="filter-search-bar">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-right-searcgadmin">
            <div className="search-admin">
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">Search</label>
              <img src="/image/job-search.png" alt="search" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
        </div>
        <ShowRows  perPage={perPage} onChangePerPageHandler={onChangePerPageHandler}/>
        <div className="department-table-imge">
          <div className="col-left-table-depart">
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-deprt">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Department name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Status</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-admin">
                  {listingData?.length > 0 &&
                    listingData?.map((item, index) => (
                      <tr>
                        <td className="deprt-left-td">
                          <div className="name-sr-circle">
                            <span className="later-circle">
                              {item?.name?.charAt(0)}
                            </span>
                            {item?.name}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`active-tb-data ${
                              item?.status === 0 && "inactive-tb-data"
                            }`}
                          >
                            {" "}
                            {item?.status === 0 ? "Inactive" : "Active"}
                          </div>
                        </td>
                        <td>
                          <div className="four-role-img">
                            {defaultPermissions?.roles_view === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onViewRoleClick(item?.id, item?.name)
                                }
                              >
                                <img
                                  src="/image/role-d.png"
                                  alt=""
                                  className="show-tooltip icon-cursor"
                                />
                                <div className="tooltip-view-role">
                                  View roles
                                </div>
                              </div>
                            )}
                            {defaultPermissions?.roles_create === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onCreateRoleClick(item?.id, item?.name)
                                }
                              >
                                <img
                                  src="/image/role-c-d.png"
                                  alt=""
                                  className="show-tooltip1 icon-cursor"
                                />
                                <div className="tooltip-crete-role">
                                  Create role
                                </div>
                              </div>
                            )}
                            {defaultPermissions?.department_edit === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  onEditDepClick(item?.id, item?.name)
                                }
                              >
                                <img
                                  src="/image/role-edit.png"
                                  alt=""
                                  className="show-tooltip2 icon-cursor"
                                />
                                <div className="tooltip-edit-role">Edit</div>
                              </div>
                            )}
                            {defaultPermissions?.department_delete === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  deleteModelHandler(true, item?.id)
                                }
                              >
                                <img
                                  src="/image/role-delet.png"
                                  alt=""
                                  className="show-tooltip3 icon-cursor"
                                />
                                <div className="tooltip-delete-role">
                                  Delete
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {listingData?.length > 0 && (
              <TablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
            <TableError msg={msg} />
          </div>
          <div className="col-right-image-depart">
            <img src="/image/right-depart.png" alt="" />
          </div>
        </div>
      </div>

      {toggleDeleteModel && (
        <ActionModal
          closeModal={deleteModelHandler}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to delete this department?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteModelHandler(false, null)}
          >
            No
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => onDeletDepClick()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes"
            )}
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default AdminDepartment;
