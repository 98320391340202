import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ApplicantsTable from "../../Common/ApplicantsTable/ApplicantsTable";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";

const NewApplicants = ({
  showNewApplicant,
  totalApplicants,
  applicants,
  onFvtClick,
  modelHandler,
  modelCHandler,
  overAllMatchDropDown,
  experienceDropDown,
  educationDropDown,
  impressionDropDown,
  statusDropDown,
  selectedOverAllMatch,
  selectedExperience,
  selectedEducation,
  selectedFirstImpression,
  selectedCandidateStatus,
  onSearchChange,
  onFirstImpressionChange,
  onFirstImpressionClean,
  onOverAllMatchChange,
  onOverAllMatchClean,
  onExperienceChange,
  onExperienceClean,
  onEducationChange,
  onEducationClean,
  onCandidateStatusChange,
  onCandidateStatusClean,
  onFirstImpressionClick,
  onCandidateStatusChangeHandler,
  onResumeClickHandler,
  handleAddNewCommentHandler,
  handleRemovedComment,

  jobTitles,
  onJobTitleClick,
  onJobTitleClean,
  selectedJobTitile,
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingInput, setSearchingInput] = useState("");

  useEffect(() => {

  }, [jobTitles]);

  const countHandler = () => {
    if (count < totalApplicants) setCount((prevCount) => prevCount + 10);
  };

  if (showNewApplicant === false && applicants?.length === 0) return null;



  return (
    <>
      <section className="job-heading" id="new_applicants">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="job-search-box-row">
              <div className="job-heading-home smilar-job-heading job-heading-local w-100">
                <h2>New applicants</h2>
                <div
                  className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                    }`}
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                >
                  <img
                    className="job-searchIcon job-searchIcon-mbl"
                    src="/image/job-searchIcon.png"
                    alt="job-searchBar"
                  />
                </div>
                <div
                  className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                    }`}
                >
                  <img
                    className="job-searchIcon"
                    src="/image/job-searchIcon.png"
                    alt="job-searchBar"
                    onClick={() => {
                      setOpenSearch(false);
                    }}
                  />
                  <label for='search-job-1' className="visibility-hidden opacity-0 position-absolute">search-job</label>
                  <input
                    placeholder="Search all candidates"
                    className="job-search-input"
                    id='search-job-1'
                    onChange={(e) => {
                      onSearchChange(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="new-job">
        <div className="container container-site">
          <div className="space-inner-row">
            <div className="divider"></div>
            <div className="j-fillter-only-table">
              <div className="job-fillter-company">
                <div className="j-company-fillter job-listing-mbl">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon">
                      <p className="text-truncate">
                        {" "}
                        {selectedJobTitile === ""
                          ? "Job title"
                          : selectedJobTitile?.length >= 18
                            ? selectedJobTitile?.substr(0, 18) + " ..."
                            : selectedJobTitile}
                      </p>
                      {selectedJobTitile === "" ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onJobTitleClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {jobTitles?.length > 0 && (
                    <div className="list-search-dropdown-company">
                      <ul className="list-job-title-company">
                        {jobTitles?.map((j, i) => (
                          <li
                            key={i}
                            className="icon-cursor job-title-size"
                            onClick={() => {
                              onJobTitleClick(j);
                            }}
                          >
                            {j}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedOverAllMatch?.label === null
                          ? "Overall match"
                          : selectedOverAllMatch?.label?.length >= 18
                            ? selectedOverAllMatch?.label?.substring(0, 18) +
                            " ..."
                            : selectedOverAllMatch?.label}
                      </p>
                      {selectedOverAllMatch?.label === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onOverAllMatchClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {overAllMatchDropDown?.length > 0 &&
                        overAllMatchDropDown?.map((item) => (
                          <li
                            key={item?.id}
                            onClick={() =>
                              onOverAllMatchChange(item?.label, item?.value)
                            }
                          >
                            {item?.label}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedExperience?.label === null
                          ? "Experience"
                          : selectedExperience?.label?.length >= 18
                            ? selectedExperience?.label?.substring(0, 18) + " ..."
                            : selectedExperience?.label}
                      </p>
                      {selectedExperience?.label === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onExperienceClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {experienceDropDown?.length > 0 &&
                        experienceDropDown?.map((item) => (
                          <li
                            key={item?.id}
                            onClick={() =>
                              onExperienceChange(item?.label, item?.value)
                            }
                          >
                            {item?.label}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedFirstImpression?.text === null
                          ? "First impression"
                          : selectedFirstImpression?.text?.length >= 18
                            ? selectedFirstImpression?.text?.substring(0, 18) +
                            " ..."
                            : selectedFirstImpression?.text}
                      </p>
                      {selectedFirstImpression?.text === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onFirstImpressionClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {impressionDropDown?.length > 0 &&
                        impressionDropDown?.map((impression) => (
                          <li
                            key={impression?.id}
                            onClick={() =>
                              onFirstImpressionChange(
                                impression?.id,
                                impression?.impression
                              )
                            }
                          >
                            {impression?.impression}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>



              <div className="job-fillter-company-table">
                <div className="j-company-fillter-table">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1-table"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-filter-icon-table">
                      <p>
                        {" "}
                        {selectedCandidateStatus?.text === null
                          ? "Candidate status"
                          : selectedCandidateStatus?.text?.length >= 12
                            ? selectedCandidateStatus?.text?.substring(0, 12) +
                            " ..."
                            : selectedCandidateStatus?.text}
                      </p>
                      {selectedCandidateStatus?.text === null ? (
                        <img src="/image/company--detail-filter.png" alt="" />
                      ) : (
                        <div
                          id="dropdown-boxId2"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/image/cross-b.png"
                            alt=""
                            onClick={() => onCandidateStatusClean()}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {statusDropDown?.length > 0 && statusDropDown?.map((status) => (
                        <li
                          key={status?.id}
                          onClick={() =>
                            onCandidateStatusChange(status?.id, status?.status)
                          }
                        >
                          {status?.status}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

            </div>
            {applicants?.length > 0 && (
              <>
                <div className="job-listing">
                  <div className="job-listing-header">
                    <div className="lr full-name">Full name</div>
                    <div className="lr j-title">Job applied to</div>
                    <div className="lr experience">
                      Experience
                      {/* <img src="/image/bars-arrow-up.png" alt="" className="applicant-arrow-icon"/> */}
                    </div>
                    <div className="lr cand-status">Candidate status</div>
                    <div className="lr match">
                      Overall match
                      {/* <img src="/image/bars-arrow-up.png" alt="" className="applicant-arrow-icon" /> */}
                    </div>
                    <div className="lr impression">Impression</div>
                    <div className="lr date-app">
                      Date applied
                      {/* <img src="/image/bars-arrow-up.png" alt="" className="applicant-arrow-icon"/> */}
                    </div>
                    <div className="lr action">Action</div>
                  </div>
                  <div className="job-listing-mb">
                    {applicants?.slice(0, count)?.map((item, index) => (
                      <div className="job-listing-body">
                        <ApplicantsTable
                          showCheckBox={false}
                          key={index}
                          index={index}
                          item={item}
                          candidateStatusesList={statusDropDown}
                          onFvtClick={onFvtClick}
                          modelHandler={modelHandler}
                          modelCHandler={modelCHandler}
                          onFirstImpressionClick={onFirstImpressionClick}
                          onCandidateStatusChangeHandler={
                            onCandidateStatusChangeHandler
                          }
                          onResumeClickHandler={onResumeClickHandler}
                          handleAddNewCommentHandler={
                            handleAddNewCommentHandler
                          }
                          handleRemovedComment={handleRemovedComment}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="divider"></div>
                </div>
              </>
            )}
            {applicants?.length === 0 && (
              <>
                <JobNoRecordFound />
                <div className="divider"></div>
              </>
            )}
          </div>
        </div>
        {applicants?.length > 10 && (
          <div class="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See More Applicants</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default NewApplicants;
