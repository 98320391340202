const commentDate = (data) => {
  const utcDate = new Date(data); // Input date in UTC format
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Detect user's timezone

  // Convert UTC date to local date in the user's timezone
  const localDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimezone })
  );

  const now = new Date();
  const localNow = new Date(
    now.toLocaleString('en-US', { timeZone: userTimezone })
  );

  const diffInMs = localNow - localDate;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  if (diffInHours < 24) {
    // If within the last 24 hours, display only the time in `hh:mm AM/PM` format
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)
    return `${hours}:${minutes} ${ampm}`;
  } else {
    // If older than 24 hours, display the date in `Mon DD` format (without the year)
    return localDate.toLocaleDateString('en-US', {
      timeZone: userTimezone,
      month: 'short',
      day: '2-digit',
    });
  }
};

export default commentDate;
