import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import ReactHtmlParser from "react-html-parser";
import {
  getNotifyDataAsync,
  markAllNotifyAsReadDataAsync,
  markNotifyAsReadDataAsync,
} from "../../../redux/slices/notificationSlice";
import { setNotifyCount } from "../../../redux/slices/AuthSlice";
import useAblyHook from "../../../utils/useAblyHook";
import commentDate from "../../../utils/commentDate";
import "./NotificationBar.css";

const NotificationBar = ({ notifyDropDown, setNotifyDropDown }) => {
  const { user, notifyCount } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(`notification.${user?.id}`);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [allNotifyData, setAllNotifyData] = useState([]);

  const onNofifyClik = () => {
    if (ADMIN.includes(user?.user_type)) {
      // navigate("/employer-notification");
    } else {
      navigate("/candidate-notification");
    }
    setNotifyDropDown(false);
  };

  const maskAsAllReadClick = () => {
    dispatch(markAllNotifyAsReadDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          const currentNotifyCount = notifyCount;
          if (currentNotifyCount > 0) {
            dispatch(setNotifyCount(0));
          }
          getnotifyData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setNotifyDropDown(false);
  };

  const maskAsReadClick = (prod) => {
    if (prod?.id && prod?.read_at === null) {
      dispatch(markNotifyAsReadDataAsync({ notification_ids: prod?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            const currentNotifyCount = notifyCount;
            if (currentNotifyCount > 0) {
              dispatch(setNotifyCount(currentNotifyCount - 1));
            }
            getnotifyData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setNotifyDropDown(false);
  };

  const getnotifyData = async () => {
    if (user && user?.id) {
      try {
        dispatch(
          getNotifyDataAsync({
            page: currentPage,
            per_page: perPage,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true && res?.data) {
              setAllNotifyData(res?.data);
              dispatch(setNotifyCount(res?.data[0]?.unread_notification_count));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (channel) {
      const handleChannelMessage = (message) => {
        if (message?.data?.data) {
          let newRecord = message?.data?.data;
          setAllNotifyData((prevAllNotifyData) => [newRecord, ...prevAllNotifyData]);
          dispatch(setNotifyCount(newRecord[0]?.unread_notification_count));
        }
      };
      channel.subscribe("new-notification", handleChannelMessage);

      return () => {
        channel.unsubscribe("new-notification", handleChannelMessage);
      };
    }
  }, [channel]);

  useEffect(() => {
    if(notifyDropDown){
      getnotifyData();
    }
  }, [notifyDropDown]);

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setNotifyDropDown(false);
        }}
      >
        <div className="candidate-wishlist-block icon-cursor">
          <a onClick={() => setNotifyDropDown(!notifyDropDown)}>
            <img src="/image/bell.png" alt="bell" />
            {notifyCount > 0 && (
              <div className="whislist-notification">
                <p className="conter-text-notification">{notifyCount}</p>
              </div>
            )}
          </a>
          
          {allNotifyData?.length > 0 ? (
            <div
              className={`notification-dropdown ${
                !notifyDropDown && "hide-show-notification"
              }`}
            >
              <div className="start-headind-notif">
                <div className="left-side-notif">Notifications</div>
                <div
                  className="right-side-notif"
                  onClick={() => maskAsAllReadClick()}
                >
                  Mark all as read{" "}
                  <img
                    src="/image/read-notif.png"
                    alt=""
                    className="img-tick"
                  />
                </div>
              </div>

              {allNotifyData?.slice(0, 5)?.map((prod, index) => (
                <div
                  className={`notifi-msg-row icon-cursor ${
                    prod?.read_at === null && "notify-active"
                  }`}
                  onClick={() => {
                    maskAsReadClick(prod);
                    onNofifyClik();
                  }}
                >
                  <div className="img-left-notif">
                    <img
                      src={
                        prod?.sender?.avatar
                          ? prod?.sender?.avatar
                          : "/image/client-img.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="right-side-notifi">
                    {ReactHtmlParser(prod?.data)}
                    <div className="time-interview-ques">
                      {commentDate(prod?.created_at)}
                    </div>
                  </div>
                </div>
              ))}

              {allNotifyData?.length > 5 && (
                <div
                  className="notifi-msg-row-buttom"
                  onClick={() => onNofifyClik()}
                >
                  <a>
                    <button class="see-more-btn w-100">See more</button>
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`notification-dropdown ${
                !notifyDropDown && "hide-show-notification"
              }`}
            >
              <div className="start-headind-notif">
                <div className="left-side-notif">Notifications</div>
              </div>

              <div className="px-2 mt-3">
                <div className="alert alert-light text-center">
                There are no notifications
                </div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default NotificationBar;
