import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FindJob from "../../../components/Common/FindJob/FindJob";
import MostSearchJob from "../../../components/Common/MostSearchJob/MostSearchJob";
import TopMatchContainer from "../../../components/Job/JobSearch/TopMatch/TopMatchContainer";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import LoginModel from "../../../components/Common/LoginModel/LoginModel";
import {
  resetClearedFlag,
  setMostSearchJob,
  setSearchWord,
} from "../../../redux/slices/JobSearchSlice";
import API from "../../../api";
import { useRef } from "react";
import { useParams } from "react-router";

const JobSearch = () => {
  const { user } = useSelector((state) => state.auth);
  const UrlParams = useParams();
  const {
    searchWord,
    jobTypeId,
    selectJobType,
    jobLocationId,
    dateId,
    selectJobLocation,
    mostSearchJob,
    cleared 
  } = useSelector((state) => state.jobSearch);
  const topMatchRef = useRef(null);
  const dispatch = useDispatch();
  const [mostSearchedJobs, setMostSearchedJobs] = useState([]);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [topMatchJobs, setTopMatchJobs] = useState([]);
  const [findLoader, setFindLoader] = useState(false);
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const findJobHandler = () => {
    getTopMatchJob();
  };

  const onCurrentPageHandler = (value) => {
    setCurrentPage(value);
    getTopMatchJob(value);
  };

  const onMostSearchjobClick = (value) => {
    dispatch(setSearchWord(value));
    dispatch(setMostSearchJob(!mostSearchJob));
  };

  const getTopMatchJob = async (currentPage, isScroll = true) => {
    try {      
      let params = `?`;
      if (user !== null) {
        params += `user_id=${user?.id}&`;
      }
      if (searchWord !== "") {
        params += `searchWord=${searchWord}&`;
      }
      if (jobTypeId !== null) {
        params += `jobTypeId=${jobTypeId}&`;
      }
      if (selectJobLocation !== "") {
        params += `jobLocationSearch=${selectJobLocation}&`;
      }
      if (dateId !== null) {
        params += `datePosted=${dateId}&`;
      }  
      if(currentPage !== undefined){
        params += `page=${currentPage}`;
      }
      setFindLoader(true);
      await API.get(`/job-board/top_matched_jobs${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data; 
            
            if(res?.data?.length > 0){
              setTopMatchJobs(res?.data);
            }      
           else {
            setTopMatchJobs([]);
           }
            setCurrentPage(res?.current_page);
            setEndPage(res?.last_page);
            setTotal(res?.total);
            if (
              topMatchRef.current &&
              (UrlParams?.scroll !== "search" || isScroll)
            ) {
              topMatchRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }
          setFindLoader(false);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
          setFindLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    async function getFindJob() {
      try {
        let params = `?`;
        params += user?.id !== null ? `user_id=${user?.id}&` : "";
        await API.get(`/job-board/find_job${params}`)
          .then((response) => {
            if (response?.status === 200) {
              let res = response?.data;
              setFeaturedJobs(res?.featuredJobs);
              setMostSearchedJobs(res?.mostSearchedJobs);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
    getFindJob();
  }, []);

  useEffect(() => {    
    let isScroll;
    if (UrlParams?.scroll === "search") {
      isScroll = false;
      getTopMatchJob(currentPage, isScroll);
    } else {
      isScroll = true;
      getTopMatchJob(currentPage, isScroll);
    }
  }, [searchWord]);

  useEffect(() => {
    if (cleared) {
      getTopMatchJob(currentPage); 
      dispatch(resetClearedFlag());
    }
  }, [cleared]);

  return (
    <>
      <FindJob findJobHandler={findJobHandler} findLoader={findLoader} />
      <MostSearchJob
        mostSearchedJobs={mostSearchedJobs}
        onMostSearchjobClick={onMostSearchjobClick}
      />
      <span ref={topMatchRef}>
        {" "}
        <TopMatchContainer
          findLoader={findLoader}
          startPage={startPage}
          currentPage={currentPage}
          endPage={endPage}
          featuredJobs={featuredJobs}
          topMatchJobs={topMatchJobs}
          onCurrentPageHandler={onCurrentPageHandler}
        />
      </span>
      <WorldConnect />
      <LoginModel />
    </>
  );
};

export default JobSearch;
