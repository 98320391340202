import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import Pagination from "../../../components/Job/JobSearch/Pagination/Pagination";
import useAblyHook from "../../../utils/useAblyHook";
import commentDate from "../../../utils/commentDate";
import {
  deleteNotifyDataAsync,
  getNotifyDataAsync,
  markAllNotifyAsReadDataAsync,
  markNotifyAsReadDataAsync,
} from "../../../redux/slices/notificationSlice";
import { setInboxCount, setNotifyCount } from "../../../redux/slices/AuthSlice";
import "./index.css";

const CandEmpNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, notifyCount } = useSelector((state) => state.auth);

  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(`notification.${user?.id}`);

  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState("");

  /*Toggle Model*/
  const [toggleDeleteModel, setToggleDeleteModel] = useState(false);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const [allNotifyData, setAllNotifyData] = useState([]);
  const [selectedNotifyId, setSelectedNotifyId] = useState([]);

  const goBack = () => {
    navigate(-1);
  };

  const onCurrentPageHandler = (value) => {
    setCurrentPage(value);
  };

  /*Notify Listing Id Value For Remove Notify to List*/

  const addAllNotifyIdHandler = () => {
    const allNotify = allNotifyData?.map((notify) => notify?.id);

    const newNotifyIds = allNotify?.filter(
      (id) => !selectedNotifyId?.includes(id)
    );

    setSelectedNotifyId((prevSelectedIds) => [
      ...prevSelectedIds,
      ...newNotifyIds,
    ]);
  };

  const removeAllNotifyIdHandler = () => {
    setSelectedNotifyId([]);
  };

  const selectedNotifyIdHandler = (id) => {
    const isIdExists = selectedNotifyId?.includes(id);

    if (isIdExists) {
      const updatedItems = selectedNotifyId?.filter((item) => item !== id);
      setSelectedNotifyId(updatedItems);
    } else {
      setSelectedNotifyId([...selectedNotifyId, id]);
    }
  };

  /************************/

  /*Delete Notify */

  const deleteNotifyModelHandler = (toggleModel) => {
    if (selectedNotifyId?.length > 0) {
      setToggleDeleteModel(toggleModel);
    }
  };

  /************************/

  const maskAsAllReadClick = () => {
    dispatch(markAllNotifyAsReadDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          const currentNotifyCount = notifyCount;
          if (currentNotifyCount > 0) {
            dispatch(setNotifyCount(0));
          }
          getnotifyData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const maskAsReadClick = (prod) => {
    if (prod?.id && prod?.read_at === null) {
      dispatch(markNotifyAsReadDataAsync({ notification_ids: prod?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            const currentNotifyCount = notifyCount;
            if (currentNotifyCount > 0) {
              dispatch(setNotifyCount(currentNotifyCount - 1));
            }
            getnotifyData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteNotifyHandler = async () => {
    setLoader(true);
    dispatch(
      deleteNotifyDataAsync({
        selectedNotifyId: selectedNotifyId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setLoader(false);
          setToggleDeleteModel(false);
          toast.error(res?.message?.success, {
            autoClose: 2000,
            closeOnClick: false,
          });
          getnotifyData();
        } else {
          setToggleDeleteModel(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getnotifyData = async () => {
    if (user && user?.id) {
      setMsg("");
      try {
        dispatch(
          getNotifyDataAsync({
            page: currentPage,
            per_page: perPage,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true && res?.data) {
              setAllNotifyData(res?.data);
              setCurrentPage(res?.current_page);
              setEndPage(res?.last_page);
              setTotal(res?.total);
            }

            if (res?.data?.length === 0) {
              setMsg("You have no notifications");
            } else {
              setMsg("");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (channel) {
      const handleChannelMessage = (message) => {
        if (message?.data?.data) {
          let newRecord = message?.data?.data;
          setAllNotifyData((prevAllNotifyData) => [
            newRecord,
            ...prevAllNotifyData,
          ]);
          dispatch(setNotifyCount(newRecord[0]?.unread_notification_count));
        }
      };
      channel.subscribe("new-notification", handleChannelMessage);

      return () => {
        channel.unsubscribe("new-notification", handleChannelMessage);
      };
    }
  }, [channel]);

  useEffect(() => {
    getnotifyData();
  }, [currentPage]);

  if (allNotifyData?.length === 0 && msg !== "") {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <>
        <section className="profile-edit pt-0">
          <div className="container container-site py-2">
            <Button
              variant="link"
              className="back-to-home add top-0 position-relative"
              onClick={() => goBack()}
            >
              <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
          </div>
          <div className="job-heading">
            <div className="container-site">
              <h2>Notifications</h2>
            </div>
          </div>
          <div className="mt-5">
            <div className="container-site">
              <p class="error-wishlist">{msg}</p>
            </div>
          </div>
        </section>

        <WorldConnect />
      </>
    );
  }

  return (
    <>
      <section className="profile-edit pt-0">
        <div className="container container-site py-2">
          <Button
            variant="link"
            className="back-to-home add top-0 position-relative"
            onClick={() => goBack()}
          >
            <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
            <img src="/image/form-back-arrow.png" alt="" />
          </Button>
        </div>
        <div className="job-heading">
          <div className="container-site">
            <h2>Notifications</h2>
          </div>
        </div>
      </section>

      {allNotifyData?.length > 0 && (
        <>
          <section className="notification-sec">
            <div className="container container-site">
              <div className="space-inner-row">
                <div className="notification-row">
                  <div className="left-select icon-cursor">
                  <label for='chk-notifi' className="visibility-hidden opacity-0 position-absolute">chk-notifi</label>
                    <input
                      type="checkbox"
                      name=""
                      id="chk-notifi"
                      checked={
                        allNotifyData?.length === selectedNotifyId?.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        allNotifyData?.length === selectedNotifyId?.length
                          ? removeAllNotifyIdHandler()
                          : addAllNotifyIdHandler();
                      }}
                    />
                    Select all
                    <div
                      className="img-del-notifi icon-cursor"
                      onClick={() => deleteNotifyModelHandler(true)}
                    >
                      <img src="/image/delet-notifi.jpg" alt="" />
                    </div>
                  </div>
                  <div
                    className="read-msg-noti icon-cursor"
                    onClick={() => maskAsAllReadClick()}
                  >
                    Mark all as read <img src="/image/read-notif.png" alt="" />
                  </div>
                </div>
                {allNotifyData?.map((prod, index) => (
                  <div
                    className={`notification-rows icon-cursor ${
                      prod?.read_at === null && "notification-active-row"
                    }`}
                    onClick={() => maskAsReadClick(prod)}
                  >
                    <div className="img-left-notfication">
                      <div className="user-information-noti">
                      <label for={`chk-notifi-row-${index}`} className="visibility-hidden opacity-0 position-absolute">chk-notifi</label>
                        <input
                          type="checkbox"
                          name=""
                          id={`chk-notifi-row-${index}`}
                          checked={
                            selectedNotifyId?.includes(prod?.id) ? true : false
                          }
                          onClick={() => selectedNotifyIdHandler(prod?.id)}
                        />
                        <img
                          className="user-information-noti-img"
                          src={
                            prod?.sender?.avatar
                              ? prod?.sender?.avatar
                              : "/image/client-img.png"
                          }
                          alt=""
                        />
                        <div className="right-side-content">
                          {ReactHtmlParser(prod?.data)}
                        </div>
                      </div>
                      {prod?.read_at === null && (
                        <div className="unread-msg">Unread</div>
                      )}
                    </div>
                    <div className="interview-time">
                      {commentDate(prod?.created_at)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination-nofity">
                <Pagination
                  currentPage={currentPage}
                  totalPages={endPage}
                  onCurrentPageHandler={onCurrentPageHandler}
                />
              </div>
            </div>
          </section>
        </>
      )}
      <WorldConnect />

      {toggleDeleteModel && (
        <ActionModal
          closeModal={() => deleteNotifyModelHandler(false)}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to clear all notifications?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteNotifyModelHandler(false)}
          >
            No, keep it.
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteNotifyHandler()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes, clear it."
            )}
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default CandEmpNotification;
