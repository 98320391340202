import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  getBulkResumeJobDetailDataAsync,
  setBulkResumeProcessId,
} from "../../../../redux/slices/bulkResumeCanDetailSlice";
import "./index.css";

const BulkResumeViewJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { processId } = useSelector((state) => state.bulkResumeCanDetail);
  const [loader, setLoader] = useState(false);
  const [viewResumeJobData, setViewResumeJobData] = useState({});

  const navHandler = () => {
    dispatch(setBulkResumeProcessId(null));
    navigate("/bulk-resume-analysis");
  };

  const getViewJobData = async () => {
    if (processId !== null) {
      try {
        setLoader(true);
        dispatch(
          getBulkResumeJobDetailDataAsync({
            process_id: processId,
          })
        )
          .then(async (response) => {
            const res = response?.payload;

            if (res?.result === true) {
              setViewResumeJobData(res?.data);
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getViewJobData();
  }, []);

  return (
    <div className="department-role-space bg-clr-admin">
      <div className="form-heading-prom">
        <img
          src="/image/form-back-arrow.png"
          alt="back arrow"
          onClick={() => navHandler()}
        />
        <h2>View job description</h2>
      </div>

      {loader ? (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="department-form-box">
          <form action="">
            {viewResumeJobData?.job_title && (
              <div className="admin-from-step space-b-f">
                <div className="f-admin-fill feild-f-job-title-bulk-datafill">
                  <label for="">Job title</label>
                  <br />
                  <p className="form-fill-user-bulk">
                    {viewResumeJobData?.job_title}
                  </p>
                </div>
              </div>
            )}

            {viewResumeJobData?.job_description && (
              <div className="admin-from-step space-b-f">
                <div className="f-admin-fill resume-f-admin">
                  <label for="">Job description </label>
                  <br />
                  <p className="form-fill-user-bulk">
                  {ReactHtmlParser(viewResumeJobData?.job_description)}                   
                  </p>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default BulkResumeViewJob;
