import { store } from "../redux/store";
import {
  clearLoginUser,
  setResumeDetail,
  setExpireTokenModel,
} from "../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../redux/slices/AdminDashboardSlice";
import { setInboxUser } from "../redux/slices/InboxSlice";
import { setTotalBulkResumeCredit } from "../redux/slices/buyBulkAnalysisSlice";

// Handle session expiration
export const handleSessionExpired = () => {
  const dispatch = store.dispatch;

  // Clear Redux store
  dispatch(clearLoginUser());
  dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
  dispatch(setdefaultPermissions({}));
  dispatch(
    setActiveSideBar({
      id: "1",
      name: "Modules",
      value: "Modules",
    })
  );
  dispatch(setInboxUser({}));
  dispatch(setTotalBulkResumeCredit(0));

  // Clear localStorage
  localStorage.removeItem("token");

  // Small delay to ensure modal opens after navigation
  setTimeout(() => {
    dispatch(setExpireTokenModel(true));
  }, 100); 
};
