import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  candidateFirstImpressionChangeAsync,
  favoriteCandidateAsync,
} from "../../../../../redux/slices/employerDashboardSlice";
import { CANDIDATE_Detail_Tab, CANDIDATE_STATUSES } from "../../../../../constants";
import { generateSignedURLDataAsync } from "../../../../../redux/slices/myResumeSlice";
import CanDetailHead from "../../../../../components/Employer/CandidateDetail/CanDetailHead/CanDetailHead";
import CanDetailTab from "../../../../../components/Employer/CandidateDetail/CanDetailTab/CanDetailTab";
import CanDetailResume from "../../../../../components/Employer/CandidateDetail/CanDetailResume/CanDetailResume";
import CanDetailEval from "../../../../../components/Employer/CandidateDetail/CanDetailEval/CanDetailEval";
import {
    getCandidateDetailAsync,
    getParsedResumeAsync,
    setCandidateDetailTab,
  } from "../../../../../redux/slices/candidateDetailSlice";
import { useNavigate } from "react-router-dom";
import CandidatesNotesSec from "../../../../../components/Employer/CandidateDetail/CandidatesNotesSec/CandidatesNotesSec";
import CanDetailActivities from "../../../../../components/Employer/CandidateDetail/CanDetailActivities/CanDetailActivities";

const AIAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { candidateDetailTab, candidateDetailId, candidateDetailResumeId, jobDetailId } = useSelector(
    (state) => state.candidateDetail
  );

  /* Candidate Detail on Resume Session */
  const [resumeData, setResumeData] = useState({
    fullName: "",
    originalResumeURL: null,
  });
  const [contactDetailsR, setContactDetailsR] = useState({});
  const [experienceR, setExperienceR] = useState([]);
  const [projectsR, setProjectsR] = useState([]);
  const [educationR, setEducationR] = useState([]);
  const [certificationsR, setCertificationsR] = useState([]);
  const [keySkillsR, setKeySkillsR] = useState([]);
  const [userTabs, setUserTabs] = useState(false);

  /**************************************/

  /* Candidate Detail + Candidate Evaluation */
  const [loader, setLoader] = useState(false);

  const [jobDetailData, setJobDetailData] = useState({});
  const [candidateDetailData, setCandidateDetailData] = useState({});
  const [candidateData, setCandidateData] = useState({
    analysis_skills: {},
    avatarLink: null,
    isFavorite: 0,
    currentCompany: "",
    currentJobTitle: "",
    email: "",
    education: null,
    linkedinURL: "",
    workPreferences: [],
    techTag: [],
  });

  /* Candidate Detail on first impression click */
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    if (parseInt(candidateData?.isFavorite) === 1 && impressionId === 1) {
      toast.error("Favourite applicant cannot have not a good fit status", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }
    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          getEmpCandidatesDetailData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Candidate Detail on Favourite click */
  const onFvtClick = async (account_id, job_id) => {
    if (candidateDetailData?.impression_id === 1) {
      toast.error(
        "Not a Good Fit Applicant cannot be added to Favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }
    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setCandidateData({
            ...candidateData,
            isFavorite: candidateData?.isFavorite === 0 ? 1 : 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  /* PDF Preview Handler */

  const handlePreviewResume = () => {
    if (candidateDetailData) {
      const {account_id, application_id, resume_id } = candidateDetailData;
      dispatch(
        generateSignedURLDataAsync({
          candidate_id: account_id,
          application_id: application_id,
          resume_id: resume_id,
          file_type: "pdf"
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          if (res?.result === true) {
            const { url } = res;
            if (url !== null) {
              const pdfUrl = url;
              window.open(pdfUrl, "_blank");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* Candidate Detail on Resume Session */

  const getParsedResumeData = async () => {
    if (candidateDetailId !== null) {
      dispatch(
        getParsedResumeAsync({
          userId: candidateDetailId,
          resumeId: candidateDetailResumeId,
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          const { latest_resume } = res;
          const {
            contact_details,
            experience,
            projects,
            education,
            certifications,
            key_skills,
          } = res?.resume_data;

          setContactDetailsR(contact_details);
          setExperienceR(experience);
          setProjectsR(projects);
          setEducationR(education);
          setCertificationsR(certifications);
          setKeySkillsR(key_skills);

          setResumeData({
            fullName: `${contact_details?.firstName} ${contact_details?.lastName}`,
            originalResumeURL: latest_resume?.resume_link,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* Candidate Detail + Candidate Evaluation */

  const getEmpCandidatesDetailData = async () => {
    if (candidateDetailId !== null && jobDetailId !== null) {
      setLoader(true);
      dispatch(
        getCandidateDetailAsync({
          userId: user?.id,
          candidateId: candidateDetailId,
          jobId: jobDetailId,
        })
      )
        .then((response) => {
          const res = response?.payload;

          const analysisSkills = res?.analysis_skills;
          setJobDetailData(res?.jobDetail);
          setCandidateDetailData(res?.candidate_detail);
          setCandidateData({
            ...candidateData,
            analysis_skills: analysisSkills,
            avatarLink: res?.avatarLink,
            isFavorite: res?.is_favorite,
            currentCompany: res?.lastCompany,
            currentJobTitle: res?.jobTitle,          
            email: res?.email,
            education: res?.education?.degree,
            linkedinURL: res?.linkedin,
            workPreferences: res?.work_preferences,
            techTag: res?.tags,
            expectedSalary: res?.expected_salary,
            salaryRange: res?.salary_range,
            currency: res?.currency?.title,
          });
          const tabs = CANDIDATE_Detail_Tab.filter((item) => {
            if (res?.candidate_detail?.include_profile !== 1) {
                return (item.name !== 'Candidate profile' && item.name !== 'Notes');
            } else {
                return item.name;
            }
          });
            // const tabs  = CANDIDATE_Detail_Tab.filter(item => item.name === 'Resume' || item.name === 'AI candidate evaluation');
            setUserTabs(tabs)
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getEmpCandidatesDetailData();
    getParsedResumeData();
  }, []);

  // Check if candidateDetail and jobDetail are not empty objects
  const isCandidateDetailNotEmpty =
    Object?.keys(candidateDetailData)?.length > 0;
  const isJobDetailNotEmpty = Object?.keys(jobDetailData)?.length > 0;

  return (
    <>
      {isCandidateDetailNotEmpty ? (
        <>
          <section className="journey-sec px-4">
            <div className="container-fluid ">
                <div className='flex-start-center page-title-heading mt-5'>
                    <img src='/image/form-back-arrow.png' className='me-2 cursor-pointer' onClick={() => navigate(-1)} />
                    <h2 className='m-0'>AI Analysis</h2>
                </div>
              <div className='card mt-4'>
                <div className="card-body pt-0">
                    <div className="journey-row">
                    {/* <CanDetailProgressBar /> */}
                    <CanDetailHead
                    showEmoji={false}
                    showCandidateStatus={true}
                    showSalaryExpectation={true}
                    showWorkPreference={true}
                    showTechTag={true}
                    showSendMsg={true}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                    onFvtClick={onFvtClick}
                    onFirstImpressionClick={onFirstImpressionClick}
                    showCandSocialDetail={true}
                    />
                </div>
                </div>
              </div>
              <div className="table-user-permission">
                <CanDetailTab
                  list={userTabs}
                  detailTab={candidateDetailTab}
                  onTabClick={(tab) => dispatch(setCandidateDetailTab(tab))}
                />

                {candidateDetailTab === "resume" && (
                  <CanDetailResume
                    resumeData={resumeData}
                    contactDetails={contactDetailsR}
                    experience={experienceR}
                    projects={projectsR}
                    education={educationR}
                    certifications={certificationsR}
                    keySkills={keySkillsR}
                    handlePreviewResume={handlePreviewResume}
                  />
                )}
                {candidateDetailTab === "candidate-evaluation" && (
                  <CanDetailEval
                    showFeeddBack={true}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                  />
                )}
                {candidateDetailTab === "notes" && <CandidatesNotesSec candidateData={candidateData}
                  candidateDetailData={candidateDetailData}
                  jobDetailData={jobDetailData} />}
                {candidateDetailTab === "candidate-activities" && (
                  <CanDetailActivities
                    appId={candidateDetailData?.application_id}
                  />
                )}   
                {/* {candidateDetailTab === "messages-tab" && (
                  <CanDetailMessages
                    showFeeddBack={true}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                  />
                )} */}            
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default AIAnalysis