import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import {
  setEmailVerify,
  setFavoriteJobsCount,
  setLoginUser,
  setLoginUserName,
  setResumeDetail,
  setLoginUserCountry,
  setUserResumes,
  verifyUserOTPAsync,
  setUserOTPEmail,
  resendUserOTPAsync,
} from "../../../../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../../redux/slices/AdminDashboardSlice";
import { ADMIN, CANDIDATE, EMPLOYER } from "../../../../constants";
import { getResumeForApplyingOnJob } from "../../../../redux/slices/candidateDetailSlice";
import "../../Login/LoginForm/LoginForm.css";
import "./VerifyLoginOTPForm.css";

const VerifyLoginOTPForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userOTPEmail } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // Define style object for OTP input fields

  const otpInputStyles = {
    width: "50px",
    height: "50px",
    margin: "0 10px",
    fontSize: "22px",
    borderRadius: "4px",
    border: "1px solid rgba(217, 217, 217, 1)",
    textAlign: "center",
  };

  const otpInputMStyles = {
    width: "45px",
    height: "45px",
    margin: "0 5px",
    fontSize: "22px",
    borderRadius: "4px",
    border: "1px solid rgba(217, 217, 217, 1)",
    textAlign: "center",
  };

  const getResumes = async (token) => {
    dispatch(getResumeForApplyingOnJob({ token: token }))
      .then(async (response) => {
        const res = response?.payload?.data;
        dispatch(setUserResumes(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOtpSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);

      if (otp.length === 6) {
        dispatch(verifyUserOTPAsync({ email: userOTPEmail, otp: otp }))
          .then(async (response) => {
            const res = response?.payload;
            const user = res?.user;
            const country = user?.country;

            if (res?.result === true) {
              dispatch(setLoginUser(res));
              dispatch(setLoginUserName(user?.first_name));
              await getResumes(res?.access_token);
              dispatch(
                setLoginUserCountry({
                  id: country?.id,
                  name: country?.name,
                })
              );
              dispatch(setEmailVerify(true));
              dispatch(
                setResumeDetail({
                  resumeId: user?.resume_id,
                  resumeType: user?.resume_type,
                })
              );
              dispatch(setFavoriteJobsCount(res?.favorite_job_count));
              dispatch(setUserOTPEmail(""));
              if (res?.permissions)
                dispatch(setdefaultPermissions(res?.permissions));
              localStorage.setItem("token", res?.access_token);
              if (
                res?.user?.user_type &&
                CANDIDATE.includes(res?.user?.user_type)
              ) {
                navigate("/candidate-dashboard");
              } else if (
                res?.user?.user_type &&
                EMPLOYER.includes(res?.user?.user_type)
              )
                navigate("/employer-dashboard");
              else if (
                res?.user?.user_type &&
                ADMIN.includes(res?.user?.user_type)
              ) {
                dispatch(
                  setActiveSideBar({
                    id: "2",
                    name: "Department",
                    value: "Department",
                  })
                );
                navigate("/admin-department");
              }
            } else {
              let errorMsg = res?.message?.error;
              if (errorMsg) {
                setErrorMsg(errorMsg);
                setTimeout(() => {
                  setErrorMsg("");
                }, 5000);
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLoader(false);
        setErrorMsg("Please enter a 6-digit OTP.");
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const resendUserOTPHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch(
        resendUserOTPAsync({
          email: userOTPEmail,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            let successMsg = res?.message?.success;
            toast.success(successMsg, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            let errorMsg = res?.message?.error;
            toast.error(errorMsg, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row">
            <div className="candiate-login-form">
              <div className="candidate-signin">
                <h2>Verify your email</h2>
                <p className="mb-4 sub-heading-otp">
                  Please enter the one-time passcode sent to your registered
                  email <b>ja*********@gmail.com</b> to continue.
                </p>
                <form onSubmit={handleOtpSubmit}>
                  <div className={`candidate-username`}>
                    <div className="cantainer-main-form-otp">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        inputStyle={
                          window.innerWidth >= 767
                            ? otpInputStyles
                            : otpInputMStyles
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  <div className="candidate-password">
                    {/* <div className="remember-forget-password">
                      Remaining time:{" "}
                      <span className="time-count-otp">04:59s</span>
                    </div> */}
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="form-submit">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="signup-here">
                    <p>
                      Didn’t get the code?{" "}
                      <Link href="#" onClick={(e) => resendUserOTPHandler(e)}>
                        <span>Resend</span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyLoginOTPForm;
