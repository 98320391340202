import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setActiveSideBar,
  setFormMode,
  setSelectedCompanyUser,
  setSelectedDep,
  setSelectedRoleR,
} from "../../../redux/slices/AdminDashboardSlice";
import TableError from "../../../components/Common/Admin/TabelError/TableError";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../components/Common/Admin/ShowRows/ShowRows";
import ActionModal from "../../../components/Common/ActionModal/ActionModal";
import APIPvt from "../../../apiPvt";
import "./index.css";

const AdminUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { defaultPermissions } = useSelector((state) => state.adminDashboard);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const [userListingData, setUserListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [dep, setDep] = useState([]);
  const [role, setRole] = useState([]);
  const [status, setStatus] = useState([]);

  const [selectedDepDropDown, setSelectedDepDropDown] = useState({
    id: null,
    name: "",
  });

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    title: "",
  });

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  /* Model State*/
  const [toggleDeleteModel, setToggleDeleteModel] = useState(false);

  /*Department Delete */

  const [selectedDeleteUserId, setSelectedDeleteUserId] = useState(null);

  const navHandler = () => {
    dispatch(setFormMode("add"));
    dispatch(setSelectedCompanyUser(null));
    dispatch(setSelectedDep({ id: null, name: "" }));

    dispatch(setSelectedRoleR({ id: null, name: "" }));

    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Users",
        value: "Create User",
      })
    );
    navigate("/admin-system-user");
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onDepClick = (id, name) => {
    setSelectedDepDropDown({ id: id, name: name });
  };

  const onRoleClick = (id, title) => {
    setSelectedRole({
      id: id,
      title: title,
    });
  };

  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onDepClean = () => {
    setSelectedDepDropDown({
      id: null,
      name: "",
    });
  };

  const onRoleClean = () => {
    setSelectedRole({
      id: null,
      title: "",
    });
  };

  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
}

  const onViewUserClick = (id) => {
    dispatch(setSelectedCompanyUser(id));
    navigate("/admin-detail-user");
  };

  const onEditUserClick = (id) => {
    dispatch(setFormMode("edit"));
    dispatch(setSelectedCompanyUser(id));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Users",
        value: "Create User",
      })
    );
    navigate("/admin-system-user");
  };

  /*Event Fot Delete User*/

  const deleteModelHandler = (toggleModel, id) => {
    setSelectedDeleteUserId(id);
    setToggleDeleteModel(toggleModel);
  };

  const onDeletUserClick = async () => {
    try {
      setLoader(true);
      await APIPvt.delete(`/user/${selectedDeleteUserId}`)
        .then((response) => {
          let res = response?.data;
          if (response?.status === 200 && res?.success === true) {
            getUserListingData();
            toast.success(res?.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
          setLoader(false);
          deleteModelHandler(false, null);
        })
        .catch((error) => {
          if (error) {
            setLoader(false);
            deleteModelHandler(false, null);
            console.log(error);
          }
        });
    } catch (error) {
      setLoader(false);
      deleteModelHandler(false, null);
      console.log(error);
    }
  };

  /*****************************/

  const getUserListingData = async () => {
    try {
      let params = `?`;
      if (search !== "") {
        params += `search=${search}&`;
      }
      if (selectedDepDropDown?.id !== null) {
        params += `department_id=${selectedDepDropDown?.id}&`;
      }
      if (selectedRole?.id !== null) {
        params += `role_id=${selectedRole?.id}&`;
      }
      if (selectedStatus?.value !== null) {
        params += `status=${selectedStatus?.value}&`;
      }
      if (currentPage !== null) {
        params += `page=${currentPage}&`;
      }
      if (perPage !== "") {
        params += `per_page=${perPage}&`;
      }
      setMsg("");
      await APIPvt.get(`/users${params}`)
        .then((response) => {
          if (response?.status === 200 || response?.success === true) {
            let res = response?.data;
            setUserListingData(res?.data);
            setDep(res?.departments);
            setRole(res?.roles);
            setStatus(res?.status);
            setTotalPages(res?.last_page);
            if (
              res?.data?.length === 0 &&
              search === "" &&
              selectedDepDropDown?.id === null &&
              selectedRole?.id === null &&
              selectedStatus?.value === null
            )
              setMsg("No users have been created in the system.");
            else if (
              res?.data?.length === 0 &&
              (search !== "" ||
                selectedDepDropDown?.id !== null ||
                selectedRole?.id !== null ||
                selectedStatus?.value !== null)
            )
              setMsg("Please make another selection. No record found.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserListingData();
  }, [selectedDepDropDown, selectedRole, selectedStatus, search, currentPage, perPage]);

  return (
    <>
      <div className="page-space bg-clr-admin">
        <div className="page-title-heading">
          <h2>Users</h2>
          {defaultPermissions?.users_create === 1 && (
            <button
              className="create-department"
              type="text"
              onClick={() => navHandler()}
            >
              Create users +
            </button>
          )}
        </div>{" "}
        <div className="filter-search-bar">
          <div className="two-drop-role">
            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Department</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        selectedDepDropDown?.name !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {selectedDepDropDown?.name === ""
                        ? "Department"
                        : selectedDepDropDown?.name}
                    </p>
                    {selectedDepDropDown?.name === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onDepClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {dep?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {dep?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onDepClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Roles</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        selectedRole?.title !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {selectedRole?.title === ""
                        ? "Roles"
                        : selectedRole?.title}
                    </p>
                    {selectedRole?.title === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onRoleClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {role?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {role?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onRoleClick(item?.id, item?.title);
                          }}
                        >
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Status</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${
                        selectedStatus?.label !== "" && "text-filter-select"
                      }`}
                    >
                      {" "}
                      {selectedStatus?.label === ""
                        ? "Status"
                        : selectedStatus?.label}
                    </p>
                    {selectedStatus?.label === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onStatusClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {status?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {status?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onStatusClick(item?.label, item?.value);
                          }}
                        >
                          {item?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-right-searcgadmin">
            <div className="search-admin">
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">Search</label>

              <img src="/image/job-search.png" alt="search" />
              <input
                type="text"
                name="search"
                id="jon-search-box"
                placeholder="Search"
                className="form-font-f"
                onChange={(e) => {
                  onSearchChange(e?.target?.value);
                }}
              />
            </div>
          </div>
        </div>
        <ShowRows  perPage={perPage} onChangePerPageHandler={onChangePerPageHandler}/>    
        <div className="department-table-imge">
          <div className="col-user-table">
            <div className="table-responsive">
              <table className="depart-table-left">
                <thead className="tb-heading-user">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Name</div>
                    </th>
                    <th scope="col" class="size-th-user-depart">
                      <div className="th-admin-heading">Department</div>
                    </th>
                    <th scope="col" className="size-th-user-depart">
                      <div className="th-admin-heading">Role name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">User name</div>
                    </th>
                    <th scope="col" className="size-th-user">
                      <div className="th-admin-heading">Email</div>
                    </th>
                    <th scope="col" className="size-th-user-status">
                      <div class="th-admin-heading">Status</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-user">
                  {userListingData?.length > 0 &&
                    userListingData?.map((item, index) => (
                      <tr>
                        <td className="">
                          <div className="name-sr-circle">
                            {`${item?.first_name}  ${item?.last_name}`}
                          </div>
                        </td>
                        <td>{item?.roles[0]?.department?.name}</td>
                        <td>{item?.roles[0]?.title}</td>
                        <td>{item?.username}</td>
                        <td>{item?.email}</td>
                        <td>
                          <div
                            className={
                              item?.status === 1
                                ? "active-tb-data"
                                : "inactive-tb-data"
                            }
                          >
                            {" "}
                            {item?.status === 1
                              ? "Active"
                              : "Inactive"}
                          </div>
                        </td>
                        <td>
                          <div className="four-role-img">
                            {defaultPermissions?.users_view === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() => onViewUserClick(item?.id)}
                              >
                                <img
                                  src="/image/role-user-view.png"
                                  alt=""
                                  class="show-tooltip icon-cursor"
                                />
                                <div className="tooltip-view-role">
                                  View users
                                </div>
                              </div>
                            )}
                            {defaultPermissions?.users_edit === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() => onEditUserClick(item?.id)}
                              >
                                <img
                                  src="/image/role-edit.png"
                                  alt=""
                                  class="show-tooltip2 icon-cursor"
                                />
                                <div className="tooltip-edit-role">Edit</div>
                              </div>
                            )}
                            {defaultPermissions?.users_delete === 1 && (
                              <div
                                className="img-text-tooltip"
                                onClick={() =>
                                  deleteModelHandler(true, item?.id)
                                }
                              >
                                <img
                                  src="/image/role-delet.png"
                                  alt=""
                                  class="show-tooltip3 icon-cursor"
                                />
                                <div className="tooltip-delete-role">
                                  Delete
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {userListingData?.length > 0 && (
              <TablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
            <TableError msg={msg} />
          </div>
        </div>
      </div>

      {toggleDeleteModel && (
        <ActionModal
          closeModal={deleteModelHandler}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content={`Are you sure you want to delete this user?`}
        >
          <button
            className="btn btn-black"
            onClick={() => deleteModelHandler(false, null)}
          >
            No
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => onDeletUserClick()}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes"
            )}
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default AdminUser;
