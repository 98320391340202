import React, { useEffect, useState } from 'react'
import './index.css'
import WorldConnect from '../../../components/Common/WorldConnect/WorldConnect'
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';
import StagesWizard from '../../Admin/JobPosted/InterviewStages/stagesWizard';
import API from "../../../api";
import { useSelector } from 'react-redux';

const ProInterviewStages = () => {
    const [intSteps, setIntSteps] = useState([])
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    useEffect(()=>{
      getInterviewSteps();
    },[])

    const getInterviewSteps = async () => {
      try {
        await API.get(`/job-board/get-interview-steps?user_id=${user?.id}`).then((res) => {
          if (res?.data.result) {
            let updatedResult = res?.data.data.map(item => ({
              ...item, id: item.id.toString()
            }))
            setIntSteps(updatedResult)
          }
        })
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    }

  return (
    <>
    <div className="container container-site py-2">
        <Button
          variant="link"
          className="back-to-home add resp-mob position-relative top-0 mb-0"
          onClick={() => navigate(-1)}
        >
          <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
          <img src="/image/form-back-arrow.png" alt="back-arrow" />
        </Button>
    </div>
    <div className="job-heading">
        <div className="container container-site">
          <div className="job-heading-home job-heading-local w-100 position-relative flex-column text-center">
            <h2>Create your interview stages</h2>
            <p className='opacity-50 fs-20 mt-2'>Select and customize your interview stages with ease. <br/> Tailor the process to fit your specific needs seamlessly.</p>
          </div>
        </div>
    </div>
    <div className='container'>
    <StagesWizard data={intSteps} invokeGetListing={getInterviewSteps} />
    </div>
    <WorldConnect/>
    </>
  )
}

export default ProInterviewStages