import React from "react";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import "./CanDetailTitle.css";

const CanDetailTitle = ({ jobDetailData }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container container-site">
        <div className="back-arrrow-job-title">
          <div className="arrow-back-t" onClick={() => navigate(-1)}>
            <img className="icon-cursor" src="/image/form-back-arrow.png" alt="back-arrow" />
          </div>
        </div>
      </div>
      <div className="job-title-joourny">
        {jobDetailData?.full_title !== null
          ? capitalizeFirstLetter(jobDetailData?.full_title)
          : ""}
      </div>
      <div className="jour-div-e"></div>
    </>
  );
};

export default CanDetailTitle;
