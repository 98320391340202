import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { handlePreviewResume } from "../../../../utils/downloadResumes";
import {
  fetchCandidatesInboxDataAsync,
  fetchEmployersInboxDataAsync,
  getInvitesInboxDataAsync,
  markAsReadInboxDataAsync,
  setInboxUser,
  startChatDataAsync,
} from "../../../../redux/slices/InboxSlice";
import { setInboxCount } from "../../../../redux/slices/AuthSlice";
import { USER_TYPE } from "../../../../constants";
import "./StartConversationModel.css";
import JobNoRecordFound from "../../JobNoRecordFound/JobNoRecordFound";

const InvitePeopleModel = ({ modelHandler, getInboxAllUser }) => {
  const dispatch = useDispatch();
  const { user, inboxCount } = useSelector((state) => state.auth);
  const { selectedInboxUser } = useSelector((state) => state.Inbox);

  const [loader, setLoader] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [msg, setMsg] = useState("");

  const [candidateNameSearch, setCandidateNameSearch] = useState("");

  const [jobTitleDropDown, setJobTitleDropDown] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState([]);

  const [locationList, setLocationList] = useState(true);
  const [locationSearch, setLocationSearch] = useState("");

  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [employerNameSearch, setEmployerNameSearch] = useState("");
  const [employers, setEmployers] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);

  const [selectedJobTitle, setSelectedJobTitle] = useState({
    id: null,
    name: "",
  });

  const [selectedLocation, setSelectedLocation] = useState({
    id: null,
    name: "",
  });

  /* Filter Handler*/

  const onSearchCanChange = (value) => {
    setCandidateNameSearch(value);
  };

  const onSearchEmpChange = (value) => {
    setEmployerNameSearch(value);
  };

  const onJobTitleClick = (item) => {
    const { id, full_title } = item;
    setSelectedJobTitle({
      id: id,
      name: full_title,
    });
  };

  const onJobTitleClean = () => {
    setSelectedJobTitle({
      id: null,
      name: "",
    });
  };

  const onLocationClick = (item) => {
    const { id, name } = item;
    setSelectedLocation({
      id: id,
      name: name,
    });
  };

  const onLocationClean = () => {
    setSelectedLocation({
      id: null,
      name: "",
    });
  };

  const onSelectedCandHandler = (prod) => {
    setSelectedCandidate(prod);
  };

  const onSelectedEmpHandler = (prod) => {
    setSelectedEmployers((prev) => {
      if (prev?.some((emp) => emp?.id === prod?.id)) {
        // If already selected, remove it
        return prev?.filter((emp) => emp?.id !== prod?.id);
      } else {
        // Add the new selected employer
        return [...prev, prod];
      }
    });
  };
  /* Location search */

  const filteredItem = locationDropDown?.filter((item) =>
    item?.name?.toLowerCase().includes(locationSearch.toLowerCase())
  );

  const onLocationDropDownHideHandler = (v) => {
    setLocationList(v);
  };

  const searchJobLocation = (e) => {
    setLocationSearch(e.target.value);
  };
  const toggleLocation = () => {
    setLocationList(!locationList);
  };

  const onJobLocationClickHandler = (data) => {
    setLocationSearch(data?.name);
    onLocationClick(data);
    toggleLocation();
  };

  /***********************/

  /* API Call */

  const onMaskReadClickHandler = (prod) => {
    if (prod?.id && prod?.reads?.length === 0) {
      dispatch(markAsReadInboxDataAsync({ conversation_id: prod?.id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            const currentInboxCount = inboxCount;
            if (currentInboxCount > 0) {
              dispatch(setInboxCount(currentInboxCount - 1));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const startChatHandler = async () => {
    if (selectedCandidate || selectedEmployers?.length) {
      let formData = {};

      if (selectedRole === USER_TYPE.CANDIDATE && selectedCandidate) {
        const { account_id, job_id } = selectedCandidate;
        formData = {
          job_id: job_id,
          candidate_id: account_id,
        };
      } else if (
        selectedRole === USER_TYPE.EMPLOYER &&
        selectedEmployers?.length > 0
      ) {
        const userId = selectedEmployers?.map((emp) => emp?.id);
        formData = {
          user_id: userId,
        };
      }

      setLoader(true);
      dispatch(startChatDataAsync(formData))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            dispatch(setInboxUser(res?.data));
            onMaskReadClickHandler(res?.data);
            getInboxAllUser();
          }
          modelHandler(false);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  };

  const fetchCandidatesData = async () => {
    if (user?.id !== null) {
      setMsg("");
      dispatch(
        fetchCandidatesInboxDataAsync({
          search: candidateNameSearch,
          job_id: selectedJobTitle?.id,
          city_id: selectedLocation?.id,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setCandidates(res?.data);

            if (
              res?.data?.length === 0 &&
              candidateNameSearch === "" &&
              selectedJobTitle?.id === null &&
              selectedLocation?.id === null
            )
              setMsg("");
            else if (
              res?.data?.length === 0 &&
              (candidateNameSearch !== "" ||
                selectedJobTitle?.id !== null ||
                selectedLocation?.id !== null)
            )
              setMsg("The search did not return any rows.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          setMsg("");
          console.log(error);
        });
    }
  };

  const fetchEmployersData = async () => {
    if (user?.id !== null) {
      dispatch(
        fetchEmployersInboxDataAsync({
          search: employerNameSearch,
          my_id: user?.id,
          company_id: user?.company_id,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            setEmployers(res?.company_users);

            if (res?.company_users?.length === 0 && employerNameSearch === "")
              setMsg("");
            else if (
              res?.company_users?.length === 0 &&
              employerNameSearch !== ""
            )
              setMsg("The search did not return any rows.");
            else {
              if (res?.data?.length !== 0) setMsg("");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getInvitesData = async () => {
    dispatch(getInvitesInboxDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setJobTitleDropDown(res?.data?.jobs);
          setLocationDropDown(res?.data?.locations);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (employerNameSearch !== "") {
      fetchEmployersData();
    }
  }, [employerNameSearch]);

  useEffect(() => {
    if (
      candidateNameSearch !== "" ||
      selectedJobTitle?.id !== null ||
      selectedLocation?.id !== null
    ) {
      fetchCandidatesData();
    }
  }, [candidateNameSearch, selectedJobTitle, selectedLocation]);

  useEffect(() => {
    getInvitesData();
  }, []);

  return (
    <>
      <section className="invite-model wishlist-box">
        <div className="popup-bg-invite-pople">
          <OutsideClickHandler
            onOutsideClick={() => {
              modelHandler(false);
            }}
          >
            <div className="popup-invite-list-content">
              <div
                className="close-img-invite"
                onClick={() => modelHandler(false)}
              >
                <img src="/image/cross.png" alt=" " />
              </div>
              <div className="invite-pople-top">
                <div className="inivite-logo">
                  <img src="/image/polycareers-north-macedonia.png" alt=" " />
                </div>
              </div>
              <div className="invite-pople-heading">Start chat</div>
              <p className="chat-text-box">
                Start a conversation with team members and candidates.
              </p>
              <div className="all-form-radio-btn">
                <div className="two-radio-box">
                  <div
                    className={`box-radio-btn icon-cursor ${
                      selectedRole === USER_TYPE.EMPLOYER
                        ? "box-radio-active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedRole(USER_TYPE.EMPLOYER);
                      setMsg("");
                    }}
                  >
                    <div className="img-invite-pople">
                      <img src="/image/building-left.png" alt="" />
                    </div>
                    <div className="invite-box-name">
                      <p className="box-first-pra">Employee</p>
                      <p className="box-scd-para">Add employees</p>
                    </div>
                  </div>
                  <div
                    className={`box-radio-btn icon-cursor ${
                      selectedRole === USER_TYPE.CANDIDATE
                        ? "box-radio-active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedRole(USER_TYPE.CANDIDATE);
                      setMsg("");
                    }}
                  >
                    <div className="img-invite-pople">
                      <img src="/image/users-right.png" alt="" />
                    </div>
                    <div className="invite-box-name">
                      <p className="box-first-pra">Candidate</p>
                      <p className="box-scd-para">Add candidate</p>
                    </div>
                  </div>
                </div>
                {selectedRole && (
                  <div className="invite-form-box">
                    {selectedRole === USER_TYPE.CANDIDATE && (
                      <div className="invite-form-box-container">
                        <form action="">
                          <div className="invite-from-step">
                            <div className="f-admin feild-f-admin">
                              <label for="">Name</label>
                              <br />
                              <input
                                type="text"
                                name=""
                                id="dept-f"
                                placeholder="Name"
                                className="form-font-admin"
                                onChange={(e) => {
                                  onSearchCanChange(e?.target?.value);
                                }}
                              />
                            </div>
                            <div className="f-admin feild-f-admin">
                              <label for="">Job title</label>
                              <div className="admin-status-filter-form">
                                <div
                                  id="dropdown-boxId2"
                                  className="company-form-dropdownBox1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <div className="text-filter-admin">
                                    <p className="text-filter-select">
                                      {selectedJobTitle?.name === ""
                                        ? "Job title"
                                        : selectedJobTitle?.name?.length >= 18
                                        ? selectedJobTitle?.name?.substr(
                                            0,
                                            18
                                          ) + " ..."
                                        : selectedJobTitle?.name}
                                    </p>
                                    {selectedJobTitle?.name === "" ? (
                                      <img src="/image/admin-d.png" alt="" />
                                    ) : (
                                      <div
                                        id="dropdown-boxId2"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        className="search-query"
                                      >
                                        <img
                                          src="/image/cross-b.png"
                                          alt=""
                                          onClick={() => onJobTitleClean()}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {jobTitleDropDown?.length > 0 && (
                                  <div className="admin-status-dropdown-frm">
                                    <ul className="list-job-title-company">
                                      {jobTitleDropDown?.map((j, i) => (
                                        <li
                                          key={i}
                                          className="icon-cursor job-title-size"
                                          onClick={() => {
                                            onJobTitleClick(j);
                                          }}
                                        >
                                          {j?.full_title}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="f-admin feild-f-admin">
                              <OutsideClickHandler
                                onOutsideClick={() => {
                                  onLocationDropDownHideHandler("true");
                                }}
                              >
                                <label>Location</label>
                                <div
                                  className={`admin-status-filter-form ${
                                    locationList ? "" : "open"
                                  }`}
                                >
                                  <div
                                    id="dropdown-boxId2"
                                    className="company-form-dropdownBox1"
                                    onClick={toggleLocation}
                                  >
                                    <div className="text-filter-admin">
                                      <p className="text-filter-select">
                                        {locationSearch?.length >= 18
                                          ? locationSearch?.substr(0, 18)
                                          : "Job location"}
                                      </p>
                                      {locationSearch ? (
                                           <div                                         
                                           className="search-query"
                                         >
                                        <img
                                          src="/image/cross-b.png"
                                          alt=""
                                          onClick={() => {
                                            onLocationClean();
                                            setLocationSearch("");
                                          }}
                                        />
                                        </div>
                                      ) : (
                                        <img src="/image/admin-d.png" alt="" />
                                      )}
                                    </div>
                                  </div>
                                  <div className={`admin-status-dropdown-frm`}>
                                    <div className="searchbox-invite">
                                    <label for='job-search-field-7' className="visibility-hidden opacity-0 position-absolute">switch</label>
                                      <input
                                        name="searchLocation"
                                        value={locationSearch}
                                        onChange={(e) => searchJobLocation(e)}
                                        id="job-search-field-7"
                                        placeholder="Job location"
                                        autoComplete="off"
                                        type="text"
                                        className="location-search-field"
                                        autocomplete="off"
                                      />
                                    </div>

                                    {locationDropDown?.length > 0 &&
                                      !filteredItem.length && (
                                        <>
                                          <ul className="dorpdown-list-location">
                                            {locationDropDown?.map((j, i) => (
                                              <li
                                                className="icon-cursor"
                                                onClick={() =>
                                                  onJobLocationClickHandler(j)
                                                }
                                              >
                                                <img
                                                  src="/image/location-j-place.png"
                                                  alt=""
                                                />
                                                {j?.name}
                                              </li>
                                            ))}
                                          </ul>
                                        </>
                                      )}
                                    {filteredItem?.length > 0 && (
                                      <>
                                        <ul className="dorpdown-list-location">
                                          {filteredItem.map((j, i) => (
                                            <li
                                              className="icon-cursor"
                                              onClick={() =>
                                                onJobLocationClickHandler(j)
                                              }
                                            >
                                              <img
                                                src="/image/location-j-place.png"
                                                alt=""
                                              />
                                              {j?.name}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </OutsideClickHandler>
                            </div>
                          </div>
                        </form>
                        {candidates?.length > 0 && (
                          <div className="inivte-pepole-list-sec">
                            {candidates?.map((prod, i) => (
                              <div className="list-of-pepole">
                                <input
                                  type="radio"
                                  name=""
                                  id=""
                                  checked={
                                    selectedCandidate?.account_id ===
                                      prod?.account_id &&
                                    selectedCandidate?.job_id === prod?.job_id
                                  }
                                  onClick={() => onSelectedCandHandler(prod)}
                                />
                                <div className="image-name-log-invite">
                                  <img
                                    src={
                                      prod?.user?.avatar_link ||
                                      "/image/client-img.png"
                                    }
                                    alt=""
                                  />
                                  <div className="name-designation">
                                    {prod?.first_name && (
                                      <p className="invite-name">
                                        {prod?.first_name} {prod?.last_name}
                                      </p>
                                    )}

                                    {prod?.full_title && (
                                      <p className="degnation-invite">
                                        {prod?.full_title}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <a
                                  href={prod?.phone && `tel:+${prod?.phone}`}
                                  className="img-invite-phone"
                                >
                                  <img src="/image/invite-what.png" alt="" />
                                  {prod?.phone && prod?.phone}
                                </a>

                                <a
                                  href={prod?.email && `mailto:${prod?.email}`}
                                  className="img-invite"
                                >
                                  <img src="/image/mail-invite.png" alt="" />
                                  {prod?.email && prod?.email}
                                </a>

                                <div
                                  class="img-invite-tooltip"
                                  onClick={() =>
                                    handlePreviewResume(prod?.resume)
                                  }
                                >
                                  <img
                                    src="/image/invite-res.png"
                                    alt=""
                                    className="invite-res icon-cursor"
                                  />
                                  <div className="tooltip-invite">
                                    View Resume
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {candidates?.length === 0 && msg !== "" && (
                          <div className="search-container-inbox">
                         <div className="divider divide-mbl-2"></div>
                            <JobNoRecordFound msg={msg} />
                            <div className="divider"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {selectedRole === USER_TYPE.EMPLOYER && (
                      <div className="invite-form-box-container">
                        <form action="">
                          <div className="invite-from-step">
                            <div className="f-admin feild-f-admin feild-f-name-invite">
                              <label for="">Name</label>
                              <br />
                              <input
                                type="text"
                                name=""
                                id="dept-f"
                                placeholder="Name"
                                className="form-font-admin"
                                onChange={(e) => {
                                  onSearchEmpChange(e?.target?.value);
                                }}
                              />
                            </div>
                          </div>
                        </form>
                        {employers?.length > 0 && (
                          <div className="inivte-pepole-list-sec">
                            {employers?.map((prod, i) => (
                              <div className="list-of-pepole">
                                <input
                                  type="checkbox"
                                  name=""
                                  id=""
                                  checked={selectedEmployers?.some(
                                    (emp) => emp?.id === prod?.id
                                  )}
                                  onChange={() => onSelectedEmpHandler(prod)}
                                />
                                <div className="image-name-log-invite">
                                  <img
                                    src={
                                      prod?.avatar_link ||
                                      "/image/client-img.png"
                                    }
                                    alt=""
                                  />
                                  <div className="name-designation">
                                    {prod?.first_name && (
                                      <p className="invite-name">
                                        {prod?.first_name} {prod?.last_name}
                                      </p>
                                    )}

                                    {prod?.full_title && (
                                      <p className="degnation-invite">
                                        {prod?.full_title}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <a
                                  href={prod?.phone && `tel:+${prod?.phone}`}
                                  className="img-invite-phone"
                                >
                                  <img src="/image/invite-what.png" alt="" />
                                  {prod?.phone && prod?.phone}
                                </a>

                                <a
                                  href={prod?.email && `mailto:${prod?.email}`}
                                  className="img-invite"
                                >
                                  <img src="/image/mail-invite.png" alt="" />
                                  {prod?.email && prod?.email}
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                        {employers?.length === 0 && msg !== "" && (
                          <div className="search-container-inbox">
                            <JobNoRecordFound msg={msg} />
                            <div className="divider"></div>
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      className="admin-form-btn"
                      onClick={() => startChatHandler()}
                    >
                      <button className="next-chat">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Start new chat"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </section>
    </>
  );
};

export default InvitePeopleModel;
