import { useEffect } from "react";
import { Link } from "react-router-dom";
import TopMatchLeft from "./TopMatchLeft";
import TopMatchRight from "./TopMatchRight";
import Pagination from "../Pagination/Pagination";
import "./TopMatchContainer.css";
import JobRecordLeft from "../../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../../Common/JobRecord/JobRecordRight";
import JobCardMbl from "../../../Common/JobCardMbl/JobCardMbl";


const TopMatchContainer = ({
  findLoader,
  startPage,
  currentPage,
  endPage,
  featuredJobs,
  topMatchJobs,
  onCurrentPageHandler,
}) => {

  return (
    <>
      <section className="job-posstion-job-content">
        <div className="job-heading">
          <h2>Top matches</h2>
        </div>
        <div className="container container-site">
          <div className="left-right-content">
            {/* <div className="col-left-j-space py-3"> */}

            {topMatchJobs?.length > 0 ?
              <div className={`col-left-j-space ${featuredJobs?.length ? "mb-3 pt-md-3 pt-0" : "mt-3"}`}>
                {featuredJobs?.length > 0 &&
                  featuredJobs.map((prod) => {
                    return (
                      <TopMatchLeft
                        JobData={prod}
                        selectedJobId={prod?.id}
                        hashId={prod?.hashed_id}
                        slug={prod?.slug}
                      />
                    )
                  })
                }

              </div> : ''}

            {/* <div className="divider-left"></div> */}
            <div className={`col-right-j-space ${topMatchJobs?.length !== 0 ? 'mx-auto' : ''}`}>
              {topMatchJobs?.length > 0 && 
              <>
                {
                  topMatchJobs.map((prod, index) => {
                    return (
                      <>

                        <div className="desktop-jobs w-100" style={{ paddingLeft: "20px" }}>
                          <div className="Job-Opportunities-row">
                            <JobRecordLeft JobData={prod} index={index} />
                            <JobRecordRight
                              hashId={prod?.hashed_id}
                              slug={prod?.slug}
                              date={prod?.date}
                              selectedJobId={prod?.id}
                              jobCountry={prod?.country}
                              isApplied={prod?.is_applied}
                              wishList={prod?.whishlist}
                            />
                          </div>

                          <div className="divider-two"></div>

                        </div>

                        <div className="mbl-jobs">

                          <JobCardMbl
                            hashId={prod?.hashed_id}
                            slug={prod?.slug}
                            JobData={prod}
                            index={index}
                            date={prod?.date}
                            selectedJobId={prod?.id}
                            jobCountry={prod?.country}
                            isApplied={prod?.is_applied}
                            wishList={prod?.whishlist}
                          />
                        </div>


                      </>
                    )
                  })
                }
              </>               
              }            
            </div>
          </div>
          {findLoader === false && topMatchJobs?.length === 0 &&   
            <p class="error-wishlist w-100">Please try a different keyword or location to find a job.</p>
          }        
        </div>
      </section>
      {topMatchJobs?.length > 0 && 
      <Pagination
        currentPage={currentPage}
        totalPages={endPage}
        onCurrentPageHandler={onCurrentPageHandler}
      />
       }    
    </>
  );
};

export default TopMatchContainer;
