import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobRecordRight from "../../Common/JobRecord/JobRecordRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import JobCardMbl from "../../Common/JobCardMbl/JobCardMbl";
import OutsideClickHandler from "react-outside-click-handler";

const OpenPosstion = ({
  jobTitles,
  setSearch,
  selectedJobTitile,
  datePosted,
  selectedDatePostedLabel,
  selectedDatePosted,
  expiryDate,
  selectedExpiryDateLabel,
  selectedExpiryDate,
  cities,
  selectedCity,
  jobs,
  OpenPosstionShow,
  onJobTitleClick,
  onSearchChange,
  onJobTitleClean,
  onDatePostedClick,
  onDatePostedClean,
  onExpiryClick,
  onExpiryClean,
  onLocationClick,
  onLocationClean,
}) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);

  const [locationList, setLocationList] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobLocation, setJobLocation] = useState([]);

  useEffect(() => {
    console.log("jobTitles is : ", jobTitles);
  }, [jobTitles]);

  const countHandler = () => {
    if (count < jobs?.length) setCount((prevCount) => prevCount + 10);
  };

  if (OpenPosstionShow === false && jobs?.length === 0) return null;

  const filteredItem = cities.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))

  const onLocationDropDownHideHandler = (v) => {
    setLocationList(v);
  };

  const searchJobLocation = (e) => {
    setSearchTerm(e.target.value);
  }
  const toggleLocation = () => {
    setLocationList(!locationList)
  }

  const onJobLocationClickHandler = (data) => {
    setSearchTerm(data.name)
    onLocationClick(data)
    toggleLocation()
  };

  return (
    <>
      <section className="open-postion">
        <div className={`job-heading`}>
          <div className='container container-site'>
            <div className={`job-heading-local ${openSearch ? "job-heading-local-mbl" : ""}`}>
              <h2>Open positions</h2>
              <div
                className={`job-search-div ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl-pos"
                  }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div ${openSearch ? "job-search-div-abs" : "job-search-open"
                  }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <label for='search-job' className="visibility-hidden opacity-0 position-absolute">search-job</label>
                <input
                  placeholder="Search jobs"
                  id='search-job'
                  className="job-search-input"
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container container-site mt-3">
          <div className="divider-opcity "></div>
          <div className="j-fillter-only">
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedJobTitile === ""
                        ? "Job Title"
                        : selectedJobTitile?.length >= 17
                          ? selectedJobTitile?.substr(0, 17) + " ..."
                          : selectedJobTitile}
                    </p>
                    {selectedJobTitile === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobTitleClick(j);
                          }}
                        >
                          {j}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedDatePostedLabel === ""
                        ? "Date Posted"
                        : selectedDatePostedLabel}
                    </p>
                    {selectedDatePostedLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onDatePostedClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {datePosted?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {datePosted?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onDatePostedClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {selectedExpiryDateLabel === ""
                        ? "Expiry Date"
                        : selectedExpiryDateLabel}
                    </p>
                    {selectedExpiryDateLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onExpiryClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {expiryDate?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {expiryDate?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onExpiryClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className='job-fillter-company'>
              <OutsideClickHandler
                onOutsideClick={() => {
                  onLocationDropDownHideHandler("true");
                }}
                className="j-company-fillter job-listing-mbl"
              >
                <div className="j-company-fillter job-listing-mbl">
                  <div className="search-query" onClick={toggleLocation}>
                    <p>{searchTerm ? searchTerm : 'Job Location'}</p>
                    {
                      searchTerm ? <img
                        src="/image/cross-b.png"
                        alt=""
                        onClick={() => { onLocationClean(); setSearchTerm('') }}
                      /> : <img
                        src="/image/company--detail-filter.png"
                        alt=""
                      />
                    }
                  </div>
                  <div
                    className={`job-location-dropdown ${locationList ? "job-dropdown-showhide" : 'open-dd'
                      }`}
                  >
                    <div className="searchbox-sec">
                      <label for='job-search-field-12' className="visibility-hidden opacity-0 position-absolute">job-search-field</label>
                      <input
                        type="text"
                        name="searchLocation"
                        value={searchTerm}
                        onChange={(e) =>
                          searchJobLocation(e)
                        }
                        id="job-search-field-12"
                        placeholder="Job Location"
                        autoComplete="off"
                      />
                    </div>
                    {(cities?.length > 0 && !filteredItem.length) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {cities.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {(filteredItem?.length > 0) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {filteredItem.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </OutsideClickHandler>
            </div>

            <div className="job-fillter-company d-none">
              <div className="j-company-fillter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>{selectedCity === "" ? "Location" : selectedCity}</p>
                    {selectedCity === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onLocationClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {cities?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="dorpdown-list-date-company">
                      {cities?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onLocationClick(j);
                          }}
                        >
                          <img src="/image/location-j-place.png" alt="" />
                          {j?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="divider-opcity "></div>
        </div>
      </section>

      <section className="company-job-sec mt-md-0 mt-3">
        <div className="container container-site">
          {jobs?.length > 0 ? (
            jobs?.slice(0, count)?.map((prod, index) => (
              <Fragment key={prod?.id}>
                <div className="desktop-jobs" style={{ paddingLeft: "20px" }}>
                  <div className="Job-Opportunities-row">
                    <JobRecordLeft JobData={prod} index={index} />
                    <JobRecordRight
                      hashId={prod?.hashed_id}
                      slug={prod?.slug}
                      date={prod?.date}
                      selectedJobId={prod?.id}
                      jobCountry={prod?.currency?.country}
                      isApplied={prod?.is_applied}
                      wishList={prod?.whishlist}
                    />
                  </div>

                  <div className="divider-two"></div>

                </div>

                <div className="mbl-jobs py-md-0 py-1">

                  <JobCardMbl
                    hashId={prod?.hashed_id}
                    slug={prod?.slug}     
                    JobData={prod}
                    index={index}
                    date={prod?.date}
                    jobCountry={prod?.country}
                    selectedJobId={prod?.id}
                    isApplied={prod?.is_applied}
                    wishList={prod?.whishlist}
                  />
                </div>
              </Fragment>
            ))
          ) : (
            <JobNoRecordFound />
          )}
          {/* <div className="divider"></div> */}
        </div>

        {jobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>More jobs</button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

export default OpenPosstion;
