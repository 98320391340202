import { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import JobRecordLeft from "../../Common/JobRecord/JobRecordLeft";
import JobListingRight from "../../Common/JobRecord/JobListingRight";
import JobNoRecordFound from "../../Common/JobNoRecordFound/JobNoRecordFound";
import "./JobListing.css";
import OutsideClickHandler from "react-outside-click-handler";
import ContentModal from "../../Common/ContentModal/ContentModal";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import APIPvtDB from "../../../apiPvtD";
import API from "../../../api";
import * as Yup from "yup";
import InvitePeopleModal from "../../Common/InvitePeopleModal/InvitePeopleModal";

const JobListing = ({
  jobTitles,
  setSearch,
  selectedJobTitile,
  onJobTitleClean,
  datePosted,
  selectedDatePostedLabel,
  selectedDatePosted,
  expiryDate,
  selectedExpiryDateLabel,
  selectedExpiryDate,
  cities,
  selectedCity,
  jobStatus,
  selectedJobStatus,
  showJobListing,
  jobListingsCount,
  publishedJobs,
  onJobTitleClick,
  onSearchChange,
  onDatePostedClick,
  onDatePostedClean,
  onExpiryClick,
  onExpiryClean,
  onLocationClick,
  onLocationClean,
  onJobStatusClick,
  onJobStatusClean,
  onChangeJobStatus,
}) => {
  const [count, setCount] = useState(10);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingInput, setSearchingInput] = useState("");
  const [locationList, setLocationList] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [toggleShareListModel, settoggleShareListModel] = useState(false);
  const [selectCompanyUser, setSelectCompanyUser] = useState([]);
  const [userName, setUserName] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);
  const prevCountRef = useRef();
  const { user } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      campanyUser: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      campanyUser: Yup.mixed().test(
        "campanyUser",
        "Please select the people to add members list",
        (value) =>
          !!(Array.isArray(selectCompanyUser))
      ),
    }),
    onSubmit: async (data, { resetForm }) => {
      let selectCompanyUsersId = selectCompanyUser.map((item) => item.id);
      if (selectCompanyUsersId.length < 1) {
        selectCompanyUsersId = ['']
      }
      AddMemberToMsg(selectCompanyUsersId);
    },
  });

  useEffect(() => {

  }, [jobTitles]);

  useEffect(() => {
    const getData = setTimeout(() => {
      try {
        if (userName) {
          const formData = new FormData();
          user !== null && formData.append("my_id", user?.id);
          user !== null && formData.append("company_id", user?.company_id);
          // user !== null && formData.append("my_id", 5);
          // user !== null && formData.append("company_id", 1);
          formData.append("search_word", userName);

          API.post(`/job-board/auto-search-company-user`, formData)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                let res = response?.data;

                const vUser = res?.company_users?.filter(user => user?.email_verified_at != null)
                setAllCompanyUser(vUser);

                if (res?.company_users?.length > 0) {               
                  setCompanyUserHide(true);
                }
                else {
                  setCompanyUserHide(false);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)

    return () => clearTimeout(getData)
  }, [userName]);

  const countHandler = () => {
    if (count < jobListingsCount) setCount((prevCount) => prevCount + 10);
  };

  if (showJobListing === false && publishedJobs?.length === 0) return null;

  const filteredItem = cities.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))

  const onLocationDropDownHideHandler = (v) => {
    setLocationList(v);
  };

  const searchJobLocation = (e) => {
    setSearchTerm(e.target.value);
  }
  const toggleLocation = () => {
    setLocationList(!locationList)
  }

  const onJobLocationClickHandler = (data) => {
    setSearchTerm(data.name)
    onLocationClick(data)
    toggleLocation()
  };

  const AddMemberToMsg = async (users) => {
    try {
      // const formData = new FormData();
      const formData = {
        "job_id": selectedJob,
        "user_ids": users
      }
      await APIPvtDB.post(`/add-members-to-job`, formData)
        .then((response) => {
          if (response.data?.success) {
            addRemoveCand()
            if (selectCompanyUser.length < prevCountRef.current) {
              toast.success('Members removed successfully', {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            if (selectCompanyUser.length > prevCountRef.current) {
              toast.success(response?.data.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
          } else {
            addRemoveCand()
            toast.error(response?.data.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  const addMemberinJob = (jID) => {
    setSelectedJob(jID)
    const getObj = publishedJobs.find(obj => obj.id === jID)
    prevCountRef.current = getObj.members.length;
    setSelectCompanyUser(getObj.members)
    // console.log(getObj, prevCountRef, jID);
    addRemoveCand()
  };

  const addRemoveCand = () => {
    settoggleShareListModel(!toggleShareListModel);
  };

  const onCompanyUserClick = (curentCompanyUser) => {
    const idExists = selectCompanyUser.some(item => item.id === curentCompanyUser.id);
    if (!idExists) {
      setSelectCompanyUser([...selectCompanyUser, curentCompanyUser]);
    }
  };
  const isCompanyUserFieldValid = () =>
    !!(
      formik.touched["campanyUser"] &&
      formik.errors["campanyUser"] &&
      selectCompanyUser?.length < 1
    );

  const getCompanyUserErrorMessage = () => {
    return (
      isCompanyUserFieldValid() && (
        <p className="error-msg">{formik.errors["campanyUser"]}</p>
      )
    );
  };

  const onCompanyUserCancel = (curentCompanyUser) => {
    setSelectCompanyUser(prevData => prevData.filter(item => item.id !== curentCompanyUser.id))
  };

  return (
    <>
      <section className="job-heading" id="job_listing">
        <div className="container container-site">
          <div className="job-search-box-row">
            <div className="job-heading-home smilar-job-heading job-heading-local w-100">
              <h2>Job listing</h2>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                  }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div m-0 ${openSearch ? "job-search-div-abs" : "job-search-open"
                  }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <label for='search-job-2' className="visibility-hidden opacity-0 position-absolute">search-job</label>
                <input
                  placeholder="Search jobs"
                  className="job-search-input"
                  id='search-job-2'
                  onChange={(e) => {
                    onSearchChange(e?.target?.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="open-postion">
        <div className="container container-site">
          <div className="divider"></div>
          <div className="j-fillter-only-dashbord">
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobTitile === ""
                        ? "Job title"
                        : selectedJobTitile?.length >= 18
                          ? selectedJobTitile?.substr(0, 18) + " ..."
                          : selectedJobTitile}
                    </p>
                    {selectedJobTitile === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobTitleClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobTitles?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobTitles?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor job-title-size"
                          onClick={() => {
                            onJobTitleClick(j);
                          }}
                        >
                          {j}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedDatePostedLabel === ""
                        ? "Date posted"
                        : selectedDatePostedLabel}
                    </p>
                    {selectedDatePostedLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onDatePostedClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {datePosted?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {datePosted?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onDatePostedClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedExpiryDateLabel === ""
                        ? "Expiry date"
                        : selectedExpiryDateLabel}
                    </p>
                    {selectedExpiryDateLabel === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onExpiryClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {expiryDate?.length > 0 && (
                  <div className="list-search-dropdown-status">
                    <ul className="dorpdown-list-date-company">
                      {expiryDate?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onExpiryClick(j?.value, j?.label);
                          }}
                        >
                          <img src="/image/calander-d.png" alt="" />
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className='job-fillter-company'>
              <OutsideClickHandler
                onOutsideClick={() => {
                  onLocationDropDownHideHandler("true");
                }}
                className="j-company-fillter job-listing-mbl"
              >
                <div className="j-company-fillter job-listing-mbl">
                  <div className="search-query" onClick={toggleLocation}>
                    <p>{searchTerm ? searchTerm : 'Job location'}</p>
                    {
                      searchTerm ? <img
                        src="/image/cross-b.png"
                        alt=""
                        onClick={() => { onLocationClean(); setSearchTerm('') }}
                      /> : <img
                        src="/image/company--detail-filter.png"
                        alt=""
                      />
                    }
                  </div>
                  <div
                    className={`job-location-dropdown ${locationList ? "job-dropdown-showhide" : 'open-dd'
                      }`}
                  >
                    <div className="searchbox-sec">
                      <label for='job-search-field-6' className="visibility-hidden opacity-0 position-absolute">job-search-field</label>
                      <input
                        type="text"
                        name="searchLocation"
                        value={searchTerm}
                        onChange={(e) =>
                          searchJobLocation(e)
                        }
                        id="job-search-field-6"
                        placeholder="Job location"
                        autoComplete="off"
                      />
                    </div>
                    {(cities?.length > 0 && !filteredItem.length) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {cities.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {(filteredItem?.length > 0) && (
                      <>
                        <ul className="dorpdown-list-location">
                          {filteredItem.map((j, i) => (
                            <li
                              className="icon-cursor"
                              onClick={() =>
                                onJobLocationClickHandler(j)
                              }
                            >
                              <img src="/image/location-j-place.png" alt="" />
                              {j?.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
            <div className="job-fillter-company d-none">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p> {selectedCity === "" ? "Location" : selectedCity}</p>
                    {selectedCity === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onLocationClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {cities?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="dorpdown-list-date-company">
                      {cities?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onLocationClick(j);
                          }}
                        >
                          <img src="/image/location-j-place.png" alt="" />
                          {j?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="job-fillter-company">
              <div className="j-company-fillter job-listing-mbl">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-icon">
                    <p>
                      {" "}
                      {selectedJobStatus?.label === ""
                        ? "All"
                        : selectedJobStatus?.label}
                    </p>
                    {selectedJobStatus?.label === "" ? (
                      <img src="/image/company--detail-filter.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-b.png"
                          alt=""
                          onClick={() => onJobStatusClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {jobStatus?.length > 0 && (
                  <div className="list-search-dropdown-company">
                    <ul className="list-job-title-company">
                      {jobStatus?.map((j, i) => (
                        <li
                          key={i}
                          className="icon-cursor"
                          onClick={() => {
                            onJobStatusClick(j?.label, j?.value);
                          }}
                        >
                          {j?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company-job-sec">
        <div className="space-inner-row">
          <div className="container container-site">
            {publishedJobs?.length > 0 ? (
              publishedJobs?.slice(0, count)?.map((prod, index) => (
                <Fragment key={prod?.id}>
                  <div className="divider mb-md-0 mb-4"></div>
                  <div className="Job-Opportunities-row" id={prod?.id}>
                    <JobRecordLeft
                      hideCompanyName={true}
                      jobCounter={true}
                      activeSwitch={true}
                      radioBtn={prod?.is_active === 1 ? true : false}
                      JobData={prod}
                      onChangeJobStatus={onChangeJobStatus}
                      addMemberinJob={addMemberinJob}
                    />
                    <JobListingRight
                      hashId={prod?.hashed_id}
                      slug={prod?.slug}
                      isActive={prod?.is_active}
                      date={prod?.date}
                      expireDate={prod?.expire_date}
                      selectedJobId={prod?.id}
                      myLists={prod?.myLists}
                      listSharedWithMe={prod?.listSharedWithMe}
                      addMemberinJob={addMemberinJob}
                    />
                  </div>
                </Fragment>
              ))
            ) : (
              <JobNoRecordFound />
            )}
            <div className="divider"></div>
          </div>
        </div>
        {publishedJobs?.length > 10 && (
          <div className="see-more-job" onClick={() => countHandler()}>
            <Link href="#">
              <button>See more</button>
            </Link>
          </div>
        )}
      </section>

      {
        toggleShareListModel && (
          <InvitePeopleModal
          toggleShareListModel={toggleShareListModel}
          closeModal={addRemoveCand}
          formik={formik}
          allCompanyUser={allCompanyUser}
          allCompanyUserHide={allCompanyUserHide}
          onCompanyUserClick={onCompanyUserClick}
          selectCompanyUser={selectCompanyUser}
          getCompanyUserErrorMessage={getCompanyUserErrorMessage}
          onCompanyUserCancel={onCompanyUserCancel}
          userName={userName}
          setUserName={setUserName}
        />
        )
      }
    </>
  );
};

export default JobListing;
