import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Button, Form } from "react-bootstrap";
import ActionModal from "../../ActionModal/ActionModal";
import {
  EMPLOYER,
  IS_IMAGE,
  IS_PDF,
  IS_Doc,
  USER_TYPE,
} from "../../../../constants";
import "./MessageCard.css";
import dateTime from "../../../../utils/dateTime";

const MessageCard = ({
  item,
  myMsg,
  onDeleteInboxHandler,
  onEditInboxHandler,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const [edit, setEdit] = useState(false);
  const [deleteChat, setDeleteChat] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);

  const [inputValue, setInputValue] = useState(false);

  if (item?.comments === null && item?.file_path === null) return <></>;

  const isImage = IS_IMAGE.includes(item?.file_mime_type);
  const IsPdf = IS_PDF.includes(item?.file_mime_type);
  const IsDoc = IS_Doc.includes(item?.file_mime_type);

  const showSubMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const closeModal = () => {
    setDeleteChat(!deleteChat);
  };

  const dialogDelete = (data) => {
    closeModal();
    setDeleteChatData(data);
  };

  const deleteComment = async () => {
    onDeleteInboxHandler(item);
    closeModal();
    setDeleteChatData(null);
    setShowMenu(false);
  };

  const editComment = (item) => {
    setEdit(true);
    setInputValue(item?.comments);
  };

  const updateComment = async () => {
    if(inputValue !== ""){
      onEditInboxHandler(item, inputValue);
    }
    setInputValue("");
    setEdit(false);  
  };

  return (
    <>
      {!edit ? (
        <div className={`message-container ${myMsg && "message-right"}`}>        
            <img
              className={`${myMsg ? "right-avatar" : "avatar"}`}
              src={
                item?.user?.user_type === USER_TYPE.EMPLOYER
                  ? item?.user?.companyy?.logo || "/image/client-img.png"
                  : item?.user?.avatar_link || "/image/client-img.png"
              }
              alt={`avatar`}
            />          
          <div className="message-content-container">
            <div
              className={`message-content ${myMsg && "message-content-right"}`}
            >
              <div className={`pin-chat ${!myMsg && "pin-chat-right"}`}>
                <div className={`dd-listitem-inbox-msg`}>
                  <button className="btn p-0 border-0 message-content-top-w">
                    <div className="message-content-top-right">
                      <span>
                        {item?.user?.first_name ? item?.user?.first_name : ""}{" "}
                        {item?.user?.last_name ? item?.user?.last_name : ""}
                      </span>
                      {myMsg && (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setShowMenu(false);
                          }}
                        >
                          <img
                            src="/image/ellipses.png"
                            alt="éllipses"
                            className="ep-msg-inbox"
                            onClick={() => showSubMenu()}
                          />
                        </OutsideClickHandler>
                      )}
                    </div>
                  </button>
                  {myMsg && (
                    <div className={showMenu ? "cv-cions active" : "cv-cions"}>
                      <ul>
                        {!item?.file_path && (
                          <li onClick={() => editComment(item)}>
                            {" "}
                            Edit <img src="/image/edit-ep-inbox.png" alt="Edit" />
                          </li>
                        )}
                        <li onClick={() => dialogDelete(item)}>
                          {" "}
                          Delete
                          <img src="/image/delete-ep-inbox.png" alt="delete" />
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {item?.comments ? (
                <p className={`message-text ${!myMsg && "message-text-right"}`}>
                  {item?.comments}
                </p>
              ) : (
                <></>
              )}
              {item?.file_path ? (
                <>
                  {isImage ? (
                    <a href="#">
                      <img
                        src={item?.file_path}
                        alt={item?.original_name}
                        className="inbox-img"
                      />
                    </a>
                  ) : IsPdf ? (
                    <a href={item?.file_path} target="_blank">
                      <img
                        src="/image/inbox-pdf.png"
                        className="inbox-file-img"
                      />
                      <p className="message-text">{item?.original_name}</p>
                    </a>
                  ) : IsDoc ? (
                    <a href={item?.file_path} target="_blank">
                      <img
                        src="/image/inbox-doc.png"
                        className="inbox-file-img"
                      />
                      <p className="message-text">{item?.file_original_name}</p>
                    </a>
                  ) : null}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="time-stamp">
              {item?.created_at && dateTime(item?.created_at, "time")}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Form
            className="d-flex justify-content-between w-100 flex-column bg-white p-3"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
              <Form.Control
                className="w-100 note-edit-input"
                placeholder="Type your note here..."
                size="lg"
                value={inputValue}
                onChange={handleInputChange}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <div className="d-flex mt-3 justify-content-end">
              <Button
                className="me-3 px-3 py-2 edit-btn"
                variant="dark"
                onClick={() => {
                  setEdit(false);                
                }}
              >
                Cancel
              </Button>
              <Button
                className="border-muted px-3 py-2 edit-btn"
                variant="outline-dark"
                onClick={() => updateComment()}
              >
                Save
              </Button>
            </div>
          </Form>
        </>
      )}

      {deleteChat && (
        <ActionModal
          closeModal={closeModal}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content="Are you sure you want to delete?"
        >
          <button className="btn btn-black" onClick={() => closeModal()}>
            No, keep it.
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteComment()}
          >
            Yes, delete it.
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default MessageCard;
