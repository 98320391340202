import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { EmployerRoutes } from "./EmployerRoutes";
import { CandidateRoutes } from "./CandidateRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { PublicRoutes } from "./PublicRoutes";
import TrackPageView from "../utils/TrackPageView";
import ScrollToTop from "../utils/ScrollToTop";
import TokenWatcher from "../utils/TokenWatcher";
import Home from "../pages/Home";
import JobSearch from "../pages/Job/JobSearch";
import ViewDetail from "../pages/Job/ViewDetail";
import CompanyDetail from "../pages/Job/CompanyDetail";
import JobQuesation from "../pages/Job/JobQuesation";
import Login from "../pages/Auth/Login";
import VerifyLoginOTP from "../pages/Auth/VerifyLoginOTP";
import SignUp from "../pages/Auth/SignUp";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import NewPassword from "../pages/Auth/NewPassword";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermCondition from "../pages/TermCondition";
import Pricing from "../pages/Pricing/PricingTable";
import PricingTableMk from "../pages/Pricing/PricingTableDemo";
import NotFound from "../pages/NotFound/NotFound"

/*candidate*/
import CanDashboard from "../pages/Candidate/Dashboard";
import CanProfile from "../pages/Candidate/Profile";
import CanWishlist from "../pages/Candidate/Wishlist";
import CanResume from "../pages/Candidate/Resume/CanResume";
import CanResumeDecision from "../pages/Candidate/Resume/CanResumeDecision";
import CanResumeJobForm from "../pages/Candidate/Resume/CanResumeJobForm";
import { MyResumes } from "../pages/Candidate/Resume/MyResumes";
import CanQuesationAnswer from "../pages/Candidate/QuesationAnswer";

/*Employer*/
import PostJob from "../pages/Employer/PostJob";
import EmpDashboard from "../pages/Employer/Dashboard";
import EmpProfile from "../pages/Employer/Profile";
import EmpCandidateList from "../pages/Employer/CandidateList/EmpCandidateList";
import EmpFavouritesList from "../pages/Employer/FavouritesList/EmpFavouritesList";
import EmpList from "../pages/Employer/List/EmpList";
import EmpCandidateDetail from "../pages/Employer/CandidateDetail/EmpCandidateDetail";
import ProInterviewStages from "../pages/Employer/ProInterviewStages";

/* Common */
import CandEmpInbox from "../pages/Common/Inbox";
import CandEmpNotification from "../pages/Common/Notification";

/*Admin*/
import AdminDashboard from "../pages/Admin/Dashboard";
import AdminDepartment from "../pages/Admin/Department";
import Billing from "../pages/Admin/Billing";
import JobPosted from "../pages/Admin/JobPosted";
import PendingAnalysis from "../pages/Admin/PendingAnalysis";
import BuyBulkAnalysis from "../pages/Admin/BuyBulkAnalysis";
import AdminRole from "../pages/Admin/Role";
import AdminUser from "../pages/Admin/User";
import AdminSystemUser from "../pages/Admin/SystemUser";
import AdminViewRole from "../pages/Admin/ViewRole";
import AdminViewUser from "../pages/Admin/ViewUser";
import AdminDetailUser from "../pages/Admin/DetailUser";
import AdminAllPromotion from "../pages/Admin/Promotion/AllPromotion";
import AdminCreateProCompanyForm from "../pages/Admin/Promotion/CreatePromotion/AdminCreateProCompanyForm";
import AdminCreateProJobForm from "../pages/Admin/Promotion/CreatePromotion/AdminCreateProJobForm";
import AdminCreatePromotionSlot from "../pages/Admin/Promotion/CreatePromotionSlot";
import AdminPromotionReq from "../pages/Admin/Promotion/PromotionReq";
import BulkResumeAnalysis from "../pages/Admin/BulkResume/BulkResumeAnalysis";
import BulkResumeUpload from "../pages/Admin/BulkResume/BulkResumeUpload";
import BulkResumeViewJob from "../pages/Admin/BulkResume/BulkResumeViewJob";
import BulkResumeReqDetail from "../pages/Admin/BulkResume/BulkResumeReqDetail";
import BulkAnalysis from "../pages/Admin/BulkResume/BulkAnalysis";
import BulkResumeAIAnalysis from "../pages/Admin/BulkResume/BulkResumeAIAnalysis";
import { ADMIN } from "../constants";
import GoogleCallback from "../components/Auth/GoogleAuth/GoodgleCallback";
import JobDetail from "../pages/Admin/JobPosted/JobDetail";
import CandidateJourney from "../pages/Admin/JobPosted/CandidateJourney";
import InterviewStages from "../pages/Admin/JobPosted/InterviewStages";
import PreScreeningQuestions from "../pages/Admin/JobPosted/PreScreening";
import InterviewQuestions from "../pages/Admin/JobPosted/PreScreening/InterviewQuestions";
import PreScreeningResult from "../pages/Admin/JobPosted/PreScreeningResult";
import BuyMorePost from "../pages/Admin/Promotion/BuyMorePosts";
import AIAnalysis from "../pages/Admin/JobPosted/CandidateJourney/AIAnalysis";
import Faq from "../pages/FAQ";





const CustomRouter = () => {
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  return (
    <Router>
      <TokenWatcher />
      <TrackPageView />
      <ScrollToTop />
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/company-detail" element={<CompanyDetail />} />
          <Route path="/jobs/:slug" element={<ViewDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/faq-questions" element={<Faq/>} />
          {/* <Route path="/pricing-content" element={<Pricing />} /> */}
          <Route path="/pricing-content" element={<PricingTableMk />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>



        <Route element={<PublicRoutes findJobHide={true} />}>
          <Route path="/job-search" element={<JobSearch />} />
          <Route path="/job-search/:scroll" element={<JobSearch />} />
          <Route path="/job-quesation/:id" element={<JobQuesation />} />
          <Route
            path="/candidate-resume-decision"
            element={<CanResumeDecision />}
          />
        </Route>

        <Route element={<PublicRoutes findJobHide={true} loginHide={true} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login/verify-otp" element={<VerifyLoginOTP />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

        <Route
          element={
            <PublicRoutes
              findJobHide={true}
              loginHide={false}
              signUpHide={true}
            />
          }
        >
          <Route path="/signup" element={<SignUp />} />
          <Route path="/auth/callback" element={<GoogleCallback />} />
        </Route>

        <Route
          element={
            isEmailVerify === true && ADMIN.includes(user?.user_type) ? (
              <EmployerRoutes toggleAuthHeader={true} />
            ) : (
              <CandidateRoutes findJobHide={true} />
            )
          }
        >
          <Route path="/new-password" element={<NewPassword />} />
        </Route>

        <Route element={<CandidateRoutes />}>
          <Route path="/candidate-dashboard" element={<CanDashboard />} />
          <Route path="/candidate-wishlist" element={<CanWishlist />} />
        </Route>

        <Route element={<CandidateRoutes findJobHide={true} />}>
          <Route path="/candidate-profile" element={<CanProfile />} />
          <Route path="/candidate-inbox" element={<CandEmpInbox />} />
          <Route path="/candidate-notification" element={<CandEmpNotification />} />
          <Route path="/my-resumes" element={<MyResumes />} />
          <Route path="/candidate-resume" element={<CanResume />} />      
          <Route
            path="/candidate-resume-job-form"
            element={<CanResumeJobForm />}
          />
            <Route
            path="/job/pre-screening-question"
            element={<CanQuesationAnswer />}
          />        
        </Route>

        <Route element={<EmployerRoutes toggleAuthHeader={true} />}>
          <Route path="/post-job" element={<PostJob />} />
          <Route path="/proInt-Stages" element={<ProInterviewStages />} />
          <Route path="/employer-dashboard" element={<EmpDashboard />} />
          <Route path="/employer-profile" element={<EmpProfile />} />
          <Route path="/employer-inbox" element={<CandEmpInbox />} />
          
          <Route
            path="/employer-Favourit-list"
            element={<EmpFavouritesList />}
          />
          <Route
            path="/employer-candidate-list"
            element={<EmpCandidateList />}
          />
          <Route path="/employer-list" element={<EmpList />} />
          <Route
            path="/employer-candidate-detail"
            element={<EmpCandidateDetail />}
          />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-department" element={<AdminDepartment />} />
          <Route path="/jobs-posted" element={<JobPosted />} />
          <Route path="/job-detail" element={<JobDetail />} />
          <Route path="/interview-journey" element={<CandidateJourney />} />
          <Route path="/interview-journey/ai-analysis" element={<AIAnalysis />} />
          <Route path="/pre-screening-question" element={<PreScreeningQuestions />} />
          <Route path="/pre-screening-question/result" element={<PreScreeningResult />} />
          <Route path="/pre-question" element={<InterviewQuestions/>} />
          <Route path="/interview-steps" element={<InterviewStages />} />
          <Route path="/admin-billing" element={<Billing />} />
          <Route path="/buy-bulk-analysis" element={<BuyBulkAnalysis />} />
          <Route path="/pending-analysis" element={<PendingAnalysis />} />
          <Route path="/admin-role" element={<AdminRole />} />
          <Route path="/admin-user" element={<AdminUser />} />
          <Route path="/admin-system-user" element={<AdminSystemUser />} />
          <Route path="/admin-view-role" element={<AdminViewRole />} />
          <Route path="/admin-view-user" element={<AdminViewUser />} />
          <Route path="/admin-detail-user" element={<AdminDetailUser />} />
          <Route path="/admin-all-promotion" element={<AdminAllPromotion />} />
          <Route
            path="/admin-promotion-company"
            element={<AdminCreateProCompanyForm />}
          />
            <Route
            path="/admin-promotion-job"
            element={<AdminCreateProJobForm />}
          />          
          <Route
            path="/admin-promotion-slot"
            element={<AdminCreatePromotionSlot />}
          />
          <Route path="/admin-promotion-req" element={<AdminPromotionReq />} />
          <Route path="/buy-more-posts" element={<BuyMorePost/>} />
          <Route
            path="/bulk-resume-analysis"
            element={<BulkResumeAnalysis />}
          />
          <Route path="/bulk-resume-upload" element={<BulkResumeUpload />} />
          <Route path="/bulk-resume-view-job" element={<BulkResumeViewJob />} />
          <Route
            path="/bulk-resume-req-detail"
            element={<BulkResumeReqDetail />}
          />
          <Route
            path="/bulk-analysis"
            element={<BulkAnalysis />}
          />
          <Route
            path="/bulk-resume-ai-analysis"
            element={<BulkResumeAIAnalysis />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default CustomRouter;
