import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AllPromotionTable from "../../../../components/Admin/Promotion/AllPromotionTable";
import TablePagination from "../../../../components/Common/Admin/TablePagination/TablePagination";
import ShowRows from "../../../../components/Common/Admin/ShowRows/ShowRows";
import TableError from "../../../../components/Common/Admin/TabelError/TableError";

import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
import ConfettiModal from "../../../../components/Common/ConfettiModal/ConfettiModal";
import {
  getPromotionListingDataAsync,
  promotionApproveReqAsync,
  promotionRejectReqAsync,
  setPromotionFormMode,
  setSelectedEditProID,
  setSelectedPromotionDate,
  setSelectedPromotionType,
  setSelectedTimeFrame,
} from "../../../../redux/slices/companyPromotionSlice";
import { SORT_DIRECTION, USER_TYPE } from "../../../../constants";
import "./index.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminAllPromotion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { defaultPermissions } = useSelector((state) => state.adminDashboard);

  const [msg, setMsg] = useState("");
  const [purchasedMsg, setPurchasedMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderR, setLoaderR] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortByStartDate, setSortByStartDate] = useState("desc");

  /*Dropdown Value*/
  const [promotionTypeD, setPromotionTypeD] = useState([]);
  const [companyD, setCompanyD] = useState([]);
  const [promotionStatusD, setPromotionStatusD] = useState([]);

  const [selectedPromotionTypeD, setSelectedPromotionTypeD] = useState({
    id: null,
    name: "",
  });

  const [selectedCompanyD, setSelectedCompanyD] = useState({
    id: null,
    name: "",
  });

  const [selectedPromotionStatusD, setSelectedPromotionStatusD] = useState({
    id: null,
    name: "",
  });

  /*Promotion Id Value For Approve/Reject Request*/

  const [selectedListingId, setSelectedListingId] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [promotionSubscribed, setPromotionSubscribed] = useState(false);

  const navHandler = () => {
    dispatch(setPromotionFormMode("add"));
    dispatch(setSelectedEditProID(null));
    dispatch(setSelectedPromotionType({ id: null, name: "" }));
    dispatch(setSelectedTimeFrame({ id: null, name: "" }));
    dispatch(
      setSelectedPromotionDate({
        id: null,
        name: "",
        week_start_day: "",
        week_end_day: "",
        start_date: "",
        end_date: "",
      })
    );
    navigate("/admin-promotion-company");
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const onsortByStartDateClick = () => {
    if (sortByStartDate === SORT_DIRECTION.ASC) {
      setSortByStartDate(SORT_DIRECTION.DESC);
    } else {
      setSortByStartDate(SORT_DIRECTION.ASC);
    }
  };

  const onproTypeClick = async (id, name) => {
    setSelectedPromotionTypeD({ id, name });
  };

  const onCompanyClick = async (id, name) => {
    setSelectedCompanyD({ id, name });
  };

  const onproStatusClick = async (id, name) => {
    setSelectedPromotionStatusD({ id, name });
  };

  const onproTypeClean = () => {
    setSelectedPromotionTypeD({
      id: null,
      name: "",
    });
  };

  const onCompanyClean = () => {
    setSelectedCompanyD({
      id: null,
      name: "",
    });
  };

  const onproStatusClean = () => {
    setSelectedPromotionStatusD({
      id: null,
      name: "",
    });
  };

  /*Event Fot Selecting Promotion Id For Approve/Reject Request*/

  const addAllListingIdHandler = () => {
    const allListingIds = listingData?.map((list) => list?.id);
    setSelectedListingId(allListingIds);
  };

  const removeAllListingIdHandler = () => {
    setSelectedListingId([]);
  };

  const selectedListingIdHandler = (id) => {
    const isIdExists = selectedListingId?.includes(id);

    if (isIdExists) {
      const updatedItems = selectedListingId?.filter((item) => item !== id);
      setSelectedListingId(updatedItems);
    } else {
      setSelectedListingId([...selectedListingId, id]);
    }
  };

  /*****************************/

  const onPromotionApproveReq = async () => {
    setLoader(true);
    dispatch(promotionApproveReqAsync({ selectedListingId: selectedListingId }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          toast.success(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          setSelectedListingId([]);
          getPromotionListingData();
        } else {
          toast.error(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const onPromotionRejectReq = async () => {
    setLoaderR(true);
    dispatch(promotionRejectReqAsync({ selectedListingId: selectedListingId }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          toast.success(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          setSelectedListingId([]);
          getPromotionListingData();
        } else {
          toast.error(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setLoaderR(false);
      })
      .catch((error) => {
        setLoaderR(false);
        console.log(error);
      });
  };

  const getPromotionListingData = async () => {
    setMsg("");
    dispatch(
      getPromotionListingDataAsync({
        promotionTypeId: selectedPromotionTypeD?.id,
        companyId: selectedCompanyD?.id,
        promotionStatusId: selectedPromotionStatusD?.id,
        search: search,
        sortByStartDate: sortByStartDate,
        page: currentPage,
        perPage: perPage,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.status === 200 || res?.success === true) {
          setListingData(res?.data);
          setPromotionTypeD(res?.promotion_types);
          setPromotionStatusD(res?.promotion_statuses);
          setTotalPages(res?.last_page);

          if (user?.user_type === USER_TYPE.SUPER_ADMIN)
            setCompanyD(res?.companies);

          if (
            res?.data?.length === 0 &&
            search === "" &&
            selectedPromotionTypeD?.id === null &&
            selectedCompanyD?.id === null &&
            selectedPromotionStatusD?.id === null
          )
            setMsg("The search did not return any rows.");
          else if (
            res?.data?.length === 0 &&
            (search !== "" ||
              selectedPromotionTypeD?.id !== null ||
              selectedCompanyD?.id !== null ||
              selectedPromotionStatusD?.id !== null)
          )
            setMsg("Please make another selection. No record found.");
          else {
            if (res?.data?.length !== 0) setMsg("");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPromotionListingData();
  }, [
    selectedPromotionTypeD,
    selectedCompanyD,
    selectedPromotionStatusD,
    search,
    sortByStartDate,
    currentPage,
    perPage,
  ]);

  const handleJobConfirmationModel = () => {
    setPromotionSubscribed(!promotionSubscribed);
    navigate("/admin-all-promotion");
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("promotion_purchased");

    if (paramValue && paramValue === "company") {
      setPurchasedMsg("Your company promotion is successful.");
      setPromotionSubscribed(true);
    }
    else if(paramValue && paramValue === "job"){
      setPurchasedMsg("Your job promotion is successful.");
      setPromotionSubscribed(true);
    }
    else {
      setPurchasedMsg("");
      setPromotionSubscribed(false);
    }
  }, []);

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>All promotions</h2>   
      </div>{" "}
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Promotion type</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${selectedPromotionTypeD?.name !== "" &&
                      "text-filter-select"
                      }`}
                  >
                    {" "}
                    {selectedPromotionTypeD?.name === ""
                      ? "Promotion type"
                      : selectedPromotionTypeD?.name}
                  </p>
                  {selectedPromotionTypeD?.name === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onproTypeClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {promotionTypeD?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {promotionTypeD?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onproTypeClick(item?.id, item?.name);
                        }}
                      >
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {user?.user_type === USER_TYPE.SUPER_ADMIN && (
            <div className="col-left-adminfilter">
              <p className="filter-label-admin">Companies</p>
              <div className="admin-status-filter">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-filter-admin">
                    <p
                      className={` ${selectedCompanyD?.name !== "" && "text-filter-select"
                        }`}
                    >
                      {" "}
                      {selectedCompanyD?.name === ""
                        ? "Companies"
                        : selectedCompanyD?.name}
                    </p>
                    {selectedCompanyD?.name === "" ? (
                      <img src="/image/admin-d.png" alt="" />
                    ) : (
                      <div
                        id="dropdown-boxId2"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/image/cross-port.png"
                          alt=""
                          onClick={() => onCompanyClean()}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {companyD?.length > 0 && (
                  <div className="promotion-status-dropdown">
                    <ul className="list-job-title-company">
                      {companyD?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onCompanyClick(item?.id, item?.name);
                          }}
                        >
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Promotion status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${selectedPromotionStatusD?.name !== "" &&
                      "text-filter-select"
                      }`}
                  >
                    {" "}
                    {selectedPromotionStatusD?.name === ""
                      ? "Promotion status"
                      : selectedPromotionStatusD?.name}
                  </p>
                  {selectedPromotionStatusD?.name === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onproStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {promotionStatusD?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {promotionStatusD?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          onproStatusClick(item?.id, item?.name);
                        }}
                      >
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <img src="/image/job-search.png" alt="" />
            <label for='jon-search-box' className="visibility-hidden opacity-0 position-absolute">jon-search-box</label>
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <ShowRows
        perPage={perPage}
        onChangePerPageHandler={onChangePerPageHandler}
      />
      {user?.user_type === USER_TYPE.SUPER_ADMIN &&
        selectedListingId?.length > 0 && (
          <div className="promotion-table-button">
            <button
              className="reject-prom-btn"
              onClick={() => onPromotionRejectReq()}
            >
              {loaderR ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Reject"
              )}
            </button>
            <button
              className="approve-prom-btn"
              onClick={() => onPromotionApproveReq()}
            >
              {" "}
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Approve"
              )}
            </button>
          </div>
        )}
      <div className="department-table-imge">
        <div className="col-user-table">
          <div className="table-responsive">
            <table className="depart-table-left">
              <thead className="tb-heading-user">
                <tr>
                  {user?.user_type === USER_TYPE.SUPER_ADMIN && (
                    <th scope="col" class="th-size-start">
                      <input
                        type="checkbox"
                        name=""
                        id="company-promotion"
                        className="icon-cursor"
                        checked={
                          listingData?.length === selectedListingId?.length &&
                            selectedListingId?.length > 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          listingData?.length === selectedListingId?.length
                            ? removeAllListingIdHandler()
                            : addAllListingIdHandler();
                        }}
                      />
                      <div className="th-admin-heading">Company name</div>
                    </th>
                  )}
                  {/* <th scope="col" className="th-size-one">
                    <div className="th-admin-heading">Promotion type</div>
                  </th> */}
                  <th scope="col" className="th-size-eight">
                      <div className="th-admin-heading">Promo location</div>
                    </th>
                    <th scope="col" className="th-size-postion">
                      <div className="th-admin-heading">Ads position</div>
                    </th>
                  <th scope="col" className="th-size-two">
                    <div className="th-admin-heading">Status</div>
                  </th>
                  <th scope="col" className="th-size-three">
                    <div className="th-admin-heading">Time frame</div>
                  </th>
                  <th scope="col" className="th-size-four">
                    <div className="th-admin-heading">
                      Start date{" "}
                      <img
                        className="arrow-icon icon-cursor"
                        src={
                          sortByStartDate === SORT_DIRECTION.ASC
                          ? "/image/bars-arrow-up.png"
                          : "/image/bars-arrow-down.png"
                        }
                        alt=""
                        onClick={() => onsortByStartDateClick()}
                      />{" "}
                    </div>
                  </th>
                  <th scope="col" className="th-size-five">
                    <div className="th-admin-heading">End date</div>
                  </th>
                  <th scope="col" className="th-size-six">
                    <div className="th-admin-heading">Job title</div>
                  </th>
                  {/* <th scope="col" className="th-size-six">
                    <div className="th-admin-heading">Total price</div>
                  </th> */}
                  {/* <th scope="col" className="th-size-seven">
                    <div className="th-admin-heading">Approval date</div>
                  </th>               */}
                  <th scope="col" className="th-size-eight">
                    <div className="th-admin-heading">Created by</div>
                  </th>

                  {user?.user_type === USER_TYPE.SUPER_ADMIN ? (
                    <th scope="col" class="th-size-nine">
                      <div className="th-admin-heading">Action</div>
                    </th>
                  ) : (
                    <th scope="col" className="th-size-nine">
                      <div className="th-admin-heading">Created date</div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="tb-user">
                {listingData?.length > 0 &&
                  listingData?.map((item, index) => (
                    <AllPromotionTable
                      index={index}
                      item={item}
                      selectedListingId={selectedListingId}
                      selectedListingIdHandler={selectedListingIdHandler}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          {listingData?.length > 0 && (
            <TablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
          <TableError msg={msg} />
        </div>
      </div>
      {promotionSubscribed && (
        <ContentModal closeModal={handleJobConfirmationModel}>
          <ConfettiModal closeModal={handleJobConfirmationModel}
            title={<p>{purchasedMsg}</p>} />
        </ContentModal>
      )}
    </div>
  );
};

export default AdminAllPromotion;
