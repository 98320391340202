  const dateTime = (data, type) => {
    const utcDate = new Date(data); // Input date in UTC format
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Detect user's timezone
  
    if (type === 'date') {
      // Format the date in the user's timezone
      return utcDate.toLocaleDateString('en-US', {
        timeZone: userTimezone,
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });
    }
  
    if (type === 'time') {
      // Convert UTC date to local date in the user's timezone
      const localDate = new Date(
        utcDate.toLocaleString('en-US', { timeZone: userTimezone })
      );
  
      let hours = localDate.getHours(); // Get local hours
      const minutes = localDate.getMinutes().toString().padStart(2, '0'); // Get local minutes
      const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM
      hours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)
  
      return `${hours}:${minutes} ${ampm}`; // Custom time format
    }
  
    // Default case: return the original UTC string if type doesn't match
    return data;
  };
  
  export default dateTime;