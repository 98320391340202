import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setExpireTokenModel } from "../redux/slices/AuthSlice";
import ActionModal from "../components/Common/ActionModal/ActionModal";

const TokenWatcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { expireTokenModel } = useSelector((state) => state.auth);

  const handleModalClose = () => {
    // Close the modal
    
    dispatch(setExpireTokenModel(false));
  };

  useEffect(() => {
    if (expireTokenModel) {
      // Show modal and redirect to login if expired
      navigate("/login");
    }
  }, [expireTokenModel, navigate]);

  return (
    <>
      {expireTokenModel && (
        <ActionModal
          heading="Session Expired"
          content="To protect your data we have closed your session. Please login again."
          closeModal={handleModalClose}
          modalArt="/image/warning-wishlist.png"
        />
      )}
    </>
  );
};

export default TokenWatcher;
