import React from "react";
import VerifyLoginOTPForm from "../../../components/Auth/VerifyLoginOTP/VerifyLoginOTPForm/VerifyLoginOTPForm";
import WorldConnect from "../../../components/Common/WorldConnect/WorldConnect";

const VerifyLoginOTP = () => {
  return (
    <>
      <VerifyLoginOTPForm />
      <WorldConnect />
    </>
  );
};

export default VerifyLoginOTP;
